import React from 'react';
import { Accordion } from 'native-base';

export const Accordions = ({ content, header, items }) => {
  const autoExpandItemIndex = !!items?.[0]?.id ? null : 0;

  return (
    <Accordion
      allowMultiple
      borderColor={'transparent'}
      index={[autoExpandItemIndex]}
    >
      {items.map((item, i) => (
        <Accordion.Item index={i} key={i}>
          {({ isExpanded }) => (
            <>
              <Accordion.Summary
                p={0}
                borderTopWidth={0}
                _expanded={{
                  bg: 'transparent',
                }}
                _hover={{ bg: 'transparent' }}
              >
                {header(item, i, isExpanded)}
              </Accordion.Summary>

              <Accordion.Details p={0}>{content(item, i)}</Accordion.Details>
            </>
          )}
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
