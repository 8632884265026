import * as React from 'react';
import { initialUserData } from '../data/initialUserData.js';
import { FORM_ACTIONS } from './formActions.js';

const FormDataContext = React.createContext();

export default function reducer(formData, action) {
  switch (action.type) {
    case FORM_ACTIONS.TOGGLE_FORM_DATA:
      return toggleFormData(formData, action.fieldName, action.payload);

    case FORM_ACTIONS.UPDATE_FORM_FIELD:
      return updateFormField(
        formData,
        action.assessment,
        action.fieldName,
        action.payload
      );

    case FORM_ACTIONS.ADD_NEW_MEDICATION:
      return addNewMedication(formData, action.payload);

    case FORM_ACTIONS.REMOVE_MEDICATION:
      return removeMedication(formData, action.payload);

    case FORM_ACTIONS.INIT_AFFILIATE:
      return initAffiliate(formData, action.payload);

    default:
      return formData;
  }
}

function initAffiliate(formData, payload) {
  return { ...formData, affiliate: payload };
}

function addNewMedication(formData, payload) {
  const { medications } = formData;
  const newMedications = {
    ...medications,
    [Date.now()]: { medication: payload },
  };
  return { ...formData, medications: newMedications };
}

function removeMedication(formData, payload) {
  if (payload !== undefined) {
    const key = payload;
    const { medications } = formData;
    delete medications[key];
    return { ...formData };
  }
}

function toggleFormData(formData, fieldName, payload) {
  if (payload !== undefined) return { ...formData, [fieldName]: payload };

  return { ...formData, [fieldName]: !formData[fieldName] };
}

function updateFormField(formData, assessment, fieldName, payload) {
  // if an assessment key comes through, we nest one level by that key
  switch (assessment) {
    case 'onboarding-uc': {
      const newAssessment = {
        ...formData['onboarding-uc'],
        [fieldName]: payload,
      };
      return { ...formData, [assessment]: newAssessment };
    }

    case 'onboarding-uc-ibs': {
      const newAssessment = {
        ...formData['onboarding-uc-ibs'],
        [fieldName]: payload,
      };
      return { ...formData, [assessment]: newAssessment };
    }

    case 'onboarding-crohns': {
      const newAssessment = {
        ...formData['onboarding-crohns'],
        [fieldName]: payload,
      };
      return { ...formData, [assessment]: newAssessment };
    }

    case 'onboarding-crohns-ibs': {
      const newAssessment = {
        ...formData['onboarding-crohns-ibs'],
        [fieldName]: payload,
      };
      return { ...formData, [assessment]: newAssessment };
    }

    case 'onboarding-ibs-all': {
      const newAssessment = {
        ...formData['onboarding-ibs-all'],
        [fieldName]: payload,
      };
      return { ...formData, [assessment]: newAssessment };
    }

    default:
      return { ...formData, [fieldName]: payload };
  }
}

function FormDataProvider({ children, initialState = initialUserData }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <FormDataContext.Provider value={value}>
      {children}
    </FormDataContext.Provider>
  );
}

function useFormData() {
  const context = React.useContext(FormDataContext);
  if (context === undefined) {
    throw new Error('useFormData must be used within a FormDataProvider');
  }
  return context;
}

function cacheFormDataInLocalStorage(data) {
  localStorage.setItem('formData', [data]);
}

function getFormDataFromLocalStorage() {
  return JSON.parse(localStorage.getItem('formData'));
}

function clearLocalStorage() {
  localStorage.clear();
}

export {
  FormDataProvider,
  useFormData,
  FormDataContext,
  cacheFormDataInLocalStorage,
  getFormDataFromLocalStorage,
  clearLocalStorage,
};
