import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/react';

const { REACT_APP_ENV_LABEL = 'unknown' } = process.env;

Sentry.init({
  dsn: 'https://48cc34e91e1f4f8a8406546daa30e140@o1334154.ingest.sentry.io/4503972246388736',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: REACT_APP_ENV_LABEL === 'production' ? 1.0 : 0,
  environment: REACT_APP_ENV_LABEL,

  // Sentry specific url replay: https://docs.sentry.io/platforms/javascript/session-replay/understanding-sessions/#replays-on-specific-urls
  // Sentry manual replay: https://docs.sentry.io/platforms/javascript/session-replay/understanding-sessions/#manually-starting-replay
  // Docs suggest setting the sample rate to be 10% but
  // here we set it to 0%. "You may want this to be 100%
  // while in development and sample at a lower rate in production"
  replaysSessionSampleRate: 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs. Only enabled for production.
  replaysOnErrorSampleRate: REACT_APP_ENV_LABEL === 'production' ? 1.0 : 0,
});

const {
  REACT_APP_STRIPE_PK = 'pk_test_51LRz0wJxQmFJ7pu9WKH8JEQhXW4mKzQl4OcwXnt4JIJmI9rWa0lhSIQyKrnuSekN241z94bJ4y8OcY0I9m4UIQLo00I70jA82E',
} = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PK);

ReactDOM.render(
  <>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </>,
  document.getElementById('root') as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
