import { Button, HStack, Link } from 'native-base';
import { useAuth } from '../../hooks';

type Props = {
  onGetStartedButtonClick: () => void;
  onOpenLoginModal: () => void;
};

export const HeaderActions = ({
  onGetStartedButtonClick,
  onOpenLoginModal,
}: Props) => {
  const { isAuthenticated } = useAuth();

  return (
    <HStack alignItems={'center'}>
      {!isAuthenticated && (
        <Link
          _hover={{
            _text: {
              color: 'primary.100',
              underline: true,
            },
          }}
          _text={{
            color: 'light.100',
            textTransform: 'none',
            fontWeight: 'black',
            fontSize: 'md',
          }}
          isUnderlined={false}
          onPress={onOpenLoginModal}
        >
          Log In
        </Link>
      )}

      <Button
        _text={{ color: 'secondary.500', textTransform: 'none' }}
        ml={6}
        onPress={onGetStartedButtonClick}
        testID={'onboarding-signup-button'}
      >
        Get Started
      </Button>
    </HStack>
  );
};
