import { format } from 'date-fns';

export const getUserPhase = (currentPhase) => {
  switch (currentPhase) {
    case 'PhaseOne':
      return 1;
    case 'PhaseTwo':
      return 2;
    case 'PhaseThree':
      return 3;
    case 'PhaseFour':
      return 4;
    case 'ProgramComplete':
      return 5;
    default:
      return 0;
  }
};

// phase completed is one behind the current phase
// when current phase is 0, we default to 0 instead of returning -1
export const getCompletedPhaseNumber = (currentPhase) => {
  const userPhase = getUserPhase(currentPhase);
  return userPhase > 0 ? userPhase - 1 : 0;
};

export const generateDateStringByTimestamp = (timestamp) => {
  const dateObj = timestamp ? new Date(timestamp) : null;
  return dateObj ? format(dateObj, 'MM-dd-yyyy') : null;
};
