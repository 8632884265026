import { useState } from 'react';
import {
  FormControl,
  Pressable,
  Box,
  Flex,
  Image,
  Text,
  Link,
} from 'native-base';
const chevronDown = '../chevron-down.png';

export const RequiredTerms = ({
  formData,
  setData,
  setShowFullRelease,
  onChange,
}) => {
  const [visibleSections, setVisibleSections] = useState({});

  const requiredTermsConfig = [
    {
      id: 'auth_medical_release',
      title: 'Notice of Privacy Practice',
      content: (
        <>
          I acknowledge that Ayble Health Service Corporation, providing health
          care on behalf of Ayble Health, Inc., has offered me a copy of its{' '}
          <Link
            _text={{ textTransform: 'none' }}
            isExternal
            href={'http://www.ayblehealth.com/npp'}
          >
            Notice of Privacy Practices
          </Link>{' '}
          (“Notice”). I understand this acknowledgement (“Acknowledgment”) in no
          way affects the care I will receive from Ayble Health Service
          Corporation. I ACKNOWLEDGE I HAVE READ THIS NOTICE AND UNDERSTAND ITS
          CONTENTS AND HAVE RECEIVED A COPY HEREOF. I FURTHER ACKNOWLEDGE THAT I
          AM THE INDIVIDUAL OR PERSON DULY AUTHORIZED EITHER BY THE INDIVIDUAL
          OR OTHERWISE, TO SIGN THIS ACKNOWLEDGMENT AND TO CONSENT TO AND ACCEPT
          ITS TERMS.
        </>
      ),
    },
    {
      id: 'auth_payer_to_pay_ayble',
      title: 'Insurance Reimbursement Authorization',
      content: (
        <>
          I authorize the release of any medical or other information necessary
          to process claims to all my insurance carrier(s) and its authorized
          agents. I assign all payments, rights, and claims for reimbursement of
          claims, costs and expenses allowable under my insurance plan(s)
          directly to Ayble Health for services rendered. I understand that I am
          financially responsible for charges not covered by my insurance
          company. By checking this box I agree with these terms as well as the
          terms in the{' '}
          <Link
            _text={{ textTransform: 'none' }}
            onPress={(e) => {
              e?.stopPropagation();
              setShowFullRelease(true);
            }}
          >
            full benefits assessment.
          </Link>
        </>
      ),
    },
  ];
  return (
    <>
      <FormControl.Label mt={5}>
        Please review and acknowledge the receipt of:
      </FormControl.Label>

      {requiredTermsConfig.map((section) => {
        return (
          <Pressable
            my={2}
            onPress={(e) => {
              //@ts-ignore
              if (e.target.id !== section.id) {
                setVisibleSections((prev) => ({
                  ...prev,
                  [section.id]: !prev[section.id],
                }));
              }
            }}
          >
            <Box p={4} borderColor="muted.400" borderWidth={1} borderRadius={4}>
              <Flex
                flexDir={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Flex flexDir={'row'} alignItems={'center'}>
                  <input
                    type="checkbox"
                    name={section.id}
                    id={section.id}
                    checked={formData[section.id]}
                    onChange={(e) => {
                      e.stopPropagation();
                      setData({
                        ...formData,
                        [section.id]: e.target.checked,
                      });
                      onChange();
                    }}
                    data-testid={section.id}
                  />
                  <Text ml={2}>{section.title}</Text>
                </Flex>

                <Image
                  size={4}
                  source={{ uri: chevronDown }}
                  style={{
                    transform: [
                      {
                        rotate: visibleSections[section.id] ? '180deg' : '0deg',
                      },
                    ],
                  }}
                />
              </Flex>
              {visibleSections[section.id] && (
                <Text mt={4} lineHeight={21}>
                  {section.content}
                </Text>
              )}
            </Box>
          </Pressable>
        );
      })}
    </>
  );
};

export default RequiredTerms;
