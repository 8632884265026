import {
  Box,
  Button,
  Flex,
  VStack,
  Text,
  useBreakpointValue,
  Center,
} from 'native-base';
import { DropheadItem } from './DropheadItem';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import { AffiliateType } from '../../models/affiliate';
import { MAX_CONTENT_WIDTH_PX } from './constants';

const boardUrl = '../../board-hearts.png';
const pcUrl = '../../personal-computer-hearts.png';
const creditCardUrl = '../../credit-card.png';
const employeeCardUrl = '../../employee-card.png';

type Item = {
  imgUrl: string;
  text: string;
  title: string;
};

const HEALTH_PLAN_ITEMS: Item[] = [
  {
    imgUrl: boardUrl,
    text: 'Share your gut health concerns and goals, and our digestive experts will create a personalized plan for lasting relief.',
    title: 'Get your custom care plan',
  },
  {
    imgUrl: pcUrl,
    text: 'Use the Ayble Health app to message your care team, identify your trigger foods, build psychology skills for a calmer gut, track symptoms, buy groceries and more!',
    title: 'Meet your care team and find relief',
  },
  {
    imgUrl: creditCardUrl,
    text: 'We accept most insurance plans, so you can focus on your health while we handle the rest. Low-cost plans are also available.',
    title: 'Pay with insurance',
  },
];

const EMPLOYER_ITEMS: Item[] = [
  {
    imgUrl: boardUrl,
    text: 'Share your gut health concerns and goals, and our digestive experts will create a personalized plan for lasting relief.',
    title: 'Get your custom care plan',
  },
  {
    imgUrl: pcUrl,
    text: 'Use the Ayble Health app to message your care team, identify your trigger foods, build psychology skills for a calmer gut, track symptoms, buy groceries and more!',
    title: 'Meet your care team and find relief',
  },
  {
    imgUrl: employeeCardUrl,
    text: 'Ayble Health is available at no direct cost to you. Verify your eligibility today! Finally, find digestive relief and feel better, for longer!',
    title: 'All at no cost to you',
  },
];

const HEALTH_SYSTEM_ITEMS: Item[] = [
  {
    imgUrl: boardUrl,
    text: 'Share your gut health concerns and goals, and our digestive experts will create a personalized plan for lasting relief alongside your provider.',
    title: 'Get your custom care plan',
  },
  {
    imgUrl: pcUrl,
    text: 'Use the Ayble Health app to message your care team, identify your trigger foods, build psychology skills for a calmer gut, track symptoms, buy groceries and more!',
    title: 'Meet your care team and find relief',
  },
  {
    imgUrl: creditCardUrl,
    text: 'We accept most insurance plans, so you can focus on your health while we handle the rest. Low-cost plans are also available.',
    title: 'Pay with insurance',
  },
];

const AffiliateTypeItemsMap: Record<AffiliateType, Item[]> = {
  [AffiliateType.employer]: EMPLOYER_ITEMS,
  [AffiliateType.healthPlan]: HEALTH_PLAN_ITEMS,
  [AffiliateType.healthSystem]: HEALTH_SYSTEM_ITEMS,
};

type Props = {
  onGetStartedButtonClick: () => void;
};

export const Drophead = ({ onGetStartedButtonClick }: Props) => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const defaultItems = fetchedAffiliate?.affiliate_type
    ? AffiliateTypeItemsMap[fetchedAffiliate.affiliate_type]
    : AffiliateTypeItemsMap[AffiliateType.healthPlan];

  // if body_text is present, we update the last item to contain that copy
  const actualAffiliateItems = fetchedAffiliate?.body_text
    ? [
        ...defaultItems.slice(0, 2),
        {
          ...defaultItems[2],
          text: fetchedAffiliate?.body_text,
        },
      ]
    : defaultItems;

  return (
    <Box backgroundColor={'secondary.500'}>
      <Center backgroundColor={'muted.100'} py={12} borderTopRadius={12}>
        <VStack alignItems={'center'} maxW={MAX_CONTENT_WIDTH_PX} py={12}>
          <Flex direction={isMobile ? 'column' : 'row'}>
            {actualAffiliateItems?.map(({ imgUrl, text, title }) => (
              <DropheadItem
                html={text}
                imgUrl={imgUrl}
                key={imgUrl}
                title={title}
              />
            ))}
          </Flex>

          <Box
            minW={'190px'}
            mt={isMobile ? 0 : 20}
            px={{ base: 8, sm: 0 }}
            w={{ base: '100%', sm: '30%' }}
          >
            <Button
              _text={{ color: 'secondary.900', textTransform: 'none' }}
              height={{ base: undefined, sm: '72px' }}
              onPress={onGetStartedButtonClick}
              rounded={'72px'}
              size={{ base: 'sm', sm: 'md' }}
              w={'100%'}
            >
              <Text
                color={'secondary.500'}
                fontSize={{ base: undefined, sm: 'xl' }}
                fontWeight={'bold'}
              >
                GET STARTED
              </Text>
            </Button>
          </Box>
        </VStack>
      </Center>
    </Box>
  );
};
