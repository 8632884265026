import styled from '@emotion/styled';
import {
  Box,
  Flex,
  Heading,
  Image,
  useBreakpointValue,
  useTheme,
  VStack,
} from 'native-base';
import {
  HEADER_4_MOBILE_SIZE_PX,
  HEADER_4_SIZE_PX,
  MAX_CONTENT_WIDTH_PX,
} from './constants';

const vogueLogoUrl = '../../vogue_logo.png';
const glamourLogoUrl = '../../glamour_logo.png';
const everydayHealthLogoUrl = '../../everyday_health_logo.png';
const ReadersDigestLogoUrl = '../../readers_digest_logo.png';

const LOGOS = [
  vogueLogoUrl,
  glamourLogoUrl,
  everydayHealthLogoUrl,
  ReadersDigestLogoUrl,
];

const ImgContainer = styled(Box)`
  & > div > div {
    background-position: left;
  }
`;

const Grid = styled('div')<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? 'repeat(2, auto);' : 'repeat(4, auto);'};
  grid-template-rows: ${({ isMobile }) =>
    isMobile ? 'repeat(2, auto);' : 'repeat(1, auto);'};
  grid-column-gap: ${({ isMobile }) => (isMobile ? '8px' : null)};
  grid-row-gap: ${({ isMobile }) => (isMobile ? '8px' : null)};
  justify-content: space-between;
  width: 100%;
`;

export const Logos = () => {
  const {
    colors: { secondary },
  } = useTheme();

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  return (
    <Box backgroundColor={'secondary.500'}>
      <VStack
        alignItems={'center'}
        backgroundColor={'muted.100'}
        py={12}
        pt={{ base: undefined, sm: 12 }}
        borderBottomRadius={12}
      >
        <Heading
          style={{ color: secondary['500'] }}
          fontSize={{ base: HEADER_4_MOBILE_SIZE_PX, sm: HEADER_4_SIZE_PX }}
        >
          As Seen In:
        </Heading>

        <Flex
          width={'100%'}
          maxW={MAX_CONTENT_WIDTH_PX}
          alignItems={'center'}
          direction={isMobile ? 'column' : 'row'}
          justifyContent={'center'}
          pt={5}
          px={{ base: 8, lg: 24 }}
        >
          <Grid isMobile={isMobile}>
            {LOGOS.map((logoUrl) => (
              <ImgContainer key={logoUrl}>
                <Image
                  alt={'Testimonial logo'}
                  height={{ base: '130px', md: '200px', lg: '300px' }}
                  resizeMode={'contain'}
                  size={'2xl'}
                  source={{
                    uri: logoUrl,
                  }}
                  width={{ base: '180px', md: '260px' }}
                />
              </ImgContainer>
            ))}
          </Grid>
        </Flex>
      </VStack>
    </Box>
  );
};
