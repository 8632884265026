import { useState, useEffect, useCallback } from 'react';
import { VStack, Input, FormControl, Box, Radio, Flex } from 'native-base';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { FORM_ACTIONS } from '../../utils/formActions.js';
import SignUpInfoLocation from './SignUpInfoLocation.js';
import { useAffiliate } from '../../api/affiliates/useAffiliate';
import SignUpInfoAffiliateLookup from './SignUpInfoAffiliateLookup.js';

/*
  Dear future reader, this file contains the logic which handles how 
  an affiliate and referrer is set for the register endpoint. Most
  of this data is pulled from ReactQuery, and stored via formData with 
  a dispatch. This is because we need to maintain selections made by the user
  so we can more easily pass them to the register endpoint. I'd prefer to not store
  so much in the formData, but due to how our overall onboarding flow is built, this was
  necessary.

  One other little tricky note, whenever we set `requiredReferrerId`, we fetch the more detailed
  required referrer data to show messaging. This primarily happens when they select an affiliate
  from the affiliate lookup, however, the url utm_source can also have a required affiliate, so we
  fetch that via a useEffect (by setting that id). Lastly, if the required referrer already exists
  in the formData, we fetch it via that value. This is to cover cases where the user registers, and
  wants to come back and we need to pre-populate the form with this data.

  None if this is as clean as I'd like it to be, and for that, I apologize!
*/

function SignUpInfo({ formData, dispatch, isMobile }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const [touched, setTouched] = useState({});
  const [requiredReferrerId, setRequiredReferrerId] = useState(null);

  const { affiliate: referrerFromUrl } = useAffiliate({
    affiliateId: searchParams.get('utm_source'),
  });

  const {
    affiliate: requiredReferrerData,
    isLoading: isRequiredAffiliateLoading,
  } = useAffiliate({
    affiliateId: requiredReferrerId,
  });

  const updateReferrer = useCallback(
    (value) => {
      dispatch({
        type: FORM_ACTIONS.UPDATE_FORM_FIELD,
        fieldName: 'referrer',
        payload: value,
      });
    },
    [dispatch]
  );

  const updateAffiliate = useCallback(
    (value) => {
      dispatch({
        type: FORM_ACTIONS.UPDATE_FORM_FIELD,
        fieldName: 'selected_affiliate',
        payload: value,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    // If there is a referrer from the url (utm_source), we:
    //   1. store the referrer value on the form data (so user.referrer = utm_source)
    //   2. logic if we should also store user.affiliate = referrer/utm_source
    //        this happens if the referrer is a payer that covers ayble and has no required referrer.
    //        In that case, the payer question doesn't even surface on the account creation form
    //        and, it is assumed that the referrer is the user's payer (which we store as user.affiliate)
    if (referrerFromUrl) {
      updateReferrer(referrerFromUrl.utm_source);

      // const shouldAutomaticallySetAffiliateValue =
      //   referrerFromUrl?.covers_ayble && !referrerFromUrl.required_referrer;

      if (
        !formData.selected_affiliate
        // shouldAutomaticallySetAffiliateValue
      ) {
        updateAffiliate(referrerFromUrl.utm_source);
      }

      // If the referrer has a required_referrer, then load the required_referrers data
      if (referrerFromUrl.required_referrer) {
        setRequiredReferrerId(referrerFromUrl.required_referrer);
      }
    }
    // If they had previously selected an affiliate which had a required_referrer
    // We want to load that requiredReferrer data up again
    if (formData.required_referrer) {
      setRequiredReferrerId(formData.required_referrer);
    }
    // disabling deps because we truly only want this to run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAffilSelection = (selection) => {
    if (selection.required_referrer) {
      setRequiredReferrerId(selection.required_referrer);
      dispatch({
        type: FORM_ACTIONS.UPDATE_FORM_FIELD,
        fieldName: 'required_referrer',
        payload: selection.required_referrer,
      });
    }

    updateAffiliate(selection.utm_source);
    updateReferrer(referrerFromUrl?.utm_source);

    /* When they select an affiliate we want
    autofill out the input with the name of their selection */
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'affiliate_name',
      payload: selection.name,
    });
  };

  const showRequiredReferrerPrompt =
    (formData.selected_affiliate || formData.affiliate) &&
    requiredReferrerData?.utm_source !== referrerFromUrl?.utm_source &&
    !isRequiredAffiliateLoading;

  return (
    <VStack space={1} width="100%" mt={6}>
      <Box minWidth="200" width="100%">
        <FormControl isRequired style={{ display: 'flex' }}>
          <FormControl.Label>First name</FormControl.Label>

          <Input
            autoComplete={'off'}
            minWidth="200"
            maxWidth={isMobile ? '100%' : '70%'}
            type="text"
            testID={'signup-first-name-input'}
            value={formData.firstName}
            onChangeText={(e) => {
              setTouched({ ...touched, firstName: true });
              dispatch({
                type: FORM_ACTIONS.UPDATE_FORM_FIELD,
                fieldName: 'firstName',
                payload: e,
              });
            }}
          />
        </FormControl>

        <FormControl isRequired style={{ display: 'flex' }}>
          <FormControl.Label>Last name</FormControl.Label>

          <Input
            autoComplete={'off'}
            minWidth="200"
            maxWidth={isMobile ? '100%' : '70%'}
            type="text"
            testID={'signup-last-name-input'}
            value={formData.lastName}
            onChangeText={(e) => {
              setTouched({ ...touched, lastName: true });
              dispatch({
                type: FORM_ACTIONS.UPDATE_FORM_FIELD,
                fieldName: 'lastName',
                payload: e,
              });
            }}
          />
        </FormControl>

        <FormControl
          isInvalid={
            formData.email.length > 0 && formData.email.indexOf('@') < 0
          }
          isRequired
          style={{ display: 'flex' }}
        >
          <FormControl.Label>Email address</FormControl.Label>

          <Input
            autoComplete={'off'}
            minWidth="200"
            maxWidth={isMobile ? '100%' : '70%'}
            type="email"
            testID={'signup-email-input'}
            value={formData.email}
            onChangeText={(e) => {
              setTouched({ ...touched, email: true });
              dispatch({
                type: FORM_ACTIONS.UPDATE_FORM_FIELD,
                fieldName: 'email',
                payload: e,
              });
            }}
          />
          {!!touched['email'] && (
            <FormControl.ErrorMessage h={4} ml={1} mb={4} mt={-3}>
              Must be a valid email
            </FormControl.ErrorMessage>
          )}
        </FormControl>

        <SignUpInfoLocation
          dispatch={dispatch}
          defaultValue={{ value: formData.state, label: formData.state }}
          isMobile={isMobile}
        />

        {/* Only show the affiliate lookup if the utm_source doesn't cover ayble */}
        {!referrerFromUrl?.covers_ayble && (
          <SignUpInfoAffiliateLookup
            isMobile={isMobile}
            affiliateName={formData.affiliate_name}
            selectedAffiliate={formData.selected_affiliate}
            onAffilSelection={handleAffilSelection}
            onClear={() => {
              dispatch({
                type: FORM_ACTIONS.UPDATE_FORM_FIELD,
                fieldName: 'affiliate_name',
                payload: '',
              });
            }}
            onFieldChange={(e) => {
              // Clear out the affiliate and any required affiliate data
              updateAffiliate(null);
              setRequiredReferrerId(null);
              queryClient.removeQueries({
                queryKey: ['affiliate', requiredReferrerId],
              });

              dispatch({
                type: FORM_ACTIONS.UPDATE_FORM_FIELD,
                fieldName: 'affiliate_name',
                payload: e,
              });
            }}
          />
        )}

        {showRequiredReferrerPrompt && (
          <FormControl>
            <FormControl.Label
              style={{
                marginBottom: 8,
                marginTop: 16,
              }}
            >
              Have you ever been a patient at {requiredReferrerData?.name}?
            </FormControl.Label>
            <Radio.Group
              autoComplete={'off'}
              name="requiredReferrer"
              accessibilityLabel={`Have you ever been a patient at ${requiredReferrerData?.name}?`}
              value={formData.from_required_referrer}
              onChange={(e) => {
                if (e) {
                  updateReferrer(requiredReferrerData?.utm_source);
                } else {
                  updateReferrer(referrerFromUrl?.utm_source);
                }

                dispatch({
                  type: FORM_ACTIONS.UPDATE_FORM_FIELD,
                  fieldName: 'from_required_referrer',
                  payload: e,
                });
              }}
            >
              <Flex flexDir={'row'} gap="10px">
                <Radio size={'sm'} value={true}>
                  Yes
                </Radio>
                <Radio size="sm" value={false}>
                  No
                </Radio>
              </Flex>
            </Radio.Group>
          </FormControl>
        )}
      </Box>
    </VStack>
  );
}

export default SignUpInfo;
