import styled from '@emotion/styled';
import { Box, Heading, Image, ITheme, Text, VStack } from 'native-base';
import { AffiliateType } from '../../models/affiliate';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import { StatBox } from './StatBox';
import {
  BODY_1_MOBILE_SIZE_PX,
  BODY_1_SIZE_PX,
  BODY_2_MOBILE_SIZE_PX,
  BODY_2_SIZE_PX,
  HEADER_2_MOBILE_SIZE_PX,
  HEADER_2_SIZE_PX,
} from './constants';

const logo = '../../FA__ayble_health_Logo_Isabelline_white.png';

const Link = styled('a')<{ theme?: ITheme }>`
  color: white;
  text-decoration: underline;
  transition: color linear 200ms;

  &:hover {
    color: ${(p) => p.theme.colors.primary['600']};
    cursor: pointer;
  }
`;

// map of key-value pairs where the key is an affiliate type,
// and the value is a function returning a node including the content to show.
type AffiliateTypeGenContentFnMap = Record<
  AffiliateType,
  (affiliateName: string | undefined) => React.ReactNode
>;

const contentAffiliateTypeMap: AffiliateTypeGenContentFnMap = {
  [AffiliateType.employer]: (affiliateName: string | undefined) => (
    <>
      {affiliateName} has partnered with Ayble Health to make whole-person
      virtual digestive care available to you for free. To verify your
      eligibility, you'll need to specify your health insurance member ID and
      group ID. So make sure to have your insurance card ready!
    </>
  ),
  [AffiliateType.healthPlan]: () => (
    <>
      Make sure to have your insurance card ready! To verify your <br />
      eligibility, you'll need to specify your health insurance member ID and
      group ID.
    </>
  ),
  [AffiliateType.healthSystem]: () => (
    <>
      Make sure to have your insurance card ready! To verify your eligibility,
      you'll need to specify your health insurance member ID and group ID.
      Low-cost plans are also available!
    </>
  ),
};

export const Footer = () => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});

  const genFn = fetchedAffiliate?.affiliate_type
    ? contentAffiliateTypeMap[fetchedAffiliate.affiliate_type]
    : contentAffiliateTypeMap[AffiliateType.healthPlan];
  const content = genFn(fetchedAffiliate?.name);

  return (
    <VStack
      alignItems={'center'}
      bgColor={'secondary.500'}
      p={{ base: 8, md: 12 }}
    >
      <StatBox />

      <Heading
        color={'white'}
        fontSize={{ base: HEADER_2_MOBILE_SIZE_PX, sm: HEADER_2_SIZE_PX }}
        textAlign={'center'}
      >
        Get affordable, expert digestive care with Ayble Health today.
      </Heading>

      <Text
        color={'white'}
        fontSize={{ base: BODY_1_MOBILE_SIZE_PX, sm: BODY_1_SIZE_PX }}
        fontWeight={'medium'}
        pt={12}
        textAlign={'center'}
      >
        Ayble helps manage digestive health, whether you have IBS,
        <br /> Crohn’s Disease, Ulcerative Colitis, or general digestive
        discomfort.
      </Text>

      <Text
        color={'white'}
        fontSize={{ base: BODY_1_MOBILE_SIZE_PX, sm: BODY_1_SIZE_PX }}
        fontWeight={'medium'}
        pt={12}
        textAlign={'center'}
      >
        {content}
      </Text>

      <Text
        color={'white'}
        fontSize={{ base: BODY_2_MOBILE_SIZE_PX, sm: BODY_2_SIZE_PX }}
        fontWeight={'medium'}
        pt={12}
        textAlign={'center'}
      >
        Any questions? Email:{' '}
        <Link href="mailto:support@ayblehealth.com">
          support@ayblehealth.com
        </Link>
      </Text>

      <Box py={16}>
        <Image
          alt="Ayble Health logo"
          resizeMode={'contain'}
          size={{ base: '32', sm: '2xs' }}
          source={{
            uri: logo,
          }}
          testID={'ayble-health-logo'}
        />
      </Box>
    </VStack>
  );
};
