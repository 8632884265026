import * as React from 'react';
import { Heading, Box, VStack, Text, Divider, HStack } from 'native-base';
import { CelebrationResults } from './CelebrationResults';
import { ImageBackground } from 'react-native';
import { getCompletedPhaseNumber } from '../UserDashboard/utils';

const formatScore = (score) => {
  const rawScore = (score /= Math.pow(10, -1));
  return rawScore.toFixed(1);
};

const phase1CelebrateImg = '../../../celebrate-phase1-completion.png';
const phase2CelebrateImg = '../../../celebrate-phase2-completion.png';
const phase3CelebrateImg = '../../../celebrate-phase3-completion.png';
const phase1StarImg = '../../../phase1-star.png';
const phase2StarImg = '../../../phase2-star.png';
const phase3StarImg = '../../../phase3-star.png';

export const CelebrationMain = ({ selectedPhase, user }) => {
  const styles = {
    starContainer: {
      position: 'relative',
      top: 0,
      left: 0,
    },
    bgStar: {
      position: 'absolute',
      top: '-80%',
      left: '25%',
      width: 65,
      height: 65,
    },
    smStar: {
      width: 45,
      height: 45,
    },
    congrats: {
      minWidth: '100%',
      minHeight: 450,
    },
  };

  const name = user ? (user.first_name ? user.first_name : user.email) : 'User';

  const congratsSubText = (phase) => {
    switch (phase) {
      case 2:
        return `You're halfway there on your journey to better gut health!`;
      case 3:
        return 'Your journey to better gut health is almost complete!';
      default:
        return '';
    }
  };
  const getGutCheckDays = () => {
    switch (selectedPhase) {
      case 1:
        return user.phase_1_summary.days_logged ?? '--';
      case 2:
        return user.phase_2_summary.days_logged ?? '--';
      case 3:
        return user.phase_3_summary.days_logged ?? '--';
      case 4:
        return user.phase_4_summary.days_logged ?? '--';
      default:
        return '--';
    }
  };

  const highestStreak = () => {
    switch (selectedPhase) {
      case 1:
        return user.phase_1_summary.longest_streak ?? '--';
      case 2:
        return user.phase_2_summary.longest_streak ?? '--';
      case 3:
        return user.phase_3_summary.longest_streak ?? '--';
      case 4:
        return user.phase_4_summary.longest_streak ?? '--';
      default:
        return '--';
    }
  };

  const avgAybleScore = () => {
    switch (selectedPhase) {
      case 1:
        return typeof user.phase_1_summary.average_ayble_score == 'number'
          ? formatScore(user.phase_1_summary.average_ayble_score)
          : '--';
      case 2:
        return typeof user.phase_2_summary.average_ayble_score == 'number'
          ? formatScore(user.phase_2_summary.average_ayble_score)
          : '--';
      case 3:
        return typeof user.phase_3_summary.average_ayble_score == 'number'
          ? formatScore(user.phase_3_summary.average_ayble_score)
          : '--';
      case 4:
        return typeof user.phase_4_summary.average_ayble_score == 'number'
          ? formatScore(user.phase_4_summary.average_ayble_score)
          : '--';
      default:
        return '--';
    }
  };

  const triggerFoodReduction = () => {
    switch (selectedPhase) {
      case 3:
        return user.phase_2_summary.trigger_food_reduction
          ? user.phase_2_summary.trigger_food_reduction
          : '--';
      default:
        return '0';
    }
  };

  const Congrats = () => {
    const showCongrats =
      selectedPhase <= getCompletedPhaseNumber(user.current_phase);

    const getCongratsImg = () => {
      switch (getCompletedPhaseNumber(user.current_phase)) {
        case 0:
          return null;
        case 1:
          return phase1CelebrateImg;
        case 2:
          return phase2CelebrateImg;
        case 3:
          return phase3CelebrateImg;
        default:
          return phase3CelebrateImg;
      }
    };

    // hide celebration image if at least phase 1 is not completed
    if (!showCongrats) return null;

    return (
      <VStack flex={1} justifyContent={'space-evenly'}>
        <Heading
          fontFamily={'heading'}
          fontSize="4xl"
          w={'100%'}
          textAlign={'center'}
          mb={2}
        >
          Congrats!
        </Heading>
        <Heading fontSize={'md'} w={'100%'} textAlign={'center'} mb="10px">
          You’ve completed Phase {selectedPhase}, {name}.{' '}
          {congratsSubText(selectedPhase)}
        </Heading>
        <ImageBackground source={getCongratsImg()} style={styles.congrats} />
      </VStack>
    );
  };

  const Summary = () => {
    const isPhaseCompleted =
      selectedPhase <= getCompletedPhaseNumber(user.current_phase);

    const getStarImg = () => {
      switch (getCompletedPhaseNumber(user.current_phase)) {
        case 1:
          return phase1StarImg;
        case 2:
          return phase2StarImg;
        case 3:
          return phase3StarImg;
        default:
          return phase1StarImg;
      }
    };

    // hide this part if at least phase 1 is not completed
    if (getCompletedPhaseNumber(user.current_phase) === 0) return null;

    return (
      <VStack
        justifyContent={'space-evenly'}
        alignItems={'center'}
        px={'4'}
        w={'100%'}
      >
        {isPhaseCompleted && (
          <VStack
            justifyContent={'center'}
            alignItems={'center'}
            styles={styles.starContainer}
            ml={5}
            my={3}
          >
            <ImageBackground source={getStarImg()} style={styles.bgStar} />
            <HStack w={'70%'} justifyContent={'space-around'}>
              <ImageBackground source={getStarImg()} style={styles.smStar} />
              <ImageBackground source={getStarImg()} style={styles.smStar} />
            </HStack>
          </VStack>
        )}

        <Heading
          fontFamily={'heading'}
          fontSize="4xl"
          w={'100%'}
          textAlign={'center'}
          mb={8}
          mt={2}
        >
          Phase {selectedPhase} {isPhaseCompleted ? 'Summary' : 'Progress'}
        </Heading>

        <Box borderColor={'muted.400'} borderWidth={'1px'} borderRadius={8}>
          <Heading
            display={'flex'}
            fontFamily={'heading'}
            fontSize="xl"
            w={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            minH={'80px'}
          >
            <div>
              You completed{' '}
              <Text color={'secondary.100'}>
                {getGutCheckDays()} Gut Checks!
              </Text>
            </div>
          </Heading>
          <Divider />
          <Heading
            display={'flex'}
            fontFamily={'heading'}
            fontSize="xl"
            w={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            minH={'80px'}
          >
            <div>
              Your highest streak of consecutive Gut Checks:{' '}
              <Text color={'secondary.100'}>{highestStreak()} days</Text>
            </div>
          </Heading>

          <Divider />

          {selectedPhase === 3 && (
            <>
              <Heading
                display={'flex'}
                fontFamily={'heading'}
                fontSize="xl"
                w={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                minH={'80px'}
              >
                <div>
                  You reduced your trigger food intake by{' '}
                  <Text color={'secondary.100'}>{triggerFoodReduction()}%</Text>
                </div>
              </Heading>
              <Divider />
            </>
          )}

          <Heading
            display={'flex'}
            fontFamily={'heading'}
            fontSize="xl"
            w={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            minH={'80px'}
          >
            <div>
              Your average Ayble score in phase {selectedPhase} was{' '}
              <Text color={'secondary.100'}>{avgAybleScore()}</Text>
            </div>
          </Heading>
        </Box>
      </VStack>
    );
  };

  return (
    <VStack h={'100%'} w="100%" justifyContent={'center'}>
      <Congrats />
      <Summary />
      <CelebrationResults selectedPhase={selectedPhase} user={user} />
    </VStack>
  );
};
