import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  TextArea,
  VStack,
} from 'native-base';
import { PencilIcon } from '../../icons/PencilIcon';
import { IconButton } from '../Form/IconButton';
import { useCoachUserNotes } from '../../api/healthCoach/useCoachUserNotes';
import { Loader } from '../Loader';
import { useParams } from 'react-router-dom';
import { useUserDiagnosisAndSymptom } from '../../api/healthCoach/useUserDiagnosisAndSymptom';
import { useSymptomSelect } from '../../api/healthCoach/useSymptomSelect';
import { useDiagnosisSelect } from '../../api/healthCoach/useDiagnosisSelect';
import { useUpdateCoachUserNotes } from '../../api/healthCoach/mutations/useUpdateCoachUserNotes';
import { useUpdateUserDiagnosisAndSymptom } from '../../api/healthCoach/mutations/useUpdateUserDiagnosisAndSymptom';
import { ListWithNotes } from './ListWithNotes';

const convertSelectOptionsToDiagnosisAndSymptom = (dxOptions, sxOptions) => {
  const diagnosis = dxOptions
    .filter((d) => !!d.value)
    .map((d) => ({
      id: d.value,
      diagnosis: d.label,
    }));

  const symptom = sxOptions
    .filter((s) => !!s.value)
    .map((s) => ({
      id: s.value,
      symptom: s.label,
    }));

  return { diagnosis, symptom };
};

const convertDiagnosisAndSymptomToSelectOptions = (diagnosis, symptom) => {
  const dxOptions = diagnosis?.map(({ id, diagnosis }) => ({
    label: diagnosis,
    value: id,
  }));
  const sxOptions = symptom?.map(({ id, symptom }) => ({
    label: symptom,
    value: id,
  }));

  return { dxOptions, sxOptions };
};

export const CoachNotes = () => {
  const { patient } = useParams();
  const { userNotes, isLoading, isError } = useCoachUserNotes(patient);
  const { mutation } = useUpdateCoachUserNotes();
  const { loadDiagnosisOptions } = useDiagnosisSelect();
  const { loadSymptomOptions } = useSymptomSelect();
  const {
    data: userDiagnosisAndSymptom,
    isLoading: isUserDiagnosisAndSymptomLoading,
  } = useUserDiagnosisAndSymptom(patient);
  const { mutateUserDiagnosisAndSymptom } = useUpdateUserDiagnosisAndSymptom(
    patient,
    userDiagnosisAndSymptom
  );

  const [isEditing, setIsEditing] = useState(false);
  const [generalNotes, setGeneralNotes] = useState('');
  const [dxValidationMessage, setDxValidationMessage] = useState('');
  const [sxValidationMessage, setSxValidationMessage] = useState('');
  const [dxGeneralNotes, setDxGeneralNotes] = useState('');
  const [sxGeneralNotes, setSxGeneralNotes] = useState('');
  const [dxSelectOptions, setDxSelectOptions] = useState([]);
  const [sxSelectOptions, setSxSelectOptions] = useState([]);

  const canAddNewDx = dxSelectOptions?.every((opt) => !!opt.value);
  const canAddNewSx = sxSelectOptions?.every((opt) => !!opt.value);

  const updateNotesWithFetchedVals = useCallback(() => {
    if (!userNotes) return;

    const { general, dx_notes, sx_notes } = userNotes;

    setGeneralNotes(general);
    setDxGeneralNotes(dx_notes);
    setSxGeneralNotes(sx_notes);
  }, [userNotes]);

  const updateDiagnosesWithFetchedVals = useCallback(() => {
    if (!userDiagnosisAndSymptom) return;

    const { diagnosis, symptom } = userDiagnosisAndSymptom;
    const { dxOptions, sxOptions } = convertDiagnosisAndSymptomToSelectOptions(
      diagnosis,
      symptom
    );

    setDxSelectOptions(dxOptions);
    setSxSelectOptions(sxOptions);
  }, [userDiagnosisAndSymptom]);

  useEffect(() => {
    if (canAddNewDx) setDxValidationMessage('');
    if (canAddNewSx) setSxValidationMessage('');
  }, [canAddNewDx, canAddNewSx]);

  useEffect(updateNotesWithFetchedVals, [updateNotesWithFetchedVals]);
  useEffect(updateDiagnosesWithFetchedVals, [updateDiagnosesWithFetchedVals]);

  const saveChanges = async () => {
    const { diagnosis, symptom } = convertSelectOptionsToDiagnosisAndSymptom(
      dxSelectOptions,
      sxSelectOptions
    );

    // first save coach user notes
    await mutation.mutateAsync({
      method: !!userNotes ? 'PATCH' : 'POST',
      userNotes: {
        id: !!userNotes ? userNotes.id : undefined,
        user_id: patient,
        sx_notes: sxGeneralNotes,
        dx_notes: dxGeneralNotes,
        general: generalNotes,
      },
    });

    // then save the user associated diagnoses and symptoms
    await mutateUserDiagnosisAndSymptom.mutateAsync({
      diagnosis,
      symptom,
    });

    // clean up in case of save with empty values that did not perform any network request
    const cleanSx = sxSelectOptions.filter((o) => !!o.value);
    const cleanDx = dxSelectOptions.filter((o) => !!o.value);
    setSxSelectOptions(cleanSx);
    setDxSelectOptions(cleanDx);

    setIsEditing(false);
  };

  const discardChanges = () => {
    setIsEditing(false);
    updateNotesWithFetchedVals();
    updateDiagnosesWithFetchedVals();
  };

  if (isLoading || isUserDiagnosisAndSymptomLoading) {
    return (
      <Box backgroundColor="muted.100" p={0}>
        <Loader loading={true} />
      </Box>
    );
  }

  return (
    <VStack>
      <Flex flexDir={'row'} alignItems={'center'}>
        <Heading fontWeight={'normal'} size={'md'} textAlign="left">
          General Notes
        </Heading>

        {!isEditing && (
          <IconButton
            onClick={() => {
              setIsEditing((isEditingState) => !isEditingState);
            }}
          >
            <PencilIcon />
          </IconButton>
        )}

        {isEditing && (
          <HStack h={'24px'} alignItems={'center'}>
            <Button
              isDisabled={mutation.isLoading}
              h={'20px'}
              minH={'16px'}
              ml={2}
              onPress={discardChanges}
              rounded={3}
              size="xs"
              variant={'outline'}
              w={'16px'}
            >
              Cancel
            </Button>

            <Button
              h={'20px'}
              isLoading={
                mutation.isLoading || mutateUserDiagnosisAndSymptom.isLoading
              }
              isDisabled={
                mutation.isLoading || mutateUserDiagnosisAndSymptom.isLoading
              }
              minH={'16px'}
              ml={2}
              onPress={saveChanges}
              rounded={3}
              size="xs"
              w={'16px'}
            >
              Save
            </Button>
          </HStack>
        )}
      </Flex>

      {(isError || mutation.isError) && (
        <Text mb={6} fontSize={'sm'} color={'error.600'}>
          An error occurred while trying to load or save the coach notes: please
          refresh the page and try again.
        </Text>
      )}

      <VStack>
        {isEditing ? (
          <TextArea
            mt={3}
            marginBottom={0}
            type="text"
            fontSize={'md'}
            placeholder="Write some general notes..."
            h={40}
            w={'100%'}
            maxW={'100%'}
            isReadOnly={!isEditing}
            value={generalNotes}
            onChange={(e) => {
              setGeneralNotes(e.target.value);
            }}
          />
        ) : (
          (generalNotes && (
            <Text fontSize={'md'} mt={2}>
              {generalNotes}
            </Text>
          )) || (
            <Text italic fontSize={'md'} pt={3}>
              No general notes for this user
            </Text>
          )
        )}
      </VStack>

      <VStack mt={3}>
        <ListWithNotes
          entries={dxSelectOptions}
          errorMessage={dxValidationMessage}
          isEditing={isEditing}
          label={'Dx'}
          loadOptions={loadDiagnosisOptions}
          notes={dxGeneralNotes}
          onAddEntry={(newEntry) => {
            if (!canAddNewDx) {
              setDxValidationMessage(
                'Please fill the empty option below before adding a new one.'
              );
              return;
            }

            const newDx = [newEntry, ...dxSelectOptions];
            setDxSelectOptions(newDx);
          }}
          onEntriesChange={setDxSelectOptions}
          onNotesChange={setDxGeneralNotes}
        />
      </VStack>

      <VStack mt={3}>
        <ListWithNotes
          entries={sxSelectOptions}
          errorMessage={sxValidationMessage}
          isEditing={isEditing}
          label={'Sx'}
          loadOptions={loadSymptomOptions}
          notes={sxGeneralNotes}
          onAddEntry={(newEntry) => {
            if (!canAddNewSx) {
              setSxValidationMessage(
                'Please fill the empty option below before adding a new one.'
              );
              return;
            }

            const newSx = [newEntry, ...sxSelectOptions];
            setSxSelectOptions(newSx);
          }}
          onEntriesChange={setSxSelectOptions}
          onNotesChange={setSxGeneralNotes}
        />
      </VStack>
    </VStack>
  );
};
