import { useState, useRef } from 'react';
import {
  Button,
  Modal,
  Input,
  FormControl,
  Text,
  Heading,
  VStack,
  Flex,
} from 'native-base';
import { useApi } from '../../hooks';


export const LoginModal = ({ showLogin, setShowLogin }) => {
  const [loginMessage, setLoginMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const loginEmailRef = useRef();
  const { api } = useApi();

  const sendNewLoginLink = async (email) => {
    setIsLoading(true);

    try {
      // TODO: Setup better random nonce/state value to confirm auth flow
      const nonce = parseInt(Math.random() * 1000 * (Math.random() * 1000));
      const url = `/login/${nonce}`;

      const request = {
        route: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        payload: { email },
      };

      await api(request);

      setLoginMessage(`Login link sent to: ${email}`);

      setIsLoading(false);
    } catch (err) {
      setLoginMessage(err.message || 'Failed to send login link');
      console.error('Failed Register User', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      zIndex={1000}
      isOpen={showLogin}
      onClose={() => setShowLogin(false)}
      justifyContent="start"
      mt="5"
    >
      <Modal.Content maxWidth="400px" bg={'light.100'} p={4}>
        <Modal.Header bg={'transparent'}>
          <Heading textAlign={'start'} fontSize={'2xl'}>
            Log In with email
          </Heading>
        </Modal.Header>
        <Modal.CloseButton />
        <Modal.Body>
          {loginMessage !== '' && <Text>{loginMessage}</Text>}
          {isLoading ? (
            <Text>Sending...</Text>
          ) : (
            <FormControl mt="3">
              <FormControl.Label>Email</FormControl.Label>
              <Input
                ref={loginEmailRef}
                type="email"
                placeholder="example@ayblehealth.com"
              />
            </FormControl>
          )}
        </Modal.Body>
        <Modal.Footer borderColor={'transparent'} bg={'light.100'}>
          <VStack space={2} w={'100%'} bg={'light.100'}>
            <Button
              minHeight={'unset'}
              h={'40px'}
              disabled={isLoading}
              onPress={() => {
                sendNewLoginLink(loginEmailRef.current.value);
              }}
            >
              Send Login Link
            </Button>
            <Button
              variant="outline"
              minHeight={'unset'}
              h={'40px'}
              onPress={() => {
                setShowLogin(false);
              }}
            >
              Cancel
            </Button>
            <Flex
              flexDirection={'row'}
              justifyContent={'center'}
              mt={5}
              w={'100%'}
            >
            </Flex>
          </VStack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default LoginModal;
