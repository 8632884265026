import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../../hooks/useApi';

export const useEligibilityRequest = () => {
  const { api } = useApi({ throwIfError: true });

  const mutation = useMutation({
    mutationFn: (userData) => {
      return api({
        route: `/eligibility_request/`,
        method: 'POST',
        payload: userData,
      });
    },
  });

  return { mutation };
};

export default useEligibilityRequest;
