import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Center, Container, Progress, View, VStack } from 'native-base';
import * as Sentry from '@sentry/react';

import { FORM_ACTIONS } from '../../utils/formActions';
import HeaderNav from '../HeaderNav/HeaderNav';
import PregnancyInfo from './PregnancyInfo';
import EatingDisorderInfo from './EatingDisorderInfo';
import GiSymptomsInfo from './GiSymptomsInfo';
import TransitionGoal from './TransitionGoal';
import AssessmentColitis from './Assessments/AssessmentColitis';
import AssessmentCrohns from './Assessments/AssessmentCrohns';
import AssessmentIBS from './Assessments/AssessmentIBS';
import Identify from './PersonalInfo/Identify';
import { useFormData } from '../../utils/formReducer.js';
import { useApi } from '../../hooks';
import { useSegment } from '../../hooks/useSegment';
import { usePrevious } from '../../hooks/usePrevious';
import { SexAndGender } from './PersonalInfo/SexAndGender';
import { GISurgery } from './GISurgery';
import EndOfFlow from './EndOfFlow';
import {
  TRACKING_DISORDER_REJECT,
  TRACKING_GI_SURGERY_REJECT,
  TRACKING_PAGE_EVENT_MAP,
  TRACKING_PREGNANCY_REJECT,
  TRACKING_SIGN_UP_FAILED,
  TRACKING_SIGN_UP_SUCCEDEED,
  TRACKING_START_SIGN_UP,
} from './constants';
import { BackgroundTexture1SVG } from '../CustomSVGs';
import { BackgroundTexture2SVG } from '../CustomSVGs';
import { isYesGiConditionOnlyForOtherDiagnosis } from './utils';
import { LandingPage } from '../LandingPage/LandingPage';
import GiSymptomsList from './GiSymptomsList';

const styles = {
  formWrapper: {
    marginTop: '20px',
    padding: '16px',
    maxWidth: '650px',
    height: '100%',
    marginBottom: '0',
    paddingBottom: '60px',
    paddingTop: '20px',
    zIndex: 5,
  },
  width: 'auto',
  anchor: {
    color: '#41B4AC',
    textDecoration: 'none',
  },
  container: {
    position: 'relative',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  backgroundTexture1Container: {
    width: 300,
    height: 400,
    overflow: 'hidden',
    position: 'absolute',
    left: 40,
    top: -124,
    zIndex: 1,
  },
  backgroundTexture1: {
    width: 300,
    height: 400,
  },
  backgroundTexture2: {
    width: 300,
    height: 300,
  },
  backgroundTexture2Container: {
    width: 400,
    height: 300,
    overflow: 'hidden',
    position: 'absolute',
    right: -194,
    bottom: 30,
    zIndex: 1,
  },
};

const formLength = 11;

export function Form({ initialPage = -2 }) {
  const { identify, page: trackPageChange, track } = useSegment();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(initialPage);
  const [apiError, setApiError] = useState(null);
  const prevPage = usePrevious(page);
  const [blockPregnancy, setBlockPregnancy] = useState(false);
  const [hasEatingDisorder, setHasEatingDisorder] = useState(false);
  const [hasHadGISurgery, setHasHadGISurgery] = useState(false);
  const { state, dispatch } = useFormData();
  const { api } = useApi({ throwIfError: true });
  const submitRef = useRef();
  const navigate = useNavigate();
  const { search, state: routerState } = useLocation();

  // check if user came from the app and has made a purchase
  const currentURL = window.location;
  const isFromIapFlow = currentURL.toString().indexOf('iap') > 0;

  const trackPageChangeAvoidRepeat = useCallback(
    (page) => {
      // use prev to avoid triggering the same event while re-rendering several times
      if (page !== prevPage) {
        trackPageChange(TRACKING_PAGE_EVENT_MAP[page]);
      }
    },
    [prevPage, trackPageChange]
  );

  const identifyUserOnTrackingTools = useCallback(
    (userId = null, userData = {}) => {
      identify(userId, userData);
      if (userId) {
        Sentry.setUser({ id: userId });
      }
    },
    [identify]
  );

  useEffect(() => {
    if (routerState?.toRegister) {
      setPage(11);
    }
  }, [routerState]);

  useEffect(() => {
    if (isFromIapFlow) {
      dispatch({
        type: FORM_ACTIONS.UPDATE_FORM_FIELD,
        fieldName: 'isFromIapFlow',
        payload: isFromIapFlow,
      });
      setPage(1);
    }
  }, [dispatch, isFromIapFlow]);

  useEffect(() => {
    if (page < -2) {
      setPage(-2);
    }
  }, [page]);

  useEffect(() => {
    const getAdditionalAnalyticsParams = () => {
      if (blockPregnancy) return TRACKING_PREGNANCY_REJECT;
      if (hasEatingDisorder) return TRACKING_DISORDER_REJECT;
      if (hasHadGISurgery) return TRACKING_GI_SURGERY_REJECT;
      return null;
    };
    const additionalAnalyticsParams = getAdditionalAnalyticsParams();

    if (additionalAnalyticsParams) {
      trackPageChange(additionalAnalyticsParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, blockPregnancy, hasEatingDisorder, hasHadGISurgery]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop();
  }, [page]);

  const increasePage = () => {
    setPage((currPage) => currPage + 1);
  };

  const checkPregnancy = () => {
    if (state.pregnant === 'No') {
      setBlockPregnancy(false);
      increasePage();
    } else {
      setBlockPregnancy(true);
    }
  };

  const checkEatingDisorder = () => {
    if (state.hasEatingDisorder === false) {
      increasePage();
    } else {
      setHasEatingDisorder(true);
    }
  };

  const checkGISurgery = () => {
    if (state.hasHadGISurgery === false) {
      increasePage();
    } else {
      setHasHadGISurgery(true);
    }
  };

  const pregnancyException = () => {
    if (state.sex === 'Male' && state.gender === 'Male') {
      setPage((currPage) => currPage + 2);
    } else {
      increasePage();
    }
  };

  const handleBackButton = () => {
    if (hasEatingDisorder) {
      setHasEatingDisorder(false);
    } else if (hasHadGISurgery) {
      setHasHadGISurgery(false);
    } else if (blockPregnancy) {
      setBlockPregnancy(false);
    }
    setPage(1);
  };

  const handleSubmit = () => {
    identifyUserOnTrackingTools(null, {
      email: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
    });

    if (state.email.indexOf('@') > -1) {
      if (page === 0) increasePage();
      submitRef.current.click();
      handleUserRegister();
      return;
    } else {
      alert('Please Enter Your Email');
    }
  };

  const diagnosis = () => {
    if (state.hasUlcerativeColitis) {
      return 'UC Symptoms';
    } else if (state.hasCrohnsDisease) {
      return 'CD Symptoms';
    } else if (state.noDiagnosedGiCondition) {
      return 'IBS & Other';
    } else {
      return 'IBS & Other';
    }
  };

  const diagnosis_secondary = () => {
    if (state.ibsConstipation) return 'IBS Constipation';
    if (state.ibsMixed) return 'IBS Mixed';
    if (state.ibsDiarrhea) return 'IBS Diarrhea';
    if (state.ibsUnspecified) return 'IBS Unspecified';
  };

  const handleUserRegister = async () => {
    setIsLoading(true);
    setApiError(null);
    const assessmentTypes = [
      'onboarding-uc',
      'onboarding-uc-ibs',
      'onboarding-crohns',
      'onboarding-crohns-ibs',
      'onboarding-ibs-all',
      'onboarding-stress',
    ];

    const surveyData = assessmentTypes
      .filter((key) => state[key])
      .map((formKey) => {
        const dataGroup = state[formKey];

        return {
          data: Object.keys(dataGroup).map((fieldKey) => ({
            question: fieldKey,
            answer: dataGroup[fieldKey],
          })),
          type: formKey,
        };
      });

    try {
      const {
        diagnosis_ids,
        symptom_ids,
        email,
        enterpriseCode,
        firstName,
        isFromIapFlow,
        lastName,
        noDiagnosedGiCondition,
        referrer,
        selected_affiliate,
        state: addressState,
      } = state;

      // TODO: Setup better random nonce/state value to confirm auth flow
      const nonce = parseInt(Math.random() * 1000 * (Math.random() * 1000));
      const payload = {
        email,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        enterpriseCode,
        state: addressState,
        symptom_ids,
        diagnosis: diagnosis(),
        diagnosis_secondary: diagnosis_secondary(),
        // when we do not have any GI condition we cannot have diagnosis
        diagnosis_ids: noDiagnosedGiCondition ? [] : diagnosis_ids,
        isFromIapFlow: isFromIapFlow,
        surveys: surveyData,
        affiliate: selected_affiliate,
        referrer,
      };

      ['sex', 'gender', 'race'].forEach((key) => {
        if (state[key] && state[key] !== 'Prefer not to say') {
          const col = key === 'race' ? 'ethnicity' : key; // translate column name for race/ethnicity
          payload[col] = state[key];
        }
      });

      const request = {
        route: `/register/${nonce}`,
        method: 'POST',
        payload,
      };

      track(TRACKING_START_SIGN_UP, payload);

      const response = await api(request);

      setIsLoading(false);

      track(TRACKING_SIGN_UP_SUCCEDEED, response);
      identifyUserOnTrackingTools(response.id, payload);

      navigate(`/phone${search}`, {
        state: {
          affiliate: selected_affiliate,
          referrer,
          email,
          userId: response.id,
          firstName,
          lastName,
        },
      });
    } catch (err) {
      track(TRACKING_SIGN_UP_FAILED, null);
      setApiError(err.message);
      console.error('Failed Register User', err);
    } finally {
      setIsLoading(false);
    }
  };

  const startOnboarding = (trackingEventStr) => {
    setPage(1);
    if (trackingEventStr) track(trackingEventStr);
  };

  const PageDisplay = () => {
    // page 4 gets split in multiple ones
    // so, it is handled inside its if statement
    if (page !== 4) {
      trackPageChangeAvoidRepeat(page);
    }

    switch (page) {
      case -2:
        return <LandingPage onStartOnboarding={startOnboarding} />;

      case 1:
        return <GiSymptomsList onNext={increasePage} />;

      case 2:
        return <TransitionGoal onNext={increasePage} />;

      case 3:
        return <GiSymptomsInfo onNext={increasePage} />;

      // assessments
      case 4: {
        if (state.hasUlcerativeColitis) {
          trackPageChange(TRACKING_PAGE_EVENT_MAP['assessment-a']);
          return <AssessmentColitis onNext={increasePage} />;
        }

        if (state.hasCrohnsDisease) {
          trackPageChange(TRACKING_PAGE_EVENT_MAP['assessment-b']);
          return <AssessmentCrohns onNext={increasePage} />;
        }

        if (
          state.ibsConstipation ||
          state.ibsMixed ||
          state.ibsDiarrhea ||
          state.ibsUnspecified
        ) {
          trackPageChange(TRACKING_PAGE_EVENT_MAP['assessment-c']);
          return <AssessmentIBS onNext={increasePage} />;
        }

        if (
          state.noDiagnosedGiCondition ||
          isYesGiConditionOnlyForOtherDiagnosis(state)
        ) {
          trackPageChange(TRACKING_PAGE_EVENT_MAP['assessment-d']);
          return <AssessmentIBS onNext={increasePage} />;
        }
        break;
      }

      case 5:
        return <SexAndGender onNext={increasePage} />;

      case 6:
        return <Identify onNext={pregnancyException} />;

      case 7:
        return (
          <PregnancyInfo
            blockPregnancy={blockPregnancy}
            onNext={checkPregnancy}
            onBack={handleBackButton}
          />
        );

      case 8:
        return (
          <EatingDisorderInfo
            hasEatingDisorder={hasEatingDisorder}
            onNext={checkEatingDisorder}
            onBack={handleBackButton}
          />
        );

      case 9:
        return (
          <GISurgery
            hasHadGISurgery={hasHadGISurgery}
            onNext={checkGISurgery}
            onBack={handleBackButton}
          />
        );

      default:
        return (
          <EndOfFlow
            handleSubmit={handleSubmit}
            setParentPage={setPage}
            submitRef={submitRef}
            apiError={apiError}
          />
        );
    }
  };

  if (isLoading) {
    return (
      <>
        <HeaderNav page={page} formData={state} setParentPage={setPage} />
        <Progress value={(100 / formLength) * (page + 1)} mx="0" />
        <Center bg={'light.100'}>
          <Container style={styles.formWrapper}>
            <VStack space={3} className="form-container">
              <Box className="body">Loading...</Box>
            </VStack>
          </Container>
        </Center>
      </>
    );
  }

  // Return PageDisplay because we still want to track the page visits
  // as before but we only return that because we do not want any wrapper
  // around the content in this case, since we show the landing page.
  if (page === -2) return <>{PageDisplay()}</>;

  return (
    <View>
      <HeaderNav
        page={page}
        formData={state}
        setParentPage={setPage}
        pregnancyException={pregnancyException}
        changePage={handleBackButton}
      />
      <Progress rounded="0" value={(100 / formLength) * (page + 1)} mx="0" />
      <Box style={styles.backgroundTexture2Container}>
        <BackgroundTexture2SVG {...styles.backgroundTexture2} />
      </Box>

      <Center
        zIndex={2}
        h={'100%'}
        overflow={'hidden'}
        testID={'onboarding-form'}
      >
        <Box style={styles.backgroundTexture1Container}>
          <BackgroundTexture1SVG {...styles.backgroundTexture1} />
        </Box>
        <Container
          h={'100vh'}
          style={{
            ...styles.formWrapper,
            ...((page === -2 || page === 10) && { maxWidth: '100%' }),
          }}
        >
          <VStack space={2} className="form-container" maxW={'100%'}>
            <div h={'100%'} className="body">
              {PageDisplay()}
            </div>
          </VStack>
        </Container>
      </Center>
    </View>
  );
}

export default Form;
