import { Heading, Box, VStack } from "native-base";
import { FORM_ACTIONS } from "../../../../utils/formActions.js"
import CustomSliderLabels from "../../../Molecules/CustomSliderLabels.js";
import CustomSlider from "../../../Molecules/CustomSlider.js";

function ColitisViewSix({ formData, dispatch }) {
  const assessmentTrack = 'onboarding-uc-ibs'
  const assessmentObject = formData[assessmentTrack]
  const setPainDays = (data) => {
    dispatch({ type: FORM_ACTIONS.UPDATE_FORM_FIELD, fieldName: 'onboarding-uc-ibs-1', assessment: assessmentTrack, payload: data })
  }
  const setPainSeverity = (data) => {
    dispatch({ type: FORM_ACTIONS.UPDATE_FORM_FIELD, fieldName: 'onboarding-uc-ibs-2', assessment: assessmentTrack, payload: data })
  }
  const setBloatingSeverity = (data) => {
    dispatch({ type: FORM_ACTIONS.UPDATE_FORM_FIELD, fieldName: 'onboarding-uc-ibs-3', assessment: assessmentTrack, payload: data })
  }

  return (
    <VStack  w={{base: '100%'}} px={'1em'}>
      <VStack>
        <Heading fontSize={'3xl'} mb={4}>Tell us more about your symptoms. </Heading>        
      </VStack>

      <h3>Over the last 10 days, how many days did you experience abdominal pain?</h3>
      <Box mb={4} px={'14px'}>
        <CustomSlider value={assessmentObject['onboarding-uc-ibs-1']} setSliderValue={setPainDays} minValue={0} maxValue={10} />
        <CustomSliderLabels labels={['Days', 'Days', 'Days']}/>
      </Box>

      <h3>Over the last 10 days, how severe was your abdominal pain?</h3>
      <Box mb={4} px={'14px'}>
        <CustomSlider value={assessmentObject['onboarding-uc-ibs-2']} setSliderValue={setPainSeverity} minValue={0} maxValue={10} />
        <CustomSliderLabels labels={['No Pain', 'Somewhat Severe', 'Very Severe']}/>
      </Box>

      <h3>Over the last 10 days, how severe was your bloating/flatulence?</h3>
      <Box mb={4} px={'14px'}>
        <CustomSlider value={assessmentObject['onboarding-uc-ibs-3']} setSliderValue={setBloatingSeverity} minValue={0} maxValue={10} />
        <CustomSliderLabels labels={['None', 'Somewhat Severe', 'Very Severe']}/>
      </Box>
    </VStack>
  )
}

export default ColitisViewSix;