import React from 'react';
import { HStack, Image, Pressable } from 'native-base';
import { useNavigate, useSearchParams } from 'react-router-dom';

const backBttn = '../../chevron-left-white.png';

export const UserDashboardHeader = ({ prevRoute = '/' }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <HStack
      px={8}
      backgroundColor={'secondary.500'}
      height={'64px'}
      position={'relative'}
    >
      <Pressable
        variant={'closer'}
        onPress={() => {
          navigate(`/hc-dashboard?${searchParams}`);
        }}
        position={'absolute'}
      >
        <Image
          alt="back button"
          height={'32px'}
          source={backBttn}
          width={'32px'}
          zIndex="1"
          top={'16px'}
        />
      </Pressable>
    </HStack>
  );
};
