import { useEffect, useState } from 'react';

const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve(img);
    };

    img.onerror = img.onabort = () => {
      reject(src);
    };

    img.src = src;
  });
};

/**
 * Hook that make sure all the images passed as parameter are preloaded
 */
export const useImagePreloader = (imageList: string[]) => {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) return;

      const imagesPromiseList: Promise<any>[] = [];
      for (const i of imageList) imagesPromiseList.push(preloadImage(i));

      await Promise.all(imagesPromiseList);

      if (isCancelled) return;

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
};
