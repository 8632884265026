import { Flex, Box } from "native-base";

function CustomSliderMarkers({length, value}) {
  const windowWidth = window.innerWidth;
  const styles = {
    sliderMarkers: {
      position: 'absolute',
      zIndex: -1,
      width: 'calc(100% + 8px)',
      left: '-4px'
    },
    sliderNodes: {
      height: '12px',
      width: '12px',
      borderRadius: '50%',
      content: '',
    },
    sliderNodesActive: {
      height: '12px',
      width: '12px',
      borderRadius: '50%',
      content: '',
    },
    sliderNodesSmall: {
      height: '10px',
      width: '10px',
      borderRadius: '50%',
      content: '',
    },
    sliderNodesActiveSmall: {
      height: '10px',
      width: '10px',
      borderRadius: '50%',
      content: '',
    }
  }
  return (
    <Flex style={styles.sliderMarkers} direction="row" justify={'space-between'} w={'100%'}>
      {[...Array(length + 1)].map((el, i) => {
          return i < value ? (
            <Box key={i} bg={'secondary.600'} style={windowWidth > 412 ? styles.sliderNodes:styles.sliderNodesSmall}></Box>
          ) : (
            <Box key={i} bg={'secondary.50'} style={windowWidth > 412 ? styles.sliderNodesActive:  styles.sliderNodesActiveSmall}></Box>
          )
        }
      )}
    </Flex>
  )
}
export default CustomSliderMarkers;