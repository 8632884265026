import { useQuery } from '@tanstack/react-query';
import { useApi, useAuth } from '../../hooks';

export const useSuspectedFoods = (userId: string) => {
  const { api } = useApi();
  const { isAuthenticated, loading, userToken } = useAuth();

  const fetchFoodChallenges = async () => {
    const requestObj = {
      route: `/private/coach/users/${userId}/subphase/challenge-food-status`,
      token: userToken,
    };

    return await api(requestObj);
  };

  const { data, isLoading, error } = useQuery<
    {
      completed?: any[];
      not_completed?: any[];
      upcoming?: any[];
    },
    Error
  >(['hc-food-challenges', userToken, userId], fetchFoodChallenges, {
    enabled: isAuthenticated && !loading && !!userToken && !!userId,
    placeholderData: {},
    refetchOnWindowFocus: false,
  });

  return { foodChallenges: data ?? null, isLoading, error };
};
