export const onboarindUCOneAnswers = {
  '0-3': 'onboarding-uc-ans-1-03',
  '4-6': 'onboarding-uc-ans-1-46',
  '7-9': 'onboarding-uc-ans-1-79',
  '10+': 'onboarding-uc-ans-1-10-more'
}

export const onboarindUCTwoAnswers = {
  '0': 'onboarding-uc-ans-2-0',
  '1-3': 'onboarding-uc-ans-2-13',
  '4-6': 'onboarding-uc-ans-2-46',
}

export const onboarindUCThreeAnswers = {
  'No Urgency': 'onboarding-uc-ans-3-urgency',
  'Had to Hurry': 'onboarding-uc-ans-3-hurry',
  'Had to Go Immediately': 'onboarding-uc-ans-3-go',
  'Had Accident': 'onboarding-uc-ans-3-in',
}

export const onboarindUCFourAnswers = {
  'No': 'onboarding-uc-ans-4-none',
  'Trace Amounts': 'onboarding-uc-ans-4-trace',
  'Less than 50% of the time': 'onboarding-uc-ans-4-mod',
  'More than 50% of the time': 'onboarding-uc-ans-4-lot',
}

export const onboarindUCFiveAnswers = {
  '0': 'onboarding-uc-ans-5-very-well',
  '4': 'onboarding-uc-ans-5-par',
  '5': 'onboarding-uc-ans-5-poor',
  '6': 'onboarding-uc-ans-5-very-poor',
  '7': 'onboarding-uc-ans-5-terrible',
}