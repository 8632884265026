import { useCallback } from 'react';

declare global {
  interface Window {
    analytics: any;
  }
}

export const useSegment = () => {
  const identify = useCallback((userId, userData) => {
    if (userId) {
      window.analytics.identify(userId, userData);
      return;
    }
    window.analytics.identify(userData);
  }, []);

  const page = useCallback((page) => {
    window.analytics.page(page);
  }, []);

  const track = useCallback((eventName, eventData) => {
    window.analytics.track(eventName, eventData);
  }, []);

  return {
    identify,
    page,
    track,
  };
};
