import React from 'react';
import {
  Button,
  Heading,
  HStack,
  Link,
  Modal,
  Text,
  VStack,
} from 'native-base';

export const InfoModal = ({ onClose }) => {
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size={'lg'}
      h="100vh"
      position="fixed"
    >
      <Modal.Content maxH="600">
        <Modal.Header>
          <HStack alignItems={'center'} justifyContent={'center'}>
            <VStack p={3} alignItems={'center'}>
              <Heading fontFamily="heading" fontSize={'xl'}>
                How do I change this?
              </Heading>
            </VStack>
            <Modal.CloseButton top={0} right={0} />
          </HStack>
        </Modal.Header>

        <Modal.Body>
          <Text fontSize={'md'} fontWeight={'normal'}>
            Unfortunately, you can't yet change your condition mid-program.
            Please{' '}
            <Link
              href="mailto:support@ayblehealth.com?subject=I%20would%20like%20to%20change%20my%20condition&body=Hello%20support%20of%20Ayblehealth%2C%20%0D%0A%0D%0AI%20am%20reaching%20out%20because%20I%20would%20like%20to%20change%20my%20condition."
              _text={{ textTransform: 'lowercase' }}
            >
              contact us
            </Link>{' '}
            for additional questions.
          </Text>
          <Button w={'100%'} mt={5} onPress={onClose}>
            Got It!
          </Button>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
