import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi, useAuth } from '../../../hooks';

export const useUpdateSuspectedFoods = (userId: string) => {
  const queryClient = useQueryClient();
  const { api } = useApi();
  const { userToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async (foodStableStrings: string[]) => {
      const route = '/private/backstep/foods/finish/';

      const payload: any = {
        foods: foodStableStrings,
      };

      return await api({
        route,
        token: userToken,
        method: 'POST',
        payload,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Coach-Override': userId,
        },
      });
    },

    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['hc-suspected-foods'] }),
  });

  return { mutation };
};
