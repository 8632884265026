import { AddIcon, Flex, Text, TextArea, VStack } from 'native-base';
import { IconButton } from '../Form/IconButton';
import { MultilineAsyncSelect } from '../Form/MultilineAsyncSelect';
import React from 'react';

export const ListWithNotes = ({
  entries,
  errorMessage,
  isEditing,
  label,
  loadOptions,
  notes,
  onAddEntry,
  onEntriesChange,
  onNotesChange,
}) => {
  return (
    <>
      <Flex flexDir={'row'} alignItems={'center'} mt={5}>
        <Text fontSize={'md'} fontWeight={'normal'} textAlign="left">
          {label}
        </Text>

        {isEditing && (
          <IconButton
            onClick={() => {
              onAddEntry({ id: null, value: '' });
            }}
          >
            <AddIcon />
          </IconButton>
        )}
      </Flex>

      {!!errorMessage && (
        <Text mt={2} fontSize={'sm'} color={'error.600'}>
          {errorMessage}
        </Text>
      )}

      <VStack mt={3}>
        <MultilineAsyncSelect
          entries={entries}
          loadOptions={loadOptions}
          name={'sx'}
          onEntriesChange={onEntriesChange}
          readonly={!isEditing}
        />

        {isEditing ? (
          <TextArea
            fontSize={'md'}
            mt={3}
            marginBottom={0}
            type="text"
            placeholder={`${label} general notes...`}
            h={20}
            w={'100%'}
            maxW={'100%'}
            isReadOnly={!isEditing}
            value={notes}
            onChange={(e) => {
              onNotesChange(e.target.value);
            }}
          />
        ) : (
          <Text fontSize={'md'} mt={3}>
            {notes}
          </Text>
        )}
      </VStack>
    </>
  );
};
