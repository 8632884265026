export enum Phase {
  Zero = 'PhaseZero',
  One = 'PhaseOne',
  Two = 'PhaseTwo',
  Three = 'PhaseThree',
  Four = 'PhaseFour',
}

type PhaseSummary = {
  average_ayble_score?: number;
  days_logged?: number;
  end_date?: string;
  longest_streak?: number;
  lowest_ayble_score?: number;
  most_eaten_food?: string;
  phase_extended?: boolean;
  start_date?: string;
};

export type User = {
  id: string;
  affiliate?: string;
  auth_id: string;
  // When populated, it is the date the user canceled
  canceled?: string;
  coach_first_name?: string;
  coach_last_name?: string;
  provider_first_name?: string;
  provider_last_name?: string;
  email: string;
  phase_1_summary: PhaseSummary | null;
  phase_2_summary: PhaseSummary | null;
  phase_3_summary: PhaseSummary | null;
  phase_4_summary: PhaseSummary | null;
  phoneNumber?: string;
  birthdate: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  address_street?: string;
  address_city?: string;
  address_state?: string;
  address_zip?: string;
  current_phase: Phase;
  diagnosis: string;
  phase_start_date: string; // date format
  days_of_phase: number;
  days_of_current_streak: number;
  in_transition: boolean;
  sex: string;
  gender: string;
  healthie_id?: string;
  ethnicity: string;
  onboarded: boolean;
  gut_break: boolean;
  referrer?: string;
  rest_day: boolean;
  on_pause: boolean;
  diagnosis_secondary: string;
  diagnosis_ids: string[];
  allergens: string[];
  allergen_ids: number[];
  diet_restrictions: string[];
  dietary_restriction_ids: number[];
  selected_foods: string[];
  selected_food_ids: number[];
  other_diet_ids: number[];
  recommended_foods: string[];
  current_symptoms: string[];
  potential_triggers: string[];
  plan_name?: string;
  suspected_triggers: string[];
  verified_triggers: string[];
  sifter_favorites: string[];
  customer_id: string;
  subscription_id: string;
  iap_purchase_id: string;
  goals: string[];
  coach_id: string;
  intercom_id: string;
  created_at: string;
  modified_at: string;
  medications?: string;
  opt_in_marketing?: boolean;
  opt_in_news?: boolean;
  user_phase_progress?: number;
  last_gut_check?: string;
  last_mindgut_activity?: string;
  on_day_in_phase: number;
  user_type: string;
};
