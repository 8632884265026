import { useState, useEffect } from 'react';
import { VStack, Center, Button, HStack } from 'native-base';
import { useFormData } from '../../../utils/formReducer.js';
import CrohnsViewOne from './AssessmentRangeSliderForms/CrohnsViewOne.js';
import CrohnsViewSix from './AssessmentRangeSliderForms/CrohnsViewSix.js';
import CrohnsViewSeven from './AssessmentRangeSliderForms/CrohnsViewSeven.js';
import AssessmentWrapUp from './AssessmentWrapUp.js';

function AssessmentCrohns({ onNext }) {
  const { state: formData, dispatch } = useFormData();

  const [page, setPage] = useState(0);

  const PageDisplay = () => {
    if (page === 0) {
      return <CrohnsViewOne formData={formData} dispatch={dispatch} />;
    } else if (page === 1) {
      return <CrohnsViewSix formData={formData} dispatch={dispatch} />;
    } else if (page === 2) {
      return <CrohnsViewSeven formData={formData} dispatch={dispatch} />;
    } else if (page === 3) {
      return <AssessmentWrapUp onNext={onNext} />;
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop();
  }, [page]);

  return (
    <Center>
      <VStack space={1} className="personal-info-container">
        {PageDisplay()}
        <Center>
          <HStack mt={4}>
            {page === 0 && (
              <Button
                minWidth={'300px'}
                size={'lg'}
                onPress={() => {
                  setPage((currPage) => currPage + 1);
                }}
              >
                {'Next'}
              </Button>
            )}
            {page > 0 && page < 3 && (
              <>
                <Button
                  mr={2}
                  minWidth={'175px'}
                  size={'lg'}
                  onPress={() => {
                    setPage((currPage) => currPage - 1);
                  }}
                >
                  {'Previous'}
                </Button>
                <Button
                  minWidth={'175px'}
                  size={'lg'}
                  onPress={() => {
                    setPage((currPage) => currPage + 1);
                  }}
                >
                  {'Next'}
                </Button>
              </>
            )}
          </HStack>
        </Center>
      </VStack>
    </Center>
  );
}

export default AssessmentCrohns;
