import { useApi, useAuth } from '../../hooks';
import { useQuery } from '@tanstack/react-query';

type CoachUserNotes = {
  id: string;
  coach_id: string;
  user_id: string;
  created_at: string;
  sx_notes: string;
  dx_notes: string;
  general: string;
};

export const useCoachUserNotes = (userId: string) => {
  const { api } = useApi();
  const { isAuthenticated, loading, userToken } = useAuth();

  const fetchCoachUserNotes = async () => {
    const requestObj = {
      route: `/private/coach-user-notes/?user_uuid=${userId}`,
      token: userToken,
    };

    return await api(requestObj);
  };

  const { data, isLoading, isError } = useQuery<CoachUserNotes[], Error>(
    ['hc-user-notes', userToken, userId],
    () => fetchCoachUserNotes(),
    {
      enabled: isAuthenticated && !loading && !!userToken,
      refetchOnWindowFocus: false,
    }
  );

  // data is an array of user notes, returning the one at position 0 it ensures us to always get the latest one
  return { userNotes: (data && data[0]) ?? null, isLoading, isError };
};
