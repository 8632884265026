import React from 'react';
import {
  FormControl,
  Input,
  ITheme,
  Text,
  useTheme,
  VStack,
} from 'native-base';
import Select from 'react-select';
import styled from '@emotion/styled';
import { GENDERS } from '../../../constants/misc';

// by specs, we allow one of the first three values in the genders array
const genderOptions = GENDERS.slice(0, 3).map((g) => ({ label: g, value: g }));

const GradientBox = styled('div')<{ theme?: ITheme }>`
  background-image: linear-gradient(to bottom, #c7d2fe, #c9eae8);
  border: 1px solid ${(p) => p.theme.colors.muted['200']};
  border-radius: 6px;
`;

type Props = {
  dob: string;
  gender: string;
  onPlanHolderInfoChange: ({
    dob,
    gender,
  }: {
    dob: string;
    gender: string;
  }) => void;
};

export const PlanHolderInfo = ({
  dob,
  gender,
  onPlanHolderInfoChange,
}: Props) => {
  const {
    colors: { muted },
  } = useTheme();

  return (
    <GradientBox>
      <VStack borderColor={'muted.200'} padding={6}>
        <Text fontSize={'lg'} fontWeight={'bold'} mb={6} w={'100%'}>
          Plan Holder’s Information
        </Text>

        <FormControl.Label>Date of birth (YYYY-MM-DD)</FormControl.Label>
        <Input
          backgroundColor={muted['50']}
          maxWidth={'100%'}
          type="text"
          value={dob ?? ''}
          onChangeText={(value) => {
            onPlanHolderInfoChange({
              dob: value,
              gender,
            });
          }}
        />

        <FormControl.Label>Gender</FormControl.Label>
        <Select
          styles={{
            control: (base) => ({
              ...base,
              height: 48,
              backgroundColor: muted['50'],
              marginBottom: 20,
            }),
          }}
          placeholder={null}
          value={genderOptions.find((g) => g.value === gender)}
          menuPortalTarget={document.body}
          options={genderOptions}
          onChange={(selection) => {
            onPlanHolderInfoChange({
              dob,
              gender: selection?.value ?? genderOptions[0].value,
            });
          }}
        />
      </VStack>
    </GradientBox>
  );
};
