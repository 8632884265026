import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../hooks/useApi';
import { AffiliateLookup, AffiliateType } from '../../models/affiliate';

/**
 * Searches for an affiliate based on a string and returns a list of affiliate objects
 *
 * @param {string} [searchString] affiliate lookup string
 * @returns
 */
export const useAffiliateLookup = ({
  searchString,
}: {
  searchString: string;
}) => {
  const { api } = useApi();
  const [debouncedSearch, setDebouncedSearch] = useState(searchString);

  useEffect(() => {
    if (searchString !== debouncedSearch) {
      const timerId = setTimeout(() => setDebouncedSearch(searchString), 500);
      return () => clearTimeout(timerId);
    }
  }, [searchString, debouncedSearch]);

  const lookupAffiliate = async (
    searchString: string = ''
  ): Promise<[AffiliateLookup]> => {
    const type = encodeURI(AffiliateType.healthPlan);
    return await api({
      route: `/affiliates/?search=${debouncedSearch}&affiliate_type=${type}`,
    });
  };

  const {
    data: affiliates,
    isLoading,
    isInitialLoading,
    isError,
  } = useQuery<[AffiliateLookup], Error>(
    ['affiliateLookup', debouncedSearch],
    () => lookupAffiliate(debouncedSearch),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    affiliates,
    isLoading,
    isError,
    isInitialLoading,
  };
};
