import React from 'react';
import { Box } from 'native-base';
import { Animated, Pressable } from 'react-native';
import { AutoSizer } from 'react-virtualized';

export const TabsBar = ({
  // size after which tabs are displayed in column
  breakPointSize = 196,
  currentTabIndex,
  navigationState,
  onTabPress,
  position,
}) => {
  const inputRange = navigationState.routes.map((x, i) => i);

  return (
    <AutoSizer style={{ display: 'contents' }}>
      {({ width }) => (
        <Box flexDirection={width > breakPointSize ? 'row' : 'column'}>
          {navigationState.routes.map((route, i) => {
            const color = currentTabIndex === i ? 'secondary.300' : 'white';
            const bgColor =
              currentTabIndex === i ? 'muted.100' : 'secondary.500';

            position.interpolate({
              inputRange,
              outputRange: inputRange.map((inputIndex) =>
                inputIndex === i ? 1 : 0.5
              ),
            });

            return (
              <Box
                alignItems="center"
                backgroundColor={bgColor}
                cursor="pointer"
                borderTopRadius={'sm'}
                flex={1}
                key={i}
                p="3"
              >
                <Pressable
                  onPress={() => {
                    onTabPress(route, i);
                  }}
                  style={{ alignItems: 'center', width: '100%' }}
                >
                  <Animated.Text
                    style={{
                      color,
                    }}
                  >
                    {route.title}
                  </Animated.Text>
                </Pressable>
              </Box>
            );
          })}
        </Box>
      )}
    </AutoSizer>
  );
};
