import { Circle, G, Line, Rect, Text as SvgText } from 'react-native-svg';
import React, { useCallback } from 'react';
import { useTheme } from 'native-base';
import { Dimensions } from 'react-native';
import moment from 'moment-timezone';
import { countDecimals } from '../HealthCoachDashboard/utils';
export const Tooltip = ({
  chartWidth = 0,
  datum,
  date,
  positionX,
  size,
  x,
  y,
  ticks,
}) => {
  const {
    colors: { secondary },
  } = useTheme();

  const apx = useCallback(
    (size = 0) => {
      let width = Dimensions.get('window').width;
      // when the window width is more than the double
      // of the chart width we apply a proportion
      return width > chartWidth * 2
        ? (width / (chartWidth * 1.8)) * size
        : size * 2.5;
    },
    [chartWidth]
  );

  if (positionX < 0) {
    return null;
  }

  // if we have more than 1 decimal to show we round up the number
  const datumToShow =
    countDecimals(datum) > 1 ? Number.parseFloat(datum).toFixed(2) : datum;

  // doing that to avoid wrong conversions among timezones since
  // from the BE we always have date at 00:00 without timezone indication
  const localDate = moment.utc(date).hours(12).format();
  const normalizedData = moment
    .tz(localDate, moment.tz.guess())
    .format('YYYY-MM-DD');

  return (
    <G x={x(positionX)} key="tooltip">
      <G
        x={positionX > size / 2 ? apx(-50) : apx(10)}
        y={Math.min(y(datum) - 15, 120)} // 120 is the min y coordinate to avoid cutting the tooltip on the bottom
      >
        <Rect
          y={-apx(20) / 2}
          rx={apx(2)} // borderRadius
          ry={apx(2)} // borderRadius
          width={apx(40)}
          height={apx(40)}
          fill={secondary['500']}
        />

        {/* display the date in the tooltip */}
        <SvgText
          x={apx(40) / 2}
          fill="white"
          opacity={1}
          fontSize={apx(6)}
          textAnchor={'middle'}
        >
          {normalizedData}
        </SvgText>

        <SvgText
          x={apx(40) / 2}
          y={apx(20)}
          fontSize={apx(16)}
          fontWeight="bold"
          fill="white"
          textAnchor={'middle'}
        >
          {datumToShow}
        </SvgText>
      </G>

      <G x={x}>
        <Line
          y1={ticks[0]}
          y2={ticks[Number(ticks.length)]}
          stroke="black"
          strokeWidth={apx(4)}
          strokeDasharray={[6, 3]}
        />
        <Circle cy={y(datum)} r={apx(8 / 2)} fill={secondary['500']} />
        <Circle
          cy={y(datum)}
          r={apx(6 / 2)}
          stroke="#fff"
          strokeWidth={1}
          fill={secondary['500']}
        />
      </G>
    </G>
  );
};
