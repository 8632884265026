import { useCallback, useMemo } from 'react';
import { useApi, useAuth } from '../../hooks';
import { ApiRequestMethod } from '../../types';

export const useCoach = () => {
  const { api } = useApi();
  const { userToken, isAuthenticated, loading } = useAuth();

  const baseRequestObj = useMemo(
    () => ({
      route: null,
      method: 'GET' as ApiRequestMethod,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    }),
    [userToken]
  );

  const fetchUser = useCallback(
    async (id: string) => {
      try {
        const requestObj = {
          ...baseRequestObj,
          route: `/private/coach/users/${id}`,
        };

        return await api(requestObj);
      } catch (e) {
        console.error(e);
      }
    },
    [api, baseRequestObj]
  );

  return {
    fetchUser,
    isAuthenticated,
    loading,
  };
};
