import {
  VStack,
  Pressable,
  Text,
  Box,
  Flex,
  Heading,
  useBreakpointValue,
} from 'native-base';
import { FORM_ACTIONS } from '../../../../utils/formActions.js';
import CustomSliderLabels from '../../../Molecules/CustomSliderLabels.js';
import CustomSlider from '../../../Molecules/CustomSlider.js';
import {
  onboarindUCThreeAnswers,
  onboarindUCFourAnswers,
  onboarindUCFiveAnswers,
} from '../../../../data/onboardingDataMapping.js';

function ColitisViewTwo({ formData, dispatch }) {
  const assessmentTrack = 'onboarding-uc';
  const assessmentObject = formData[assessmentTrack];
  const urgencyArray = Object.keys(onboarindUCThreeAnswers);
  const bloodArray = Object.keys(onboarindUCFourAnswers);

  const flexDir = useBreakpointValue({
    base: 'column',
    md: 'row',
  });
  const flexBtns = useBreakpointValue({
    base: '50%',
    md: '100%',
  });
  const flexBtnsMar = useBreakpointValue({
    base: '0',
    md: '.5rem',
  });
  const styles = {
    formButtons: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
      flex: 1,
      textAlign: 'center',
      flexBasis: flexBtns,
      marginRight: flexBtnsMar,
      marginLeft: flexBtnsMar,
    },
    formRow: {
      justifyContent: 'space-between',
      flexDirection: flexDir,
    },
  };

  const urgencyResponse = (data) => {
    const newData = onboarindUCThreeAnswers[data];
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-uc-3',
      assessment: assessmentTrack,
      payload: newData,
    });
  };
  const bloodResponse = (data) => {
    const newData = onboarindUCFourAnswers[data];
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-uc-4',
      assessment: assessmentTrack,
      payload: newData,
    });
  };
  const setRangeValue = (data) => {
    const keys = Object.keys(onboarindUCFiveAnswers);
    let newKey = -1;

    for (let i = 0; i < keys.length; i++) {
      const current = keys[i];
      const nextKey = keys[i + 1];

      if (data >= parseInt(current) && (!nextKey || data < nextKey)) {
        newKey = current;
      }
    }
    const newData = onboarindUCFiveAnswers[newKey];
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-uc-5',
      assessment: assessmentTrack,
      payload: newData,
    });
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-uc-5-value',
      assessment: assessmentTrack,
      payload: data,
    });
  };

  return (
    <VStack w={{ base: '100%' }}>
      {/* View 2 */}
      <Heading fontSize={'3xl'} mb={'2'}>
        Tell us more about your symptoms.
      </Heading>

      <h3>In an average week, how urgent are your bowel movements?</h3>
      <Flex style={styles.formRow}>
        {urgencyArray.map((item, index) => {
          return (
            <Pressable
              style={styles.formButtons}
              key={index}
              onPress={() => urgencyResponse(item)}
            >
              {() => {
                return (
                  <Box
                    minH={'70'}
                    justifyContent={'center'}
                    bg={
                      assessmentObject['onboarding-uc-3'] ===
                      onboarindUCThreeAnswers[item]
                        ? 'secondary.500'
                        : 'light.50'
                    }
                    p="5"
                    borderWidth="1"
                    borderColor="muted.400"
                    borderRadius="lg"
                    style={{}}
                  >
                    <Text
                      color={
                        assessmentObject['onboarding-uc-3'] ===
                        onboarindUCThreeAnswers[item]
                          ? 'light.50'
                          : 'secondary.500'
                      }
                      fontWeight="medium"
                      fontSize="l"
                    >
                      {item}
                    </Text>
                  </Box>
                );
              }}
            </Pressable>
          );
        })}
      </Flex>
      <h3>In an average week, do you see any blood in your stool?</h3>
      <Flex style={styles.formRow}>
        {bloodArray.map((item, index) => {
          return (
            <Pressable
              style={styles.formButtons}
              key={index}
              onPress={() => bloodResponse(item)}
            >
              {() => {
                return (
                  <Box
                    minH={'70'}
                    justifyContent={'center'}
                    bg={
                      assessmentObject['onboarding-uc-4'] ===
                      onboarindUCFourAnswers[item]
                        ? 'secondary.500'
                        : 'light.50'
                    }
                    p="5"
                    borderWidth="1"
                    borderColor="muted.400"
                    borderRadius="lg"
                    style={{}}
                  >
                    <Text
                      color={
                        assessmentObject['onboarding-uc-4'] ===
                        onboarindUCFourAnswers[item]
                          ? 'light.50'
                          : 'secondary.500'
                      }
                      fontWeight="medium"
                      fontSize="l"
                    >
                      {item}
                    </Text>
                  </Box>
                );
              }}
            </Pressable>
          );
        })}
      </Flex>
      <h3>In an average week, how would you rate your general well-being?</h3>
      <Box mb={4} px={'14px'}>
        <CustomSlider
          value={assessmentObject['onboarding-uc-5-value']}
          setSliderValue={setRangeValue}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels labels={['Great', 'Very Poor', 'Terrible']} />
      </Box>
    </VStack>
  );
}

export default ColitisViewTwo;
