import {
  EditFoodListModal,
  InfoModal,
} from '../components/UserDashboard/Modals';
import { ModalType, useModalsContext } from './useModalsContext';

export const useModal = () => {
  const { state } = useModalsContext();
  const { modalProps, openedModal } = state;

  switch (openedModal) {
    case ModalType.EditFoodListModal:
      return <EditFoodListModal {...modalProps} />;

    case ModalType.DashboardInfoModal:
      return <InfoModal {...modalProps} />;

    default:
      return null;
  }
};
