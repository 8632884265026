/* eslint-disable react-hooks/exhaustive-deps */
// TODO: Better dependency pattern to avoid ^
import React, {
  ReactNode,
  createContext,
  useContext,
  useReducer,
  useEffect,
} from 'react';
import { User } from '../models/user';
import { useAuth } from './useAuth';
import { useFetchUserData } from './useFetchUserData';
import { useCoach } from '../api/healthCoach/useCoach';

type RawScore = {
  ayble_score?: number;
  cd_score?: number;
  created_at: string;
  ibs_score?: number;
  id: string;
  modified_at: string;
  score_date: string;
  stress_score?: number;
  uc_score?: number;
  user_id: string;
};

type State = {
  ayble_score: string[];
  current_subphase: any;
  loading: boolean;
  lowest_ayble_score: string;
  over_time_score: string;
  overview_score: string[];
  overview_stress_score: string[];
  stress_score: string[];
  today_score: string;
  user: User | null;
  all_scores_data: RawScore[];
};

type Action =
  | {
      type: 'initUserData';
      current_subphase: any;
      ayble_score: any[];
      lowest_ayble_score: string;
      over_time_score: string;
      overview_score: any[];
      overview_stress_score: string[];
      stress_score: string[];
      today_score: string;
      user: User;
      all_scores_data: any[];
    }
  | {
      type: 'startFetchingUser';
    };

type Dispatch = (action: Action) => void;

type ContextType = { state: State; dispatch: Dispatch } | undefined;
export const DashboardContext = createContext<ContextType>(undefined);

function actionsReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'startFetchingUser': {
      return {
        ...state,
        loading: true,
      };
    }

    case 'initUserData': {
      return {
        all_scores_data: action.all_scores_data,
        ayble_score: action.ayble_score,
        current_subphase: action.current_subphase,
        over_time_score: action.over_time_score,
        overview_score: action.overview_score,
        loading: false,
        lowest_ayble_score: action.lowest_ayble_score,
        overview_stress_score: action.overview_stress_score,
        stress_score: action.stress_score,
        today_score: action.today_score,
        user: action.user,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${(action as any).type}`);
    }
  }
}

type Props = {
  children: ReactNode;
  patientId?: string;
};

/**
 * This state provider handles both cases:
 * 1. state of a self user (regular user logged in the app)
 * 2. state of a specific given user when passing the patient id for the health coach dashboard.
 */
export function DashboardPageStateProvider({ children, patientId }: Props) {
  const initialState: State = {
    loading: true,
    ayble_score: [],
    current_subphase: null,
    lowest_ayble_score: '0',
    over_time_score: '0',
    overview_score: [],
    overview_stress_score: [],
    stress_score: [],
    today_score: '0',
    user: null,
    all_scores_data: [],
  };
  const { loading: isAuthenticating, user, userToken } = useAuth();
  const {
    getSelfAybleScores,
    getSelfAybleScoreSummary,
    getSelfCurrentSubPhase,
    getUserAybleScores,
    getUserAybleScoreSummary,
  } = useFetchUserData();

  const {
    fetchUser,
    loading: isCoachAuthenticating,
    isAuthenticated: isCoachAuthenticated,
  } = useCoach();

  const [state, dispatch] = useReducer(actionsReducer, initialState);
  const value = { state, dispatch };

  useEffect(() => {
    const fetchSelfData = async () => {
      const { lowest_ayble_score } = await getSelfAybleScoreSummary();
      const {
        ayble_score,
        over_time_score,
        overview_score,
        rawData,
        overview_stress_score,
        stress_score,
        today_score,
      } = await getSelfAybleScores();
      const { current_subphase } = await getSelfCurrentSubPhase();

      dispatch({
        all_scores_data: rawData,
        ayble_score,
        current_subphase,
        lowest_ayble_score,
        over_time_score,
        overview_score,
        overview_stress_score,
        stress_score,
        today_score,
        type: 'initUserData',
        user,
      });
    };

    const fetchUserData = async () => {
      dispatch({ type: 'startFetchingUser' });

      const fetchedPatient = await fetchUser(patientId as string);
      const { lowest_ayble_score } = await getUserAybleScoreSummary(patientId);
      const {
        ayble_score,
        over_time_score,
        overview_score,
        rawData,
        overview_stress_score,
        stress_score,
        today_score,
      } = await getUserAybleScores(patientId);

      dispatch({
        // add this once coach endpoint can return such info for any user
        all_scores_data: rawData,
        ayble_score,
        current_subphase: null,
        lowest_ayble_score,
        over_time_score,
        overview_score,
        overview_stress_score,
        stress_score,
        today_score,
        type: 'initUserData',
        user: {
          ...fetchedPatient,
          first_name: fetchedPatient.first_name || '--',
          last_name: fetchedPatient.last_name || '--',
        },
      });
    };

    const shouldFetchUser =
      !!patientId && !isCoachAuthenticating && isCoachAuthenticated;
    const shouldFetchSelf = !isAuthenticating && userToken;

    if (shouldFetchUser) {
      fetchUserData();
    } else if (shouldFetchSelf) {
      fetchSelfData();
    }
  }, [isAuthenticating, userToken, isCoachAuthenticating]);

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboardPageState() {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error(
      'useDashboardPageState must be used within an DashboardContextProvider'
    );
  }

  return context;
}
