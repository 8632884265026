import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Loader } from './Loader';
import { useAuth } from '../hooks';

export const ProtectedRoute = ({ redirectPath = '/', children }) => {
  const { loading, isAuthenticated } = useAuth();

  if (loading) return <Loader loading={loading} />;

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};
