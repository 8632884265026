import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi, useAuth } from '../../../hooks';

export const useUserRegisterPut = () => {
  const { api } = useApi();
  const queryClient = useQueryClient();
  const { userToken } = useAuth();

  const mutation = useMutation({
    mutationFn: (payload) => {
      return api({
        token: userToken,
        route: `/private/user/register`,
        method: 'PATCH',
        payload,
      });
    },

    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: ['user', userToken],
      });
    },
  });

  return {
    mutation,
  };
};

export default useUserRegisterPut;
