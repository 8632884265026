import { useApi } from '../../hooks';
import { useQuery } from '@tanstack/react-query';

export type CoachPublicInfo = {
  first_name: 'string';
  last_name: 'string';
  middle_name: 'string';
  title: 'string';
  bio: 'string';
  image_url: 'string';
  schedule_link: 'string';
};

export const useCoachPublicInfo = (coachId: string | undefined) => {
  const { api } = useApi();

  const fetchPublicInfo = async (): Promise<CoachPublicInfo> => {
    return api({
      route: `/coach/${coachId}/`,
    });
  };

  const { data, isLoading, isError } = useQuery<CoachPublicInfo, Error>(
    ['public-coach-info', coachId],
    fetchPublicInfo,
    { enabled: !!coachId, refetchOnWindowFocus: false }
  );

  return {
    isLoading,
    isError,
    data,
  };
};
