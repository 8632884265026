import React from 'react';
import { Flex, Heading, HStack, Link, Image, Text, VStack } from 'native-base';
import { getUserPhase } from './utils';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';

const phasesIcon = '../../phases.svg';
const forwardBttn = '../../chevron-right.png';

const phaseNames = [
  '- -',
  'Identification',
  'Elimination',
  'Reintroduction',
  'Application',
];

export const PhaseDisplay = ({ onPhaseDetailsClick }) => {
  const {
    state: {
      user: { current_phase, in_transition },
    },
  } = useDashboardPageState();
  const currentPhase = getUserPhase(current_phase);
  const showPhaseLink = currentPhase > 0;
  const currentPhaseDisplayText = in_transition
    ? `${currentPhase - 1} → ${currentPhase}`
    : currentPhase;

  const styles = {
    forwardBttn: {
      width: 24,
      height: 24,
    },
  };

  return (
    <VStack>
      <Flex w={'40px'} h={'56px'} flexDir={'column'}>
        <HStack>
          <Image
            size="sm"
            source={{
              uri: phasesIcon,
            }}
            alt="phases"
          />

          {in_transition && (
            <Flex justifyContent={'center'} ml={'8px'}>
              <Text
                borderColor={'primary.600'}
                borderRadius={'5px'}
                borderStyle={'dashed'}
                borderWidth={'1px'}
                color={'primary.600'}
                fontWeight={'semibold'}
                padding={1}
              >
                in transition
              </Text>
            </Flex>
          )}
        </HStack>
      </Flex>

      <Heading textAlign={'left'}>Phase {currentPhaseDisplayText}</Heading>

      {showPhaseLink && (
        <Link mt={1} onPress={() => onPhaseDetailsClick(currentPhase)}>
          <HStack alignItems={'center'} mt={1}>
            <Text color="muted.500" fontSize={'lg'} fontWeight={500}>
              {phaseNames[currentPhase]}
            </Text>
            <Image zIndex="1" source={forwardBttn} style={styles.forwardBttn} />
          </HStack>
        </Link>
      )}
    </VStack>
  );
};
