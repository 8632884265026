import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../hooks/useApi';
import { useSearchParams } from 'react-router-dom';

import { Affiliate } from '../../models/affiliate';

/**
 * Fetches and returns an affiliate, or undefined.
 * Uses utm_source query param or affiliateToken (id or utm_source)
 *
 * @param {string} [affiliateId] optional affiliate identifier - id or utm_source
 * @returns
 */
export const useAffiliate = ({
  affiliateId,
}: { affiliateId?: string } = {}) => {
  const { api } = useApi();
  const [searchParams] = useSearchParams();
  // can use utm_source or token to fetch affiliate - affiliateID takes precedence
  const affiliateIdentifier = useMemo(
    () => affiliateId || searchParams.get('utm_source') || undefined,
    [searchParams, affiliateId]
  );

  const getAffiliate = async (identifier: string = ''): Promise<Affiliate> => {
    return await api({
      route: `/affiliates/${identifier}/`,
    });
  };

  const {
    data: affiliate,
    isLoading,
    isInitialLoading,
    isError,
  } = useQuery<Affiliate, Error>(
    ['affiliate', affiliateIdentifier],
    () => getAffiliate(affiliateIdentifier),
    {
      enabled: !!affiliateIdentifier,
      refetchOnWindowFocus: false,
    }
  );

  return { affiliate, isLoading, isError, isInitialLoading };
};
