import { useState } from 'react';
import { ImageBackground } from 'react-native';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  VStack,
  Text,
  useBreakpointValue,
} from 'native-base';
import { useFormData } from '../../utils/formReducer.js';
import { useGoogleTagManager } from '../../hooks/useGoogleTagManager';
import SignUpInfo from './SignUpInfo';
import MailchimpForm from '../../utils/MailChimpForm';
import {
  TRACKING_ONBOARDING_COMPLETED,
  TRACKING_PURCHASE_LOCAL_STORAGE_KEY,
} from './constants';
import TOSInput from './TOSInput';

const decorativeBackground = '../../decorative_bg.png';

const uhc = '../../provider_logos/uhc.png';
const aetna = '../../provider_logos/aetna-logo.svg';
const bcbs = '../../provider_logos/bcbs.svg';
const cigna = '../../provider_logos/cigna.svg';
const marpai = '../../provider_logos/marpai.png';

const logos = [
  { source: uhc, alt: 'UHC' },
  { source: aetna, alt: 'Aetna' },
  {
    source: bcbs,

    alt: 'Blue Cross Blue Shield',
  },
  { source: cigna, alt: 'Cigna' },
  { source: marpai, alt: 'Marpai' },
];

const styles = {
  logo: {
    backgroundSize: 'contain',
    resizeMode: 'contain',
    width: '170px',
    height: '65px',
  },
};

// 18_last_step_page
const EndOfFlow = ({ setParentPage, submitRef, handleSubmit, apiError }) => {
  const { state: formData, dispatch } = useFormData();
  const [tosChecked, setTosChecked] = useState(false);

  const { trackEventInGoogleTagManager } = useGoogleTagManager();
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });
  const handleButton = () => {
    // set this flag to track the purchase, we will clear it once
    // we track it in the thank you page
    localStorage.setItem(TRACKING_PURCHASE_LOCAL_STORAGE_KEY, true);

    trackEventInGoogleTagManager(TRACKING_ONBOARDING_COMPLETED);

    handleSubmit();
  };

  const isFormValid = (formData) => {
    if (
      !formData.firstName ||
      !/[a-zA-Z]/g.test(formData.firstName) ||
      !formData.lastName ||
      !/[a-zA-Z]/g.test(formData.lastName) ||
      !formData.email ||
      formData.email.indexOf('@') < 0 ||
      !formData.state ||
      (!formData.selected_affiliate && formData.affiliate_name) ||
      !tosChecked
    ) {
      return false;
    }

    return true;
  };

  return (
    <Flex testID={'end-of-flow'} flexDirection={isMobile ? 'column' : 'row'}>
      <VStack flex={1} px={isMobile ? '15px' : '80px'}>
        <Heading
          fontSize={'3xl'}
          mb={4}
          textAlign={'left'}
          lineHeight={'35px'}
          maxW={isMobile ? '100%' : '70%'}
        >
          You’re almost there — let’s check if your insurance or employer covers
          Ayble Health.
        </Heading>

        <Text fontSize={'lg'}>
          Don’t worry! You can also pay for Ayble yourself if you prefer!
        </Text>

        {apiError && (
          <Box
            mt={4}
            maxW={isMobile ? '100%' : '70%'}
            borderColor={'red.500'}
            borderWidth={1}
            backgroundColor={'red.100'}
            p={3}
          >
            <Text fontWeight="bold" color={'red.500'}>
              {apiError}
            </Text>
          </Box>
        )}

        <SignUpInfo
          formData={formData}
          dispatch={dispatch}
          isMobile={isMobile}
        />

        <MailchimpForm
          setParentPage={setParentPage}
          metaData={formData}
          submitRef={submitRef}
        />

        <Box my={2} zIndex={-1}>
          <TOSInput
            tosChecked={tosChecked}
            handleTosChange={() => setTosChecked((prevState) => !prevState)}
          />
        </Box>

        <Button
          alignSelf={isMobile ? 'center' : 'flex-start'}
          isDisabled={!isFormValid(formData)}
          ml={2}
          mt={isMobile ? 5 : 10}
          mb={5}
          block
          maxW={'240px'}
          size={'md'}
          onPress={handleButton}
          zIndex={-1}
        >
          Next
        </Button>
      </VStack>

      <VStack
        flex={1}
        backgroundColor={'secondary.500'}
        justifyContent={'space-between'}
        height={'95vh'}
        alignSelf={isMobile ? 'default' : 'flex-start'}
        mt={isMobile ? 'auto' : -9}
        mx={-4}
        mb={'-90px'}
      >
        <Box
          backgroundColor={'white'}
          rounded={10}
          mt={10}
          p={'65px'}
          maxW={'90%'}
          alignSelf={'center'}
        >
          <Heading
            alignSelf={'center'}
            fontSize={'lg'}
            textTransform={'uppercase'}
            fontFamily={'body'}
            lineHeight={'26px'}
          >
            in-network with leading health {'\n'} insurance providers,
            including:
          </Heading>
          <Flex
            flexDir={isMobile ? 'column' : 'row'}
            alignContent={'center'}
            flexWrap={'wrap'}
            justifyContent={'center'}
            pt={6}
            gap={6}
          >
            {logos.map((item) => {
              return (
                <Box key={item.source}>
                  <Image
                    mt={5}
                    key={item.source}
                    style={styles.logo}
                    alt={item.alt}
                    source={{
                      uri: item.source,
                    }}
                  />
                </Box>
              );
            })}
          </Flex>
        </Box>

        <ImageBackground
          style={{ height: '300px', justifyContent: 'flex-end' }}
          source={{ uri: decorativeBackground }}
          resizeMode="cover"
        />
      </VStack>
    </Flex>
  );
};

export default EndOfFlow;
