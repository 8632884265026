import { HStack, VStack } from 'native-base';
import { FoodChallenges } from './food/FoodChallenges';
import { SuspectedFoods } from './food/SuspectedFoods';
import React from 'react';
import { useSuspectedFoods } from '../../api/healthCoach/useSuspectedFoods';
import { useParams } from 'react-router-dom';
import { getUserPhase } from './utils';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';
import { RecommendedFoods } from './food/RecommendedFoods';

export const PhaseFoods = () => {
  const {
    state: { user },
  } = useDashboardPageState();
  const { patient } = useParams();
  const userPhase = getUserPhase(user?.current_phase);
  // check on the id because when switching user with the drawer open, the url (patient param)
  // changes right away while the user needs to be fetched first, so we do this to avoid inconsistencies
  const isChangingUser = patient !== user?.id;
  const { error, isLoading, foods } = useSuspectedFoods(
    patient ?? '',
    !isChangingUser && userPhase === 2
  );
  const foodListNotAvailable = error?.message?.indexOf('has no backstep') >= 0;
  const showRecommendedFoods = userPhase <= 1;
  const showPhaseFoods =
    userPhase > 2 || (userPhase === 2 && foodListNotAvailable);
  const showSuspectedFoods =
    !isChangingUser && !foodListNotAvailable && userPhase === 2;

  return (
    <HStack mr={'-24px'}>
      {showRecommendedFoods && (
        <VStack flex={1}>
          <RecommendedFoods />
        </VStack>
      )}

      {showPhaseFoods && (
        <VStack flex={1} pl={{ md: 0 }}>
          <FoodChallenges />
        </VStack>
      )}

      {showSuspectedFoods && (
        <VStack flex={1} pl={0}>
          <SuspectedFoods
            error={error}
            isLoading={isLoading}
            suspectedFoods={foods ?? []}
          />
        </VStack>
      )}
    </HStack>
  );
};
