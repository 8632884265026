import { useMutation } from '@tanstack/react-query';
import { useApi, useAuth } from '../../../hooks';
import { User } from '../../../models/user';

export const useRegisterPost = () => {
  const { api } = useApi();

  const { userToken } = useAuth();

  const nonce = Math.floor(Math.random() * 1000 * (Math.random() * 1000));

  const mutation = useMutation({
    mutationFn: (payload: Partial<User>) => {
      return api({
        token: userToken,
        route: `/register/${nonce}`,
        method: 'POST',
        payload,
      });
    },
  });

  return {
    mutation,
  };
};

export default useRegisterPost;
