import { useRef } from 'react';
import axios from 'axios';
import { ApiRequest } from '../types';

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const {
  // REACT_APP_API_BASE_URL = 'https://api.staging.ayblehealth.com'
  REACT_APP_API_BASE_URL = 'http://localhost:8000',
} = process.env;

type Options = {
  throwIfError: boolean;
};

export const useApi = ({ throwIfError }: Options = { throwIfError: false }) => {
  const controllerRef = useRef(new AbortController());

  const cancel = () => {
    controllerRef.current.abort();
  };

  const api = async <PayloadType = undefined>(
    request: ApiRequest<PayloadType>
  ) => {
    const requestHeaders = request.headers ?? DEFAULT_HEADERS;
    const headers = request.token
      ? { ...requestHeaders, Authorization: `Bearer ${request.token.trim()}` }
      : requestHeaders;

    try {
      const requestData = {
        data: request.payload,
        headers,
        method: request.method || 'GET',
        signal: controllerRef.current.signal,
        url:
          request.url ||
          [REACT_APP_API_BASE_URL, request.route.replace(/^\/+/, '')].join('/'),
      };

      const response = await axios.request(requestData);
      return response.data;
    } catch (error: any) {
      console.error(error?.message || 'Unknown error');

      if (throwIfError) {
        throw new Error(
          error?.response?.data?.detail ?? 'Error with unknown details.'
        );
      }
    }

    return null;
  };

  return { api, cancel };
};
