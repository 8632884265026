import { useEffect, useState } from 'react';
import { Text } from 'native-base';
import useLearningHubContent from '../../api/contentPreview/useLearningHubContent';
import { Loader } from '../Loader';
import PreviewScreen from './PreviewScreen';
import PreviewHeader from './PreviewHeader';
import { useAuth } from '../../hooks';
import { learningHubContentSections } from './constants';

const LearningHubContentPreview = ({
  activeSection,
}: {
  activeSection: number | null;
}) => {
  const { isAuthenticated, loading, userToken } = useAuth();
  const [screenIdx, setScreenIdx] = useState(0);
  const selectedSection =
    activeSection !== null ? learningHubContentSections[activeSection] : null;

  const enableFetching = !loading && isAuthenticated;
  const { isError, isLoading, data } = useLearningHubContent({
    enable: enableFetching,
    userToken,
    ...selectedSection,
  });

  const displayLoadingSpinner = !!selectedSection && (isLoading || loading);

  useEffect(() => {
    if (data?.screens) {
      setScreenIdx(0);
    }
  }, [data?.screens]);

  const prevScreen = () => {
    if (screenIdx > 0) setScreenIdx(screenIdx - 1);
  };

  const nextScreen = () => {
    if (data?.screens && screenIdx < data?.screens?.length - 1) {
      setScreenIdx(screenIdx + 1);
    } else {
      setScreenIdx(0);
    }
  };

  const activeScreen = data?.screens ? data.screens[screenIdx] : null;

  return (
    <>
      {displayLoadingSpinner ? (
        <Loader loading={isLoading} />
      ) : isError ? (
        <Text textAlign={'center'}>{'Error Fetching Data :('}</Text>
      ) : activeScreen ? (
        <>
          <PreviewHeader
            activeScreenIdx={screenIdx}
            numScreens={data?.screens?.length}
            prevScreen={prevScreen}
            nextScreen={nextScreen}
            title={data?.title}
            {...selectedSection}
          />
          <PreviewScreen
            screen={activeScreen}
            onCtaClick={nextScreen}
            sectionTitle={data?.title}
          />
        </>
      ) : (
        <Text textAlign={'center'}>{'No section data :('}</Text>
      )}
    </>
  );
};

export default LearningHubContentPreview;
