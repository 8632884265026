import { Image, Pressable, Text } from 'native-base';

export const CardInputButton = ({ text, image, onPress, isActive }) => {
  return (
    <Pressable
      //   w={'48%'} Add this back once for the uploader
      py={'10px'}
      px={'20px'}
      borderWidth={1}
      borderRadius={'8px'}
      borderColor={'muted.400'}
      textAlign={'center'}
      onPress={onPress}
      backgroundColor={isActive ? 'tertiary.500' : 'white'}
      shadow={'1'}
      _pressed={{ backgroundColor: 'tertiary.500' }}
      _hover={{ backgroundColor: isActive ? 'tertiary.500' : 'muted.200' }}
    >
      <Image
        alt="card input type"
        source={{
          uri: image,
        }}
        style={{
          resizeMode: 'contain',
          position: 'relative',
          width: '75px',
          height: '75px',
          alignSelf: 'center',
        }}
      />
      <Text color={'black'} fontWeight={'500'} fontSize={'16px'}>
        {text}
      </Text>
    </Pressable>
  );
};

export default CardInputButton;
