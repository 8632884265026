import { FormControl, Box } from 'native-base';
import Select from 'react-select';
import { FORM_ACTIONS } from '../../utils/formActions.js';
import { locationOptions } from '../../data/locationOptions.js';

function SignUpInfoLocation({ dispatch, defaultValue, isMobile }) {
  return (
    <Box minWidth="200" width="100%">
      <FormControl
        isInvalid={false}
        isRequired
        testID={'signup-location-select'}
      >
        <FormControl.Label>Your primary state of residence</FormControl.Label>

        <Select
          styles={{
            menuPortal: (base) => ({ ...base, maxWidth: 340, zIndex: 9999 }),
            control: (base) => ({
              ...base,
              height: 48,
              maxWidth: isMobile ? '100%' : '70%',
              backgroundColor: 'transparent',
            }),
          }}
          defaultValue={defaultValue}
          className={'sign-up-info-location'}
          menuPortalTarget={document.body}
          options={locationOptions}
          placeholder=""
          accessibilityLabel="Choose Location"
          onChange={(locationValue) => {
            dispatch({
              type: FORM_ACTIONS.UPDATE_FORM_FIELD,
              fieldName: 'state',
              payload: locationValue.value,
            });
          }}
        ></Select>
      </FormControl>
    </Box>
  );
}

export default SignUpInfoLocation;
