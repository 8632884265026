import React from 'react';
import { HStack, Heading, Spinner } from 'native-base';

export const LoaderDefaultProps = {
  size: 'sm',
  label: 'Loading',
  color: 'cyan.500',
  headingColor: 'cyan.500',
  fontSize: 'sm',
  loading: false,
  p: 5
};

export const Loader = (props) => {
  const merged = { ...LoaderDefaultProps, ...props };

  return (
    <HStack space={2} p={merged.p} my={props.my} justifyContent="center">
      <Spinner accessibilityLabel={merged.label} size={merged.size} color={merged.color} />
      {!props.noText && <Heading color={merged.headingColor} fontSize={merged.fontSize}>
        {merged.label}
      </Heading>}
    </HStack>
  );
};
