import { extendTheme } from 'native-base';

export const theme = extendTheme({
  colors: {
    // Add new color
    primary: {
      50: '#DEF2F2',
      100: '#CEF2F1',
      200: '#B7E6E4',
      300: '#ADE0DC',
      400: '#74CCC6',
      500: '#72CCC6',
      600: '#41B4AC',
      700: '#0E7490',
      800: '#2B7873',
      900: '#1B4B48',
    },
    secondary: {
      50: '#74A6AD',
      100: '#3D7F87',
      200: '#2D6670',
      300: '#245B62',
      400: '#17484E',
      500: '#043338',
      600: '#032326',
      700: '#0A1F22',
      800: '#020B0D',
      900: '#010303',
    },
    muted: {
      50: '#FDFEFA',
      100: '#F7F5ED',
      200: '#E0E1E1',
      400: '#ABAFB1',
      500: '#505455',
      600: '#505455',
    },
    light: {
      50: '#FDFEFA',
      100: '#F7F5ED',
    },
    tertiary: {
      500: '#F7D432',
    },
    blue: {
      100: '#D2E3FF',
    },
    orange: {
      400: '#E46E4F',
      500: '#F8623F',
    },
    coolGray: {
      100: '#E5E7EB',
    },
    trueGray: {
      50: '#FAFAFA',
    },
    red: {
      100: '#FEE2E2',
      500: '#CD3A3A',
    },
  },
  fontConfig: {
    Recoleta: {
      100: {
        normal: 'Recoleta-Thin',
        italic: 'RecoletaAlt-Thin',
      },
      300: {
        normal: 'Recoleta-Light',
        italic: 'RecoletaAlt-Light',
      },
      400: {
        normal: 'Recoleta-Regular',
        italic: 'RecoletaAlt-Regular',
      },
      500: {
        normal: 'Recoleta-Medium',
        italic: 'RecoletaAlt-Medium',
      },
      600: {
        normal: 'Recoleta-SemiBold',
        italic: 'RecoletaAlt-SemiBold',
      },
      700: {
        normal: 'Recoleta-Bold',
        italic: 'RecoletaAlt-Bold',
      },
      900: {
        normal: 'Recoleta-Black',
        italic: 'RecoletaAlt-Black',
      },
    },
    CircularXX: {
      100: {
        normal: 'CircularXX-Thin',
        italic: 'CircularXX-ThinItalic',
      },
      300: {
        normal: 'CircularXX-Light',
        italic: 'CircularXX-LightItalic',
      },
      400: {
        normal: 'CircularXX',
        italic: 'CircularXX-Italic',
      },
      500: {
        normal: 'CircularXX-Book',
        italic: 'CircularXX-BookItalic',
      },
      600: {
        normal: 'CircularXX-Medium',
        italic: 'CircularXX-MediumItalic',
      },
      700: {
        normal: 'CircularXX-Bold',
        italic: 'CircularXX-BoldItalic',
      },
      800: {
        normal: 'CircularXX-Black',
        italic: 'CircularXX-BlackItalic',
      },
      900: {
        normal: 'CircularXX-ExtraBlack',
        italic: 'CircularXX-ExtraBlackItalic',
      },
    },
  },

  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: 'Recoleta',
    body: 'CircularXX',
    mono: 'CircularXX',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
    extraBlack: 950,
  },
  fontSizes: {
    '2xs': 10,
    xs: 12,
    sm: 14,
    md: 15,
    lg: 18,
    xl: 20,
    '2xl': 24,
    '3xl': 30,
    '4xl': 36,
    '5xl': 48,
    '6xl': 60,
    '7xl': 72,
    '8xl': 96,
    '9xl': 128,
  },
  lineHeights: {
    '2xs': '1em',
    xs: '1.125em',
    sm: '1.25em',
    md: '1.275em',
    lg: '1.25em',
    xl: '1.1em',
    '2xl': '1em',
    '3xl': '2em',
    '4xl': '3em',
    '5xl': '4em',
  },
  config: {
    initialColorMode: 'light',
  },
  components: {
    Heading: {
      baseStyle: {
        textAlign: 'center', //todo check this
        margin: '0 auto',
        fontWeight: '500',
        fontFamily: 'Recoleta',
        lineHeight: '1.1em',
      },
    },
    Text: {
      baseStyle: {
        fontWeight: '300',
        fontFamily: 'CircularXX',
        color: 'muted.600',
        lineHeight: '1.2em',
      },
    },
    ModalCloseButton: {
      defaultProps: {
        _icon: {
          size: '7',
          color: 'secondary.100',
        },
      },
    },
    Button: {
      baseStyle: {
        width: 'auto',
        borderRadius: '30px',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '30px', // web
        paddingRight: '30px', // web
        minHeight: '48px', //check this
      },
      defaultProps: {
        size: 'md',
        variant: 'solid',
        _light: {
          //needs to be removed if we moved version of NB forward
          fontSize: 'md',
          _pressed: {
            defaultStyles: {
              opacity: '20',
            },
            backgroundColor: 'secondary.500', //will override variant pressed state regardless of definition
            _text: {
              color: 'light.100',
            },
          },
          _disabled: {
            opacity: '40',
            _text: {
              color: 'light.100',
            },
          },
        },
      },
      variants: {
        fullWidth: {
          width: '90%',
        },
        outline: {
          //schema is correct
          _light: {
            bg: 'light.100',
            borderWidth: '1px',
            borderColor: 'secondary.600',
            _text: {
              fontWeight: '700',
              textTransform: 'uppercase',
              color: 'secondary.600',
            },
            _focus: {
              bg: 'secondary.500',
            },
            _hover: {
              bg: 'primary.200',
            },
            _pressed: {
              backgroundColor: 'secondary.500',
              borderColor: 'secondary.500',
              _text: {
                _light: {
                  color: 'light.100',
                },
              },
            },
            _disabled: {
              opacity: '40',
            },
          },
        },
        solid: {
          _light: {
            //needs to be removed if we moved version of NB forward
            color: 'primary.500',
            fontSize: 'md',
            bg: 'primary.500',
            _text: {
              fontWeight: '700',
              textTransform: 'uppercase',
              color: 'secondary.600',
            },
            _hover: {
              bg: 'primary.600',
            },
            _pressed: {
              defaultStyles: {
                opacity: '20',
              },
              backgroundColor: 'secondary.500', //will override variant pressed state regardless of definition
              _text: {
                color: 'light.100',
              },
            },
            _disabled: {
              opacity: '40',
              _text: {
                color: 'light.100',
              },
            },
          },
        },
        link: {
          _light: {
            backgroundColor: 'transparent',
            _text: {
              color: 'secondary.200',
              fontWeight: '700',
              textTransform: 'uppercase',
            },
            _hover: {
              _text: {
                color: 'secondary.200',
              },
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        _text: {
          fontWeight: '700',
          textTransform: 'uppercase',
          color: 'secondary.200',
        },
        _pressed: {
          color: 'secondary.600',
        },
        _hover: {
          color: 'secondary.600',
        },
      },
    },
    Input: {
      baseStyle: {
        height: '48px',
        width: '100%',
        maxWidth: '340px',
        color: 'secondary.600',
        borderColor: 'muted.400',
        bg: 'light.100',
        marginBottom: '4',
      },
      defaultProps: {
        size: 'md',
        fontFamily: 'CircularXX-Book',
        focusOutlineColor: 'secondary.900',
      },
    },
    Pressable: {
      baseStyle: {
        borderRadius: 0,
      },
    },
    Slider: {
      baseStyle: {
        borderRadius: 0,
        zIndex: 1,
        maxWidth: '100%',
        borderWidth: 0,
      },
    },
    SliderThumb: {
      baseStyle: {
        _pressed: {
          _interactionBox: {
            display: 'none',
          },
        },
      },
    },
    Progress: {
      baseStyle: {
        width: 'calc(100% + 5px)',
        margin: '0',
        borderRadius: '0',
        marginLeft: '-5px',
        backgroundColor: '#CEF2F1',
        rounded: '0',
      },
      defaultProps: {
        _filledTrack: {
          bg: 'secondary.50',
          rounded: '0',
        },
      },
    },
    Select: {
      defaultProps: {
        _customDropdownIconProps: {
          size: 4,
        },
      },
    },
  },
});
