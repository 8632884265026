import styled from '@emotion/styled';
import { Heading, Image, Text, VStack } from 'native-base';
import { HtmlRenderer } from '../OnboardingForm/HtmlRenderer';
import {
  BODY_2_MOBILE_SIZE_PX,
  BODY_2_SIZE_PX,
  HEADER_3_MOBILE_SIZE_PX,
  HEADER_3_SIZE_PX,
} from './constants';

const ImgContainer = styled('div')`
  & > div > div {
    background-position: left;
  }
`;

export const DropheadItem = ({
  html,
  imgUrl,
  title,
}: {
  html: string;
  imgUrl: string;
  title: string;
}) => {
  return (
    <VStack
      flex={1}
      px={{ base: 8, lg: 24 }}
      py={[4, 0]}
      pb={[12, 12, 0]}
      pt={0}
    >
      <ImgContainer>
        <Image
          resizeMode={'contain'}
          size={'sm'}
          source={{
            uri: imgUrl,
          }}
          alt={title}
        />
      </ImgContainer>

      <Heading
        color={'secondary.600'}
        fontWeight={'normal'}
        fontSize={{ base: HEADER_3_MOBILE_SIZE_PX, sm: HEADER_3_SIZE_PX }}
        letterSpacing={'0px'}
        minHeight={[0, 0, 128]}
        pt={6}
        textAlign="left"
      >
        {title}
      </Heading>

      <Text
        pt={{ base: 4, sm: undefined }}
        color={'secondary.600'}
        fontSize={{ base: BODY_2_MOBILE_SIZE_PX, sm: BODY_2_SIZE_PX }}
        fontWeight={'normal'}
      >
        <HtmlRenderer html={html} />
      </Text>
    </VStack>
  );
};
