import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  InfoIcon,
  Text,
  View,
  VStack,
} from 'native-base';
import { FoodCard } from '../../ui/FoodCard';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../Loader';
import { getImage } from './util';
import { useRecommendedFoods } from '../../../api/healthCoach/useRecommendedFoods';
import { useUpdateUser } from '../../../api/healthCoach/mutations/useUpdateUser';
import { IconButton } from '../../Form/IconButton';
import { PencilIcon } from '../../../icons/PencilIcon';

export const RecommendedFoods = () => {
  const { patient } = useParams();
  const { mutation } = useUpdateUser(patient ?? '');
  const { error, foods, isLoading } = useRecommendedFoods(patient);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFoods, setSelectedFoods] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const canAddFood = selectedFoods.length < 21;

  const foodsCount = foods?.length;
  const subTitle = `${foods?.length} food${
    foodsCount !== undefined && foodsCount > 1 ? 's' : ''
  }`;

  const handleFoodSelection = (foodId: string, checked: boolean) => {
    if (checked && canAddFood) {
      return setSelectedFoods([...selectedFoods, foodId]);
    }

    if (checked && !canAddFood) {
      setErrorMsg('You can only select 21 foods.');
      return;
    }

    const unCheckedFoodIndex = selectedFoods.indexOf(foodId);
    return setSelectedFoods([
      ...selectedFoods.slice(0, unCheckedFoodIndex),
      ...selectedFoods.slice(unCheckedFoodIndex + 1),
    ]);
  };

  useEffect(() => {
    if (!isLoading && !!error) {
      setErrorMsg(
        'Error while loading the recommended foods. Please try again later ot contact support.'
      );
    }
  }, [error, isLoading]);

  useEffect(() => {
    // reset error message
    if (!error && !!errorMsg && selectedFoods.length < 21) setErrorMsg('');
  }, [errorMsg, error, selectedFoods.length]);

  if (isLoading) {
    return (
      <Box backgroundColor="muted.100" p={0}>
        <Loader loading={true} />
      </Box>
    );
  }

  return (
    <VStack>
      <HStack alignItems={'center'}>
        <Heading fontWeight={'normal'} size={'md'} textAlign="left">
          Recommended Foods
        </Heading>

        {!isEditing && (
          <IconButton
            onClick={() => {
              setIsEditing(() => !isEditing);
            }}
          >
            <PencilIcon />
          </IconButton>
        )}

        {isEditing && (
          <HStack h={'24px'} alignItems={'center'}>
            <Button
              isDisabled={mutation.isLoading}
              h={'20px'}
              minH={'16px'}
              ml={2}
              onPress={() => {
                setIsEditing(false);
                setSelectedFoods([]);
              }}
              rounded={3}
              size="xs"
              variant={'outline'}
              w={'16px'}
            >
              Cancel
            </Button>

            <Button
              isDisabled={mutation.isLoading || selectedFoods.length !== 21}
              h={'20px'}
              isLoading={mutation.isLoading}
              minH={'16px'}
              ml={2}
              onPress={async () => {
                await mutation.mutateAsync({
                  recommended_foods: selectedFoods,
                });
                setIsEditing(false);
                setSelectedFoods([]);
              }}
              rounded={3}
              size="xs"
              w={'16px'}
            >
              Save
            </Button>
          </HStack>
        )}
      </HStack>

      <Text mb={1}>{subTitle}</Text>

      <Box h={'24px'}>
        {isEditing && !errorMsg && canAddFood && (
          <HStack alignItems={'center'}>
            <InfoIcon color={'info.600'} mr={1} />
            <Text>Select 21 foods to save</Text>
            {!!selectedFoods.length && (
              <Text>
                : <b>{selectedFoods.length}</b> out of <b>21</b> selected
              </Text>
            )}
          </HStack>
        )}

        {errorMsg && <Text color={'red.500'}>{errorMsg}</Text>}
      </Box>

      <View>
        <Flex flexDir={'row'} overflow={'scroll'} pb={4}>
          {foods?.map(({ id, image_url, is_recommended_user_food, name }) => (
            <FoodCard
              active={is_recommended_user_food}
              disableSeverityLabel
              editing={isEditing}
              foodId={name}
              foodLabel={name}
              imgURL={getImage(image_url)}
              key={id}
              onChange={handleFoodSelection}
              selected={selectedFoods.includes(name)}
            />
          ))}
        </Flex>
      </View>
    </VStack>
  );
};
