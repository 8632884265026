import { VStack, Heading, Box } from 'native-base';
import { FORM_ACTIONS } from '../../../../utils/formActions.js';
import CustomSliderLabels from '../../../Molecules/CustomSliderLabels.js';
import CustomSlider from '../../../Molecules/CustomSlider.js';

function IBSViewOne({ formData, dispatch }) {
  const assessmentTrack = 'onboarding-ibs-all';

  const setPainDays = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-ibs-all-1',
      assessment: assessmentTrack,
      payload: data,
    });
  };
  const setPainSeverity = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-ibs-all-2',
      assessment: assessmentTrack,
      payload: data,
    });
  };
  const setBloatingSeverity = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-ibs-all-3',
      assessment: assessmentTrack,
      payload: data,
    });
  };

  const assessmentObject = formData[assessmentTrack];

  return (
    <VStack w={{ base: '100%' }} testID={'onboarding-ibs-view-1'}>
      <VStack>
        <Heading fontSize={'3xl'} mb={'2'}>
          Tell us more about your symptoms.
        </Heading>
      </VStack>

      <h3>Over the last 10 days, how many days did you experience abdominal pain?</h3>
      <Box mb={4} px={'14px'} testID={'onboarding-ibs-view-1-slider-1'}>
        <CustomSlider
          value={assessmentObject['onboarding-ibs-all-1']}
          setSliderValue={setPainDays}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels labels={['Days', 'Days', 'Days']} />
      </Box>

      <h3>Over the last 10 days, how severe was your abdominal pain?</h3>
      <Box mb={4} px={'14px'} testID={'onboarding-ibs-view-1-slider-2'}>
        <CustomSlider
          value={assessmentObject['onboarding-ibs-all-2']}
          setSliderValue={setPainSeverity}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels
          labels={['No Pain', 'Somewhat Severe', 'Very Severe']}
        />
      </Box>

      <h3>Over the last 10 days, how severe was your bloating / flatulence?</h3>
      <Box mb={4} px={'14px'} testID={'onboarding-ibs-view-1-slider-3'}>
        <CustomSlider
          value={assessmentObject['onboarding-ibs-all-3']}
          setSliderValue={setBloatingSeverity}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels
          labels={['No Pain', 'Somewhat Severe', 'Very Severe']}
        />
      </Box>
    </VStack>
  );
}

export default IBSViewOne;
