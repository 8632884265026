import { Circle } from 'react-native-svg';
import React from 'react';

export const Dots = (props) => {
  // inside data we have two series coming from ScoreChart definition
  // for each one we need to draw the dots accordingly
  const { x, y, data } = props;

  return (
    <>
      {data.map((series) =>
        series.data.map((value, index) => {
          if (value === null || value === undefined) return null;

          return (
            <Circle
              key={index}
              cx={x(index)}
              cy={y(value)}
              r={series.svg.strokeWidth}
              stroke={series.svg.stroke}
              fill={series.svg.stroke}
            />
          );
        })
      )}
    </>
  );
};
