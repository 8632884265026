import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  VStack,
} from 'native-base';
import { PhaseDisplay } from './PhaseDisplay';
import { TimeCount } from './TimeCount';
import { ProgramFeed } from './ProgramFeed';

const stomachImg = '../../stomach.png';

export const NutritionProgram = ({
  onPhaseDetailsClick,
  onPhaseLinkClick,
}: {
  onPhaseDetailsClick: () => void;
  onPhaseLinkClick: () => void;
}) => {
  return (
    <VStack>
      <HStack alignItems={'center'} justifyContent={'flex-start'}>
        <Image
          mr={2}
          style={{
            resizeMode: 'contain',
            position: 'relative',
            width: '32px',
            height: '32px',
          }}
          source={{
            uri: stomachImg,
          }}
          alt="logo image image"
        />

        <Heading size={'md'} fontWeight={'normal'} textAlign="left">
          Nutrition Program
        </Heading>
      </HStack>

      <HStack mx={6}>
        <Flex mb={6} direction="column" flex={1}>
          <Flex py={6} p={2} flex={1}>
            <PhaseDisplay onPhaseDetailsClick={onPhaseDetailsClick} />
          </Flex>
          <Flex py={6} p={2} flex={1}>
            <TimeCount />
          </Flex>
        </Flex>

        <Divider orientation={'vertical'} height={'100hv'} />

        <Box w={'55%'}>
          <ProgramFeed onPhaseLinkClick={onPhaseLinkClick} />
        </Box>
      </HStack>
    </VStack>
  );
};
