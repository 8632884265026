import { Text, Box } from 'native-base';

const PlansDisclaimer = () => (
  <>
    <Box w="100%" rounded="md" mt={4} mb={6}>
      <Text pb={2} textAlign={'center'} fontWeight={'medium'} fontSize={'xs'}>
        Disclaimer for using the Ayble Health app or Ayblehealth.com
      </Text>
      <Text italic fontSize="xs">
        Ayble does not provide professional medical services or advice. The
        services provided by Ayble’s platform (website and/or mobile app) do not
        contain or constitute, and should not be interpreted as, medical advice,
        opinion, treatment, or recommendations. No doctor-patient relationship
        is created. Ayble is not responsible for any actions or inactions of
        anyone concerning their health or medical conditions. If you rely on any
        content, you do so solely at your own risk. We recommend you seek a
        healthcare provider’s advice in addition to using the Ayble platform and
        before making any medical decisions. Use of the Ayble platform is not
        for medical emergencies. If you think you have a medical emergency,
        please call 911.
      </Text>
    </Box>
  </>
);

export default PlansDisclaimer;
