import { View } from 'native-base';
import Svg, { Path, G, Defs, Rect, ClipPath, Circle } from 'react-native-svg';
export const TimelapseSVG = ({ size = 32 }) => {
  return (
    <View>
      <Svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <G clipPath="url(#clip0_5721_63427)">
          <Circle
            cx="16"
            cy="18"
            r="11.25"
            stroke="#043338"
            strokeWidth="1.5"
          />
          <Path
            d="M16 10C17.43 10 18.8338 10.3833 20.0654 11.1099C21.2969 11.8366 22.3112 12.8801 23.0026 14.1318C23.6941 15.3835 24.0374 16.7976 23.9968 18.227C23.9562 19.6564 23.5332 21.0488 22.7719 22.2592C22.0106 23.4697 20.9387 24.454 19.6679 25.1096C18.3971 25.7652 16.9738 26.0682 15.5462 25.9871C14.1185 25.906 12.7387 25.4437 11.5503 24.6483C10.362 23.853 9.4085 22.7536 8.78919 21.4647L16 18V10Z"
            fill="#043338"
          />
          <Rect x="15" y="4" width="2" height="3" fill="#043338" />
          <Rect
            x="19"
            y="3"
            width="2"
            height="6"
            rx="1"
            transform="rotate(90 19 3)"
            fill="#043338"
          />
        </G>
        <Defs>
          <ClipPath id="clip0_5721_63427">
            <Rect width={32} height={32} fill="white" />
          </ClipPath>
        </Defs>
      </Svg>
    </View>
  );
};
export default TimelapseSVG;
