import { useApi, useAuth } from '../../hooks';
import { useQuery } from '@tanstack/react-query';

export const useSurveyData = (
  userId: string,
  userPhase: string | undefined
) => {
  const { api } = useApi();
  const { isAuthenticated, loading, userToken } = useAuth();

  const fetchSurvey = async (params: { phase?: string } = { phase: '' }) => {
    const { phase } = params;
    const searchParams = !!phase ? `?phase=${phase}` : '';

    const requestObj = {
      route: `/private/survey/${searchParams}`,
      token: userToken,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Coach-Override': userId,
      },
    };

    return await api(requestObj);
  };

  const { data, isLoading, isError } = useQuery<any[], Error>(
    ['survey-data', userToken, userId, userPhase],
    () => fetchSurvey({ phase: userPhase }),
    {
      enabled: isAuthenticated && !loading && !!userToken,
      refetchOnWindowFocus: false,
    }
  );

  return { surveys: data, isLoading, isError };
};
