import React from 'react';
import { Checkbox, HStack, Text } from 'native-base';
import { Phase } from '../../models/user';
import { useMemo } from 'react';

const defaultPhasesMap = {
  [Phase.One]: false,
  [Phase.Two]: false,
  [Phase.Three]: false,
  [Phase.Four]: false,
};

export const SurveyDisplayUserPhaseFilter = ({ onChange, selectedPhases }) => {
  const selectedPhasesMap = useMemo(() => {
    return selectedPhases.reduce((map, phase) => {
      return {
        ...map,
        [phase]: true,
      };
    }, defaultPhasesMap);
  }, [selectedPhases]);

  return (
    <HStack flexWrap={'wrap'}>
      {Object.keys(selectedPhasesMap).map((phaseKey) => (
        <Checkbox
          isChecked={selectedPhasesMap[phaseKey]}
          key={phaseKey}
          testID={`survey-checkbox-filter-${phaseKey}`}
          onChange={(checked) => {
            const newValue = { [phaseKey]: checked };
            const newMap = {
              ...selectedPhasesMap,
              ...newValue,
            };
            const newSelectedPhases = Object.keys(newMap)
              .filter((key) => !!newMap[key])
              .map((key) => key);
            onChange(newSelectedPhases);
          }}
          value={phaseKey}
          mr={5}
          minW={'100px'}
        >
          <Text py={1}>{phaseKey}</Text>
        </Checkbox>
      ))}
    </HStack>
  );
};
