import { Box, Flex, Heading, Pressable, Text, VStack } from 'native-base';
import React from 'react';
import { FORM_ACTIONS } from '../../utils/formActions';
import { useDiagnosis } from '../../api/onboarding/useDiagnosis';
import { Loader } from '../Loader';
import { isYesGiConditionOnlyForOtherDiagnosis } from './utils';

const FORM_DATA_KEY = 'diagnosis_ids';

const styles = {
  formButtons: {
    marginTop: '.5rem',
    marginBottom: '.5rem',
    width: 'calc(50% - .5rem)',
  },
};

export const GIConditionsList = ({ formData, dispatch }) => {
  const { data: diagnosisItems, isLoading } = useDiagnosis();

  const onSelectDiagnosis = (selectedDiagnosisId) => {
    const elementIndex = formData[FORM_DATA_KEY].indexOf(selectedDiagnosisId);

    // item not present in the form data: adding it
    if (elementIndex < 0) {
      return dispatch({
        type: FORM_ACTIONS.UPDATE_FORM_FIELD,
        fieldName: FORM_DATA_KEY,
        payload: [...formData[FORM_DATA_KEY], selectedDiagnosisId],
      });
    }

    // when the item has been already selected, we need to remove it
    const newSelectedItems = [
      ...formData[FORM_DATA_KEY].slice(0, elementIndex),
      ...formData[FORM_DATA_KEY].slice(elementIndex + 1),
    ];

    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: FORM_DATA_KEY,
      payload: newSelectedItems,
    });
  };

  if (isLoading) {
    return (
      <Box
        backgroundColor="muted.100"
        p={0}
        testID="onboarding-gi-conditions-loading-spinner"
      >
        <Loader loading={true} />
      </Box>
    );
  }

  if (!diagnosisItems.length) return null;

  return (
    <VStack mt={10} space={1} testID="onboarding-gi-conditions">
      <VStack className="header">
        <Heading
          alignSelf={'start'}
          fontFamily="body"
          fontWeight={'300'}
          size="sm"
        >
          If applicable, specify any other GI condition that you live with.
          (Select all that apply)
        </Heading>
      </VStack>
      <Flex
        direction="row"
        wrap={'wrap'}
        justifyContent={'space-between'}
        testID={'onboarding-gi-conditions-list'}
      >
        {diagnosisItems.map(({ diagnosis, id }) => {
          const isSelected = formData[FORM_DATA_KEY].includes(id);

          return (
            <Pressable
              style={styles.formButtons}
              key={id}
              onPress={() => onSelectDiagnosis(id)}
              testID={'onboarding-gi-conditions-list-item'}
            >
              <Box
                h={{ base: '160px', sm: 'auto' }}
                bg={isSelected ? 'secondary.500' : 'light.50'}
                borderWidth="1"
                borderColor="muted.400"
                borderRadius="lg"
                minHeight="70px"
                maxHeight="125px"
                justifyContent="center"
                p="4"
              >
                <Text
                  color={isSelected ? 'light.50' : 'secondary.500'}
                  fontWeight="medium"
                  fontSize="md"
                >
                  {diagnosis}
                </Text>
              </Box>
            </Pressable>
          );
        })}
      </Flex>

      {isYesGiConditionOnlyForOtherDiagnosis(formData) && (
        <Text color={'danger.600'} fontWeight={'medium'} mt={3}>
          *Please note, Ayble is best suited for patients with IBD, IBS, or who
          have symptoms like constipation, diarrhea, abdominal pain, bloating,
          etc. If you don’t have these symptoms, Ayble can still be helpful, but
          we recommend you also see a gastroenterologist.
        </Text>
      )}
    </VStack>
  );
};
