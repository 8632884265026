import { Text } from 'native-base';

// commenting types for when we will have TS enabled
/*
type Props = {
  label: string;
};
*/

const style = {
  color: 'muted.500',
  borderStyle: 'solid',
  borderWidth: 1,
};

export const Pill = ({ label }) => {
  const { color, borderStyle, borderWidth } = style;

  return (
    <Text
      px={4}
      mr={2}
      mb={2}
      py={1}
      borderRadius={4}
      borderColor={color}
      borderStyle={borderStyle}
      borderWidth={borderWidth}
      color={color}
      fontWeight={'normal'}
    >
      {label}
    </Text>
  );
};
