import { Modal, Text } from 'native-base';

export const FullBenefitsModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      testID="full-benefits-modal"
    >
      <Modal.Content>
        <Modal.Header minH={'50px'}>
          <Modal.CloseButton _icon={{ size: '4' }} />
        </Modal.Header>
        <Modal.Body display={'flex'} gap={'12px'}>
          <Text>
            <strong>RELEASE OF INFORMATION:</strong> I authorize Ayble Health to
            disclose and release to my insurance carrier(s), including Medicare,
            Medicaid, Medigap/Supplemental benefits providers, and private
            insurers, as applicable, any medical and treatment information
            needed for payment purposes for services rendered. I authorize the
            use of this form for the release of information needed to process
            claims to all my insurance carrier(s) and its authorized agents. I
            authorize Ayble Health to act as my agent in helping obtain payment
            from my insurance companies.
          </Text>
          <Text>
            <strong>ASSIGNMENT OF BENEFITS:</strong> I assign all payments,
            rights and claims for reimbursement of claims, costs and expenses
            allowable under my insurance plan(s) directly to Ayble Health for
            services rendered. I understand I will receive a statement for any
            balance due by me and I agree to make full payment upon receipt of
            the statement after insurance has met its obligation.
          </Text>
          <Text>
            <strong>AGREEMENT OF RESPONSIBILITY:</strong> I understand that
            COPAYMENT IS DUE AT THE TIME OF SERVICE (coinsurance and deductibles
            may also be collected at the time of service). I understand I am
            financially responsible for charges not covered by my insurance
            company. I also agree to pay any outstanding balance as well as
            attorney fees and costs to Ayble Health if this matter is referred
            to collection.
          </Text>
          <Text>
            <strong>MEDICARE AUTHORIZATION:</strong> If a Medicare beneficiary,
            I understand my consent requests payment to be made and authorize
            the release of medical information necessary to pay claims. If
            ‘other health insurance’ is indicated in item 9 of the HCFA-1500
            Form, or elsewhere on approved claim forms, or electronically
            submitted claims, my consent authorizes the release of information
            to insurance companies or its authorized agents. In
            Medicare-assigned cases, Ayble Health agrees to accept the charge of
            determination of the Medicare carrier as the full charge, and I
            agree I am responsible for deductible, coinsurance and non-covered
            services. Coinsurance and deductibles are based upon the charge
            determination of the Medicare carrier.
          </Text>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
