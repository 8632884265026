import React from 'react';
import { Divider, Flex, HStack, VStack } from 'native-base';
import { ScoreChart } from '../AreaChart/ScoreChart';
import { HealthCoachMindGutCard } from '../HealthCoachDashboard/HealthCoachMindGutCard';
import { CoachNotes } from '../HealthCoachDashboard/CoachNotes';
import { MeetingNotes } from '../HealthCoachDashboard/MeetingNotes';
import { PhaseFoods } from './PhaseFoods';
import { DietAndUserMealDisplay } from './DietAndUserMealDisplay';
import { NutritionProgram } from './NutritionProgram';
import { UserInfo } from './UserInfo';

export const OverviewPanel = ({ onPhaseDetailsClick, onPhaseLinkClick }) => {
  return (
    <VStack p={6} pt={12}>
      <HStack mx={6} pb={6}>
        <VStack flex={1} pr={4}>
          <UserInfo />

          <VStack pt={12}>
            <MeetingNotes />
          </VStack>
        </VStack>

        <VStack flex={1} pl={4}>
          <VStack maxW={'440px'}>
            <CoachNotes />
          </VStack>
        </VStack>
      </HStack>

      <Divider my={12} />

      <Flex mx={6}>
        <NutritionProgram
          onPhaseDetailsClick={onPhaseDetailsClick}
          onPhaseLinkClick={onPhaseLinkClick}
        />
      </Flex>

      <Divider my={12} />

      <Flex mx={6}>
        <DietAndUserMealDisplay />
      </Flex>

      <Divider my={12} />

      <Flex mx={6}>
        <ScoreChart />
      </Flex>

      <Divider my={12} />

      <Flex ml={6}>
        <PhaseFoods />
      </Flex>

      <Divider my={12} />

      <HealthCoachMindGutCard />
    </VStack>
  );
};
