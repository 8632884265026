import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks';

export const useCardScanAuth = ({ userId }) => {
  const { api } = useApi();
  const { userToken, loading } = useAuth();

  const getAuthToken = async (userId) => {
    const authenticatedRoute = `/private/cardscan/authenticate`;
    const unAuthenticatedRoute = `/cardscan/authenticate/${userId}`;

    const isAuthenticated = userToken && !loading;

    return await api({
      route: isAuthenticated ? authenticatedRoute : unAuthenticatedRoute,
      token: isAuthenticated ? userToken : undefined,
    });
  };

  const {
    data: authObject,
    isLoading,
    isInitialLoading,
    isError,
  } = useQuery(['cardscanauth', userId], () => getAuthToken(userId));

  return { authObject, isLoading, isError, isInitialLoading };
};
