import { Box, Text, VStack } from 'native-base';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import { BulletListItem } from './BulletListItem';
import { generateBulletPoints } from './bullet-points';

export const BulletList = ({
  highlightedBulletIndex = null,
}: {
  highlightedBulletIndex?: number | null;
}) => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});
  const bulletPoints = generateBulletPoints(fetchedAffiliate);

  return (
    <ul style={{ listStyleType: 'none', paddingLeft: '12px' }}>
      {bulletPoints.map(({ bg, bgSize, imgUrl, text, title }, index) => (
        <BulletListItem
          bgColor={bg}
          bgSize={bgSize}
          highlighted={
            highlightedBulletIndex != null && index <= highlightedBulletIndex
          }
          img={imgUrl}
          key={title}
        >
          <VStack>
            <Box pb={1}>{title}</Box>
            <Text fontSize={'lg'} fontWeight={'thin'}>
              {text}
            </Text>
          </VStack>
        </BulletListItem>
      ))}
    </ul>
  );
};
