import { useApi, useAuth } from '../../hooks';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

type Symptom = {
  id: string;
  symptom: string;
};

export const useSymptomSelect = () => {
  const { api } = useApi();
  const { userToken } = useAuth();

  const fetchSymptoms = async (): Promise<Symptom[]> => {
    const requestObj = {
      route: '/private/symptom/',
      token: userToken,
    };

    return await api(requestObj);
  };

  const { data: symptoms } = useQuery<Symptom[], Error>(
    ['hc-symptom-select', userToken],
    () => fetchSymptoms(),
    {
      enabled: !!userToken,
      refetchOnWindowFocus: false,
    }
  );

  const loadSymptomOptions = useCallback(
    async (inputValue) => {
      if (!symptoms) return [];

      const selectOpts = symptoms.map(({ id, symptom }) => ({
        label: symptom,
        value: id,
      }));
      return selectOpts.filter((o) =>
        o.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    },
    [symptoms]
  );

  return { loadSymptomOptions };
};
