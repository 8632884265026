import styled from '@emotion/styled';
import { ITheme, Text } from 'native-base';
import { animated, useSpring } from 'react-spring';
import { BulletImage } from '../ui/BulletImage';

const ANIMATION_DURATION_MS = 300;

const Item = styled(animated.li)<{ highlighted?: boolean; theme?: ITheme }>`
  align-items: flex-start;
  color: ${(p) => p.theme.colors.secondary['500']};
  display: flex;
  margin-top: 32px;
  opacity: ${(p) => (p.highlighted ? null : '0.5')};
`;

export const BulletListItem = ({
  bgColor,
  bgSize,
  children,
  highlighted = false,
  img,
  style = null,
}: {
  bgColor: string;
  bgSize?: string;
  children;
  highlighted?: boolean;
  img: string;
  style?: React.CSSProperties | null;
}) => {
  const { x } = useSpring({
    from: { x: 0 },
    x: highlighted ? 1 : 0,
    config: { duration: ANIMATION_DURATION_MS },
  });

  // bounce effect when an item is highlighted
  const animatedStyle = {
    scale: x.to({
      range: [0, 0.5, 1],
      output: [1, 1.05, 1],
    }),
  };

  return (
    <Item
      data-testId="signup-list-item"
      highlighted={highlighted}
      style={{ ...style, ...animatedStyle }}
    >
      <BulletImage
        width={40}
        height={40}
        bgColor={bgColor}
        bgSize={bgSize}
        imgUrl={img}
      />
      <Text
        alignSelf={'center'}
        color={'white'}
        fontSize={'xl'}
        fontWeight={'medium'}
        ml={3}
        textAlign={'left'}
      >
        {children}
      </Text>
    </Item>
  );
};
