import { useAffiliate } from '../../api/affiliates/useAffiliate';
import { useMemo } from 'react';

type Props = {
  affiliate?: string;
  referrer?: string;
};

// In case referrer or affiliate are passed to this component, they will be used to fetch the info
// about the referrer or the affiliate accordingly.
// Otherwise, the component would fall back to the logic in the useAffiliate hook.
export const useAffiliateBanner = ({ affiliate, referrer }: Props) => {
  const useAffiliateParams = useMemo(() => {
    if (!!referrer) return { affiliateId: referrer };
    if (!!affiliate) return { affiliateId: affiliate };

    return {};
  }, [affiliate, referrer]);

  return useAffiliate(useAffiliateParams);
};
