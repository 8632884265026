import { Flex, Spinner } from 'native-base';
import { memo } from 'react';
import { useImagePreloader } from '../../hooks/useImagePreloader';
import { BulletList } from './BulletList';
import styled from '@emotion/styled';

const IMAGE_HEIGHT_PX = 470;
const IMAGE_WIDTH_PX = 280;

const imgList = [
  '../../convenient_virtual_care.png',
  '../../Eat_with_confidence.png',
  '../../Calm_your_mind_to_calm_your_gut.png',
  '../../manage_symptoms_effectively.png',
  '../../find_foods_that_work_for_you.png',
  '../../learn_from_gut_health_experts.png',
];

const ImgContainer = styled('span')`
  display: flex;
  height: ${IMAGE_HEIGHT_PX}px;
  min-width: ${IMAGE_WIDTH_PX}px;
  position: relative;

  & > div {
    transition: opacity linear 200ms;
  }
`;

const Carousel = ({ currentIndex = null }: { currentIndex: number | null }) => {
  const { imagesPreloaded } = useImagePreloader(imgList);

  if (!imagesPreloaded) return <Spinner size={'sm'} />;

  return (
    <Flex direction={'row'} pt={12} alignItems={'center'}>
      <ImgContainer>
        {imgList.map((img, i) => (
          <div
            style={{
              backgroundImage: `url(${img})`,
              backgroundPosition: 'center center',
              backgroundSize: 'contain',
              height: IMAGE_HEIGHT_PX,
              opacity: i === currentIndex ? 1 : 0,
              position: 'absolute',
              width: IMAGE_WIDTH_PX,
            }}
          />
        ))}
      </ImgContainer>

      <BulletList highlightedBulletIndex={currentIndex} />
    </Flex>
  );
};

export const ImageCarousel = memo(Carousel);
