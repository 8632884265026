import { Button, HStack, Text, VStack, Image, Box } from 'native-base';

const chevronRight = '../chevron-right.png';
const chevronLeft = '../chevron-left.png';

type Props = {
  activeScreenIdx: number;
  title?: string;
  section?: string;
  phase?: string;
  numScreens?: number;
  prevScreen: () => void;
  nextScreen: () => void;
};

const PreviewHeader = ({
  activeScreenIdx,
  section,
  phase,
  title,
  numScreens = 0,
  prevScreen,
  nextScreen,
}: Props) => {
  return (
    <VStack mb={'auto'} mt={0} backgroundColor={'warmGray.300'} p={2}>
      <Box>
        {title && (
          <Text my={1} w={'100%'}>
            <Text bold>{'Title: '}</Text>
            <Text>{title}</Text>
          </Text>
        )}
        <Text my={1} w={'100%'}>
          <Text bold>{'Section: '}</Text>
          <Text>{section}</Text>
        </Text>
        {phase && (
          <Text my={1} w={'100%'}>
            <Text bold>{'Phase: '}</Text>
            <Text>{phase}</Text>
          </Text>
        )}
        <Text my={1} w={'100%'}>
          <Text bold>{'Screen: '}</Text>
          <Text>{`${activeScreenIdx + 1} of ${numScreens}`}</Text>
        </Text>
      </Box>
      <HStack>
        <Button
          mr={2}
          backgroundColor={'amber.100'}
          onPress={prevScreen}
          isDisabled={activeScreenIdx === 0}
        >
          <Image size={8} source={chevronLeft as any} />
        </Button>
        <Button
          backgroundColor={'amber.100'}
          onPress={nextScreen}
          isDisabled={activeScreenIdx === numScreens - 1}
        >
          <Image size={8} source={chevronRight as any} />
        </Button>
      </HStack>
    </VStack>
  );
};

export default PreviewHeader;
