import { Box, Heading, Text, VStack } from 'native-base';
import { useAffiliate } from '../../api/affiliates/useAffiliate';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';
import { generateDateStringByTimestamp } from './utils';
import { Loader } from '../Loader';
import { useUserCoachProviderPublicInfo } from '../../hooks/useUserCoachProviderPublicInfo';

const THIRD_PARTY_SUBSCRIPTIONS = ['sub_covered', 'sub_test'];

export const UserInfo = () => {
  const {
    state: { user },
  } = useDashboardPageState();

  const { coachInfo, providerInfo } = useUserCoachProviderPublicInfo(user?.id);

  const {
    affiliate,
    isInitialLoading: isAffiliateLoading,
    isLoading: isFetchingAffiliate,
  } = useAffiliate({
    affiliateId: user?.affiliate,
  });

  const {
    affiliate: referrer,
    isInitialLoading: isReferrerLoading,
    isLoading: isFetchingReferrer,
  } = useAffiliate({
    affiliateId: user?.referrer,
  });

  const cancelDateString = generateDateStringByTimestamp(user?.canceled);
  const isLoading =
    (user?.affiliate && (isAffiliateLoading || isFetchingAffiliate)) ||
    (user?.referrer && (isReferrerLoading || isFetchingReferrer));

  if (!user || isLoading) {
    return (
      <Box backgroundColor="muted.100" py={6} height={'100%'}>
        <Loader loading={isLoading} />
      </Box>
    );
  }

  const { birthdate, ethnicity, gender, plan_name, sex, subscription_id } =
    user;

  return (
    <VStack>
      <Heading fontWeight={'normal'} mb={6} size={'md'} textAlign="left">
        User Info
      </Heading>

      <VStack mb={3}>
        {cancelDateString && (
          <Text mb={1}>
            ⚠️ Cancellation date:{' '}
            <Text fontWeight={'normal'}>{cancelDateString}</Text>
          </Text>
        )}

        {referrer && (
          <Text mb={1}>
            Referred by <Text fontWeight={'normal'}>{referrer.name}</Text>
          </Text>
        )}

        {affiliate ? (
          <Text mb={1}>
            Health Plan: <Text fontWeight={'normal'}>{affiliate.name}</Text>{' '}
            <Text>
              ({subscription_id === 'sub_covered' ? 'Verified' : 'Not Verified'}
              )
            </Text>
          </Text>
        ) : (
          !!subscription_id &&
          !THIRD_PARTY_SUBSCRIPTIONS.includes(subscription_id) && (
            <Text mb={1}>
              Subscription: <Text fontWeight={'normal'}>{plan_name}</Text>
            </Text>
          )
        )}
        <Text mb={1}>
          Account Type:{' '}
          <Text fontWeight={'normal'}>
            {subscription_id === 'sub_covered'
              ? 'Covered by Health Plan (B2B)'
              : 'Stripe Subscription (D2C)'}
          </Text>
        </Text>
      </VStack>

      <VStack mb={3}>
        <Text mb={1}>
          Ethnicity: <Text fontWeight={'normal'}>{ethnicity ?? '--'}</Text>
        </Text>
        <Text mb={1}>
          Sex at Birth: <Text fontWeight={'normal'}>{sex ?? '--'}</Text>
        </Text>
        <Text mb={1}>
          Gender Identity: <Text fontWeight={'normal'}>{gender ?? '--'}</Text>
        </Text>
        <Text mb={1}>
          Date of Birth: <Text fontWeight={'normal'}>{birthdate ?? '--'}</Text>
        </Text>
      </VStack>

      <Text mb={1} fontWeight={'medium'}>
        Care Team
      </Text>

      {coachInfo.isLoading ? (
        <Loader loading={true} />
      ) : (
        <Text mb={1}>
          Coach:{' '}
          <Text fontWeight={'normal'}>
            {coachInfo?.data?.first_name ?? '-'}{' '}
            {coachInfo?.data?.last_name ?? '-'}
          </Text>
        </Text>
      )}

      {providerInfo.isLoading ? (
        <Loader loading={true} />
      ) : (
        <Text mb={1}>
          Provider:{' '}
          <Text fontWeight={'normal'}>
            {providerInfo?.data?.full_name ?? '- -'}
          </Text>
        </Text>
      )}
    </VStack>
  );
};
