import React from 'react';
import {
  Box,
  Heading,
  HStack,
  Image,
  Link,
  Text,
  useTheme,
  VStack,
} from 'native-base';
import { getUserPhase } from './utils';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';
import { PhaseDateRangeDisplay } from './PhaseDateRangeDisplay';

const styles = {
  inset: {
    color: 'white',
    position: 'absolute',
    width: 16,
    height: 16,
    top: 3,
    left: 3,
    backgroundColor: 'black',
    borderRadius: 8,
  },
  blob: {
    width: 37,
    height: 37,
  },
  forwardBttn: {
    width: 16,
    height: 16,
  },
};

const completeBlob = '../../program-complete-icon.svg';
const inProgressBlob = '../../program-in-progress-icon.svg';
const upcomingBlob = '../../program-upcoming-icon.svg';
const forwardBttn = '../../chevron-right.png';

const blobStatus = (currentPhase, phase) => {
  if (phase < currentPhase) {
    return completeBlob;
  }
  if (phase === currentPhase) {
    return inProgressBlob;
  }
  if (phase > currentPhase) {
    return upcomingBlob;
  }
};

const phaseLinkTitle = (phase, currentPhase) => {
  return phase === currentPhase ? 'View Progress' : 'View Results';
};

export const ProgramFeed = ({ onPhaseLinkClick }) => {
  const {
    colors: { muted, secondary },
  } = useTheme();
  const {
    state: { user },
  } = useDashboardPageState();
  const {
    current_phase,
    phase_1_summary,
    phase_2_summary,
    phase_3_summary,
    phase_4_summary,
    potential_triggers,
  } = user;
  const currentPhase = getUserPhase(current_phase);

  return (
    <VStack px={12} alignItems="left">
      <Heading size={'md'} fontWeight={'normal'} textAlign="left" mb={6}>
        Phase Progress
      </Heading>
      <HStack mt={2} alignItems={'flex-start'}>
        <VStack alignItems={'flex-start'}>
          <Image
            zIndex="1"
            source={blobStatus(currentPhase, 1)}
            style={styles.blob}
          />
          <Box
            rounded={'lg'}
            w={'2px'}
            h={'55px'}
            bg={secondary['200']}
            my={1}
            ml={5}
          ></Box>
          <Image
            zIndex="1"
            source={blobStatus(currentPhase, 2)}
            style={styles.blob}
          />
          <Box
            rounded={'lg'}
            w={'2px'}
            h={'55px'}
            bg={muted['400']}
            my={1}
            ml={5}
          ></Box>
          <Image
            zIndex="1"
            source={blobStatus(currentPhase, 3)}
            style={styles.blob}
          />
          <Box
            rounded={'lg'}
            w={'2px'}
            h={'55px'}
            bg={muted['400']}
            my={1}
            ml={5}
          ></Box>
          <Image
            zIndex="1"
            source={blobStatus(currentPhase, 4)}
            style={styles.blob}
          />
        </VStack>
        <VStack>
          <HStack alignItems={'center'}>
            <Box ml={5} h={'102px'}>
              <VStack>
                <Text fontSize={16}>Phase 1: Identification</Text>

                <PhaseDateRangeDisplay phaseSummary={phase_1_summary} />

                <Text fontWeight="medium" fontSize={16} color="black">
                  {currentPhase < 1
                    ? 'Upcoming.'
                    : currentPhase > 1
                    ? `We found ${potential_triggers.length} potiental trigger food categories`
                    : 'In Progress.'}
                </Text>

                {currentPhase >= 1 && (
                  <Link
                    mt={1}
                    onPress={() => onPhaseLinkClick(1, 'Identification')}
                  >
                    <HStack alignItems={'center'}>
                      <Text fontSize={12} fontWeight="500">
                        {phaseLinkTitle(1, currentPhase)}
                      </Text>
                      <Image
                        zIndex="1"
                        source={forwardBttn}
                        style={styles.forwardBttn}
                      />
                    </HStack>
                  </Link>
                )}
              </VStack>
            </Box>
          </HStack>

          <HStack alignItems={'center'}>
            <Box ml={5} h={'102px'}>
              <VStack>
                <Text fontSize={16}>Phase 2: Elimination</Text>

                <PhaseDateRangeDisplay phaseSummary={phase_2_summary} />

                <Text fontWeight="medium" fontSize={16} color="black">
                  {currentPhase < 2
                    ? 'Upcoming.'
                    : currentPhase > 2
                    ? 'Your symptoms improved --%'
                    : 'In Progress.'}
                </Text>

                {currentPhase >= 2 && (
                  <Link
                    mt={1}
                    onPress={() => onPhaseLinkClick(2, 'Elimination')}
                  >
                    <HStack alignItems={'center'}>
                      <Text fontSize={12} fontWeight="500">
                        {phaseLinkTitle(2, currentPhase)}
                      </Text>
                      <Image
                        zIndex="1"
                        source={forwardBttn}
                        style={styles.forwardBttn}
                      />
                    </HStack>
                  </Link>
                )}
              </VStack>
            </Box>
          </HStack>

          <HStack alignItems={'center'}>
            <Box ml={5} h={'102px'}>
              <VStack>
                <Text fontSize={16}>Phase 3: Reintroduction</Text>

                <PhaseDateRangeDisplay phaseSummary={phase_3_summary} />

                <Text fontWeight="medium" fontSize={16} color="black">
                  {currentPhase < 3
                    ? 'Upcoming.'
                    : currentPhase > 3
                    ? 'Your symptoms improved --%'
                    : 'In Progress.'}
                </Text>

                {currentPhase >= 3 && (
                  <Link
                    mt={1}
                    onPress={() => onPhaseLinkClick(3, 'Reintroduction')}
                  >
                    <HStack alignItems={'center'}>
                      <Text fontSize={12} fontWeight="500">
                        {phaseLinkTitle(3, currentPhase)}
                      </Text>
                      <Image
                        zIndex="1"
                        source={forwardBttn}
                        style={styles.forwardBttn}
                      />
                    </HStack>
                  </Link>
                )}
              </VStack>
            </Box>
          </HStack>

          <HStack alignItems={'center'}>
            <Box ml={5} h={'102px'}>
              <VStack>
                <Text fontSize={16}>Phase 4: Application</Text>

                <PhaseDateRangeDisplay phaseSummary={phase_4_summary} />

                <Text fontWeight="medium" fontSize={16} color="black">
                  {currentPhase < 4
                    ? 'Upcoming.'
                    : currentPhase > 4
                    ? 'You Completed Your Program!'
                    : 'In Progress.'}
                </Text>

                {currentPhase >= 4 && (
                  <Link
                    mt={1}
                    onPress={() => onPhaseLinkClick(4, 'Maintenance')}
                  >
                    <HStack alignItems={'center'}>
                      <Text fontSize={12} fontWeight="500">
                        {currentPhase > 4
                          ? 'View Final Results'
                          : 'View Progress'}
                      </Text>
                      <Image
                        zIndex="1"
                        source={forwardBttn}
                        style={styles.forwardBttn}
                      />
                    </HStack>
                  </Link>
                )}
              </VStack>
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};
