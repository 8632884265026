import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const MailchimpForm = (props) => {
  const { metaData, submitRef } = props;
  const url = `https://myagorahealth.us7.list-manage.com/subscribe/post?u=6e2aecc3076a1fd9fee90dcae&id=f7b5f1db24`;

  const CustomForm = ({ status, onValidated, message }) => {
    const handleSubmit = (e) => {
      e.preventDefault();
      metaData.email &&
        metaData.firstName &&
        metaData.lastName &&
        metaData.state &&
        metaData.email.indexOf('@') > -1 &&
        onValidated({
          MERGE0: metaData.email,
          MERGE1: metaData.firstName,
          MERGE2: metaData.lastName,
          MERGE5: metaData.state,
        });
    };

    return (
      <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
        {status === 'error' && (
          <div
            className="mc__alert mc__alert--error"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <button
          ref={submitRef}
          style={{ visibility: 'hidden' }}
          label="subscribe"
          type="submit"
        >
          Submit
        </button>
      </form>
    );
  };

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpForm;
