import styled from '@emotion/styled';

const Circle = styled('div')`
  background-attachment: scroll;
  background-color: ${(p) => p.bgColor};
  background-image: url(${(p) => p.imgUrl});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: ${(p) => p.bgSize ?? 'contain'};
  border-radius: 50%;
  height: ${(p) => p.height}px;
  min-width: ${(p) => p.width}px;
  width: ${(p) => p.width}px;
`;

export const BulletImage = ({ bgColor, bgSize, imgUrl, height, width }) => {
  return (
    <Circle
      bgColor={bgColor}
      bgSize={bgSize}
      data-testId="bullet-image"
      imgUrl={imgUrl}
      height={height}
      width={width}
    />
  );
};
