import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Loader } from './Loader';
import { useAuth } from '../hooks';

export const PublicRoute = ({ children }) => {
  const { loading, isAuthenticated, user } = useAuth();

  if (loading) return <Loader loading />;

  if (isAuthenticated) {
    const redirectPath = () => {
      if (
        user?.subscription_id ||
        user?.iap_purchase_id ||
        localStorage.getItem('isFromIapFlow')
      ) {
        return '/thank-you';
      }

      return '/unsubscribed-landing';
    };

    return (
      <Navigate
        to={redirectPath()}
        state={{
          affiliate: user.affiliate,
          userId: user.id,
          userEmail: user.email,
        }}
        replace
      />
    );
  }
  return children ? children : <Outlet />;
};
