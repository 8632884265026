import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Heading, List, Spinner, Text, VStack } from 'native-base';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { useSurveyData } from '../../api/healthCoach/useSurveyData';
import { useParams } from 'react-router-dom';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';
import { SurveyDisplayUserPhaseFilter } from './SurveyDisplayUserPhaseFilter';
import { Phase } from '../../models/user';

const DAILY_MEALS_SURVEY_TYPE = 'daily-meals';
const CONTENT_HEIGHT_PX = 296;

const mealsIconsMap = {
  Breakfast: '☕️',
  Lunch: '🥙',
  Dinner: '🍝',
  Snack: '🍫',
};

const SurveyList = styled('div')`
  max-height: ${CONTENT_HEIGHT_PX}px;
  overflow: auto;
`;

const MealsList = ({ dishes, type }) => {
  if (!dishes?.length) return null;

  const icon = mealsIconsMap[type] || mealsIconsMap['Lunch'];

  return (
    <VStack pl={8} pt={3}>
      <Text fontSize={'lg'}>
        {icon} {type}
      </Text>

      <List.Unordered ul borderWidth={0} pl={3}>
        {dishes.map(({ name, servingSize }, i) => (
          <List.Item
            key={i}
            p={0}
            testID={'survey-display-food-item'}
          >{`${servingSize} ${name}`}</List.Item>
        ))}
      </List.Unordered>
    </VStack>
  );
};

const DailyMeals = ({ date, meals }) => {
  const mealTypes = Object.keys(meals);

  // we remove the not allowed entries for every meal type
  const normalizedMeals = mealTypes.reduce((acc, mealKey) => {
    // take the dishes from a single meal (e.g. all the dishes in lunch)
    const dishes = meals[mealKey];
    // do not include the invalid ones
    const actualDishes = dishes?.filter(
      (dish) => !dish?.skipped && dish?.name && dish?.servingSize
    );
    // no dishes => no mention for this meal
    if (!actualDishes?.length) return acc;

    acc[mealKey] = { dishes: actualDishes };
    return acc;
  }, {});

  if (isEmpty(normalizedMeals)) return null;

  return (
    <VStack pl={3} pt={3}>
      <Text
        fontSize={'xl'}
        fontWeight={'semibold'}
        textAlign="left"
        testID={'survey-display-date'}
      >
        📆{`  ${date}`}
        <Text ml={1} fontSize={'sm'} fontWeight={'light'} italic>
          in UTC
        </Text>
      </Text>

      {Object.keys(normalizedMeals).map((type, i) => (
        <MealsList key={i} dishes={normalizedMeals[type].dishes} type={type} />
      ))}
    </VStack>
  );
};

export const SurveyDisplay = () => {
  const { patient = '' } = useParams();
  const {
    state: { user },
  } = useDashboardPageState();
  const currentPhase = user?.current_phase ?? Phase.Zero;
  const [selectedPhases, setSelectedPhases] = useState<Phase[]>([currentPhase]);
  const showCurrentPhaseOnly =
    selectedPhases.length === 1 && selectedPhases[0] === currentPhase;
  const { isError, isLoading, surveys } = useSurveyData(
    patient,
    showCurrentPhaseOnly ? currentPhase : undefined
  );

  // Filter surveys to get the ones containing the meals info,
  // plus just take the ones according to the phase selected
  const mealSurveys = useMemo(
    () =>
      surveys
        ?.filter(
          (survey) =>
            survey.type === DAILY_MEALS_SURVEY_TYPE &&
            selectedPhases.length &&
            selectedPhases.includes(survey.phase)
        )
        .reverse(),
    [selectedPhases, surveys]
  );

  if (isLoading) return <Spinner my={4} />;

  if (isError) {
    return (
      <Text color={'red.500'}>
        There was an error while loading the surveys, please try again later.
      </Text>
    );
  }

  return (
    <VStack mx={6}>
      <VStack alignItems={'start'}>
        <Heading size={'md'} fontWeight={'normal'}>
          User Meal Log
        </Heading>
        <Text fontSize={'md'} italic>
          According to the selected user phases
        </Text>
      </VStack>

      <VStack my={4}>
        <SurveyDisplayUserPhaseFilter
          onChange={setSelectedPhases}
          selectedPhases={selectedPhases}
        />
      </VStack>

      {mealSurveys?.length ? (
        <SurveyList>
          {mealSurveys.map((survey, i) => {
            const { survey_date, data } = survey;
            const meals = data[0];
            const surveyDate = moment
              .utc(survey_date)
              .format('YYYY-MM-DD - hh:mm A');
            return <DailyMeals key={i} date={surveyDate} meals={meals} />;
          })}
        </SurveyList>
      ) : (
        <Text fontSize={'lg'} height={CONTENT_HEIGHT_PX} p={3}>
          ❌ No Meals to show
        </Text>
      )}
    </VStack>
  );
};
