import React, { useMemo } from 'react';
import { sortBy } from 'lodash';
import { View, Heading, Box, VStack, Flex, Text } from 'native-base';
import { foodList } from '../../../constants/foodList';
import { useDashboardPageState } from '../../../hooks/useDashboardPageState';
import { getUserPhase } from '../utils';
import { useSuspectedFoods } from '../../../api/healthCoach/useFoodChallenges';
import { useParams } from 'react-router-dom';
import { Loader } from '../../Loader';
import { FoodCard } from '../../ui/FoodCard';
import { User } from '../../../models/user';

const UPCOMING_CHALLENGE = 'Upcoming';
const COMPLETED_CHALLENGE = 'Completed';
const NOT_A_TRIGGER_FOOD = 'Not a trigger';
const SUSPECTED_TRIGGER = 'Suspected trigger';
const MILD_SEVERITY = 'Mild';
const MODERATE_SEVERITY = 'Moderate';
const SEVERE_SEVERITY = 'Severe';

export const statusSeverityMap = {
  [SUSPECTED_TRIGGER]: 1,
  [MILD_SEVERITY]: 2,
  [MODERATE_SEVERITY]: 3,
  [SEVERE_SEVERITY]: 4,
};

const getFoodListByPhase = (user: User | null) => {
  if (!user) return [];

  const userPhase = getUserPhase(user.current_phase);
  if (userPhase === 0 || userPhase === 1) return user?.recommended_foods || [];
  if (userPhase === 2) return user?.potential_triggers || [];
  if (userPhase === 3) return user?.suspected_triggers || [];
  return user?.recommended_foods || [];
};

const computeTitleByPhase = (userPhase: number) => {
  if (userPhase === 0 || userPhase === 1) return 'Recommended Foods';
  if (userPhase === 2) return 'Suspected Foods';
  if (userPhase === 3) return 'Food Challenges';
  return 'Verified Triggers';
};

const computeStatus = (currentFoodChallenges, food, userPhase) => {
  const completed = currentFoodChallenges['completed']
    ? currentFoodChallenges['completed'].find(
        (challenge_food) => challenge_food.food_category === food
      )
    : [];

  const not_completed = currentFoodChallenges['not_completed']
    ? currentFoodChallenges['not_completed'].find(
        (challenge_food) => challenge_food.food_category === food
      )
    : [];

  if (completed) {
    return completed.severity ? completed.severity : NOT_A_TRIGGER_FOOD;
  }

  if (not_completed) {
    return 'In Progress\nDay ' + not_completed['current_day'];
  }

  const isSuspectedTrigger = currentFoodChallenges['upcoming']
    ? currentFoodChallenges['upcoming'].find(
        (challenge_food) => challenge_food.food_category === food
      )
    : false;
  if (isSuspectedTrigger && userPhase === 4) return SUSPECTED_TRIGGER;

  return UPCOMING_CHALLENGE;
};

export const FoodChallenges = () => {
  const { patient } = useParams();
  const {
    state: { user },
  } = useDashboardPageState();

  const { foodChallenges: currentFoodChallenges, isLoading } =
    useSuspectedFoods(patient ?? '');

  const foodChallenges = useMemo(() => getFoodListByPhase(user), [user]);

  const completedChallenges = currentFoodChallenges?.['completed']
    ? currentFoodChallenges['completed'].length
    : [];

  const userPhase = useMemo(
    () => getUserPhase(user?.current_phase),
    [user?.current_phase]
  );

  const title = computeTitleByPhase(userPhase);
  const subTitle =
    userPhase === 3
      ? `${completedChallenges} challenge${
          completedChallenges > 1 ? 's' : ''
        } out of ${foodChallenges.length} completed`
      : `${foodChallenges.length} food${foodChallenges.length > 1 ? 's' : ''}`;

  /**
   * We sort the food challenges before rendering them;
   * this way, especially in phase 4, we can first list the not trigger foods,
   * then the triggers and lastly the skipped challenges among the list of 21 foods.
   */
  const sortedFoodChallengesByState = useMemo<
    { foodKey: string; status: string }[]
  >(() => {
    const unsortedChallenges = foodChallenges?.map((foodKey: string) => {
      const status = computeStatus(currentFoodChallenges, foodKey, userPhase);
      return { foodKey, status };
    });

    return unsortedChallenges ? sortBy(unsortedChallenges, ['status']) : [];
  }, [currentFoodChallenges, foodChallenges, userPhase]);

  if (isLoading) {
    return (
      <Box backgroundColor="muted.100" p={0}>
        <Loader loading={true} />
      </Box>
    );
  }

  return (
    <VStack>
      <Flex flexDir={'row'}>
        <Heading size={'md'} fontWeight={'normal'} textAlign="left">
          {title}
        </Heading>
      </Flex>

      <Text mb={6}>{subTitle}</Text>

      <View>
        <Flex flexDir={'row'} overflow={'scroll'} pb={3}>
          {sortedFoodChallengesByState.map(
            ({ foodKey, status }: { foodKey: string; status: string }) => {
              const isCardActive =
                statusSeverityMap[status] > 0 || status === NOT_A_TRIGGER_FOOD;

              return (
                <FoodCard
                  key={foodKey}
                  active={isCardActive}
                  dashedBorder={status !== COMPLETED_CHALLENGE}
                  disableSeverityColor={status === UPCOMING_CHALLENGE}
                  disableSeverityLabel={!isCardActive && userPhase === 4}
                  foodId={foodKey}
                  foodLabel={foodKey}
                  imgURL={foodList[foodKey]?.imgURL}
                  severityLabel={status}
                  severityLevel={statusSeverityMap[status] ?? 0}
                />
              );
            }
          )}
        </Flex>
      </View>
    </VStack>
  );
};
