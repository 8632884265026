import { useMutation, useQueryClient } from '@tanstack/react-query';
import { differenceBy } from 'lodash';
import { useApi, useAuth } from '../../../hooks';

export const useUpdateUserDiagnosisAndSymptom = (
  userId: string,
  diagnosisAndSymptomData: any
) => {
  const queryClient = useQueryClient();
  const { api } = useApi();
  const { userToken } = useAuth();

  const mutateUserDiagnosisAndSymptom = useMutation({
    mutationFn: async (newDiagnosisAndSymptom: any) => {
      const { diagnosis: oldDiagnosis, symptom: oldSymptom } =
        diagnosisAndSymptomData;
      const { diagnosis: newDiagnosis, symptom: newSymptom } =
        newDiagnosisAndSymptom;

      const deletedDiagnosis = differenceBy(oldDiagnosis, newDiagnosis, 'id');
      const deletedSymptom = differenceBy(oldSymptom, newSymptom, 'id');
      const diagnosisToCreate = differenceBy(newDiagnosis, oldDiagnosis, 'id');
      const symptomToCreate = differenceBy(newSymptom, oldSymptom, 'id');

      // create a promise for each diagnosis/symptom as we cannot delete/create in bulk
      const diagnosisToDeletePromises = deletedDiagnosis.map((d: any) => {
        return api({
          route: `/private/user-diagnosis/${d.id}/`,
          token: userToken,
          method: 'DELETE',
        });
      });

      const symptomToDeletePromises = deletedSymptom.map((s: any) => {
        return api({
          route: `/private/user-symptom/${s.id}/`,
          token: userToken,
          method: 'DELETE',
        });
      });

      const diagnosisToCreatePromises = diagnosisToCreate.map((d: any) => {
        const payload: any = { user_id: userId, dx_id: d.id };

        return api({
          route: '/private/user-diagnosis/',
          token: userToken,
          method: 'POST',
          payload,
        });
      });

      const symptomToCreatePromises = symptomToCreate.map((s: any) => {
        const payload: any = { user_id: userId, sx_id: s.id };

        return api({
          route: '/private/user-symptom/',
          token: userToken,
          method: 'POST',
          payload,
        });
      });

      return await Promise.all([
        ...diagnosisToDeletePromises,
        ...symptomToDeletePromises,
        ...diagnosisToCreatePromises,
        ...symptomToCreatePromises,
      ]);
    },

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['hc-user-diagnosis-and-symptom'],
      }),
  });

  return { mutateUserDiagnosisAndSymptom };
};
