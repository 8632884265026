import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';

const levelBgColorMap = {
  0: 'linear-gradient(0.25turn, #BCE5D9, 50%, #E9D56A)',
  1: 'linear-gradient(0.25turn, #E0DA87, 50%, #E8AC4F)',
  2: 'linear-gradient(0.25turn, #E8AC4F, 50%, #DE8047)',
  3: 'linear-gradient(0.25turn, #DE8047, 50%, #D1483D)',
};

const Badge = styled('div')<{ level: number }>`
  background: ${(p) => levelBgColorMap[p.level ?? 0]};
  border: 1px solid ${theme.colors.muted['900']};
  border-radius: 8px;
  height: 24px;
  width: 8px;
`;

type Props = {
  level: number;
};

export const SeverityBadge = ({ level }: Props) => {
  return <Badge level={level} />;
};
