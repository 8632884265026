import { VStack, Pressable, Text, Box, Heading } from 'native-base';
import { FORM_ACTIONS } from '../../utils/formActions.js';

function DiagnosisSelectionForm({ formData, dispatch }) {
  const clearDiagnosisForm = () => {
    const fields = ['hasUlcerativeColitis', 'hasCrohnsDisease'];

    fields.forEach((fieldName) => {
      dispatch({
        type: FORM_ACTIONS.TOGGLE_FORM_DATA,
        fieldName,
        payload: false,
      });
    });
  };

  const clearSymptomForm = () => {
    const fields = [
      'ibsConstipation',
      'ibsMixed',
      'ibsDiarrhea',
      'ibsUnspecified',
    ];

    fields.forEach((fieldName) => {
      dispatch({
        type: FORM_ACTIONS.TOGGLE_FORM_DATA,
        fieldName,
        payload: false,
      });
    });
  };

  const selectUlcerativeColitis = () => {
    if (!formData.hasUlcerativeColitis) {
      clearDiagnosisForm();
      dispatch({
        type: FORM_ACTIONS.TOGGLE_FORM_DATA,
        fieldName: 'hasUlcerativeColitis',
        payload: true,
      });
    } else {
      clearDiagnosisForm();
    }
  };

  const selectCrohnsDisease = () => {
    if (!formData.hasCrohnsDisease) {
      clearDiagnosisForm();
      dispatch({
        type: FORM_ACTIONS.TOGGLE_FORM_DATA,
        fieldName: 'hasCrohnsDisease',
        payload: true,
      });
    } else {
      clearDiagnosisForm();
    }
  };

  const selectIbsConstipation = () => {
    if (!formData.ibsConstipation) {
      clearSymptomForm();
      dispatch({
        type: FORM_ACTIONS.TOGGLE_FORM_DATA,
        fieldName: 'ibsConstipation',
        payload: true,
      });
    } else {
      clearSymptomForm();
    }
  };

  const selectIbsMixed = () => {
    if (!formData.ibsMixed) {
      clearSymptomForm();
      dispatch({
        type: FORM_ACTIONS.TOGGLE_FORM_DATA,
        fieldName: 'ibsMixed',
        payload: true,
      });
    } else {
      clearSymptomForm();
    }
  };

  const selectIbsDiarrhea = () => {
    if (!formData.ibsDiarrhea) {
      clearSymptomForm();
      dispatch({
        type: FORM_ACTIONS.TOGGLE_FORM_DATA,
        fieldName: 'ibsDiarrhea',
        payload: true,
      });
    } else {
      clearSymptomForm();
    }
  };

  const selectIbsUnspecified = () => {
    if (!formData.ibsUnspecified) {
      clearSymptomForm();
      dispatch({
        type: FORM_ACTIONS.TOGGLE_FORM_DATA,
        fieldName: 'ibsUnspecified',
        payload: true,
      });
    } else {
      clearSymptomForm();
    }
  };

  return (
    <VStack space={1} className="personal-info-container">
      <VStack mt={5} space={3} className="form-container">
        <VStack space={1} className="header">
          <Heading
            alignSelf={'start'}
            fontFamily="body"
            fontWeight={'300'}
            size="sm"
          >
            If applicable, specify your IBD. (You can only select one from this
            group)
          </Heading>
        </VStack>
        <Pressable
          onPress={() => {
            selectUlcerativeColitis();
          }}
        >
          {() => {
            return (
              <Box
                bg={
                  formData.hasUlcerativeColitis ? 'secondary.500' : 'light.50'
                }
                p="5"
                borderWidth="1"
                borderColor="muted.400"
                borderRadius="lg"
              >
                <Text
                  color={
                    formData.hasUlcerativeColitis ? 'light.50' : 'secondary.500'
                  }
                  fontWeight="medium"
                  fontSize="xl"
                >
                  Ulcerative Colitis
                </Text>
              </Box>
            );
          }}
        </Pressable>

        <Pressable
          onPress={() => {
            selectCrohnsDisease();
          }}
        >
          {() => {
            return (
              <Box
                bg={formData.hasCrohnsDisease ? 'secondary.500' : 'light.50'}
                p="5"
                borderWidth="1"
                borderColor="muted.400"
                borderRadius="lg"
              >
                <Text
                  color={
                    formData.hasCrohnsDisease ? 'light.50' : 'secondary.500'
                  }
                  fontWeight="medium"
                  fontSize="xl"
                >
                  Crohn's Disease
                </Text>
              </Box>
            );
          }}
        </Pressable>
      </VStack>

      <VStack mt={10} space={3} className="form-container">
        <VStack space={1} className="header">
          <Heading
            alignSelf={'start'}
            fontFamily="body"
            fontWeight={'300'}
            size="sm"
          >
            If applicable, specify your type of IBS. (You can only select one
            from this group)
          </Heading>
        </VStack>

        <Pressable
          onPress={() => {
            selectIbsDiarrhea();
          }}
        >
          {() => {
            return (
              <Box
                bg={formData.ibsDiarrhea ? 'secondary.500' : 'light.50'}
                p="5"
                borderWidth="1"
                borderColor="muted.400"
                borderRadius="lg"
              >
                <Text
                  color={formData.ibsDiarrhea ? 'light.50' : 'secondary.500'}
                  fontWeight="medium"
                  fontSize="xl"
                >
                  IBS - Diarrhea
                </Text>
              </Box>
            );
          }}
        </Pressable>

        <Pressable
          onPress={() => {
            selectIbsConstipation();
          }}
        >
          {() => {
            return (
              <Box
                bg={formData.ibsConstipation ? 'secondary.500' : 'light.50'}
                p="5"
                borderWidth="1"
                borderColor="muted.400"
                borderRadius="lg"
                style={{}}
              >
                <Text
                  color={
                    formData.ibsConstipation ? 'light.50' : 'secondary.500'
                  }
                  fontWeight="medium"
                  fontSize="xl"
                >
                  IBS - Constipation
                </Text>
              </Box>
            );
          }}
        </Pressable>

        <Pressable
          onPress={() => {
            selectIbsMixed();
          }}
        >
          {() => {
            return (
              <Box
                bg={formData.ibsMixed ? 'secondary.500' : 'light.50'}
                p="5"
                borderWidth="1"
                borderColor="muted.400"
                borderRadius="lg"
                style={{}}
              >
                <Text
                  color={formData.ibsMixed ? 'light.50' : 'secondary.500'}
                  fontWeight="medium"
                  fontSize="xl"
                >
                  IBS - Mixed
                </Text>
              </Box>
            );
          }}
        </Pressable>

        <Pressable
          onPress={() => {
            selectIbsUnspecified();
          }}
        >
          {() => {
            return (
              <Box
                bg={formData.ibsUnspecified ? 'secondary.500' : 'light.50'}
                p="5"
                borderWidth="1"
                borderColor="muted.400"
                borderRadius="lg"
                style={{}}
              >
                <Text
                  color={formData.ibsUnspecified ? 'light.50' : 'secondary.500'}
                  fontWeight="medium"
                  fontSize="xl"
                >
                  IBS - Unspecified
                </Text>
              </Box>
            );
          }}
        </Pressable>
      </VStack>
    </VStack>
  );
}

export default DiagnosisSelectionForm;
