import { Flex, Text } from "native-base";

function CustomSliderLabels({labels}) {
  const labelNumbers = ['0', '5', '10'];

  const alignEnds = (index) => {
    let style = {}
    if (index === 0){
        style = {
          transform: 'translateX(-50%)'
        }
    } else if (index === labels.length -1) {
        style = {
          transform: 'translateX(50%)'
        }
    }
    return style
  }
  return (
    <Flex direction="row" justify={'space-between'} w={'100%'} mt={2}>
      {labels.map((label, index) => {
        return (
          <Flex key={index} w={'33%'} style={alignEnds(index)}
              direction="column" alignItems={'center'}>
            <Text fontWeight={600} fontSize={15}>{labelNumbers[index]}</Text>
            <Text textAlign={'center'} maxW={{base:'81px', sm:'85px'}} fontWeight={600} fontSize={15}>{label}</Text>
          </Flex>
        )
      })}
    </Flex>
  )
}
export default CustomSliderLabels;