import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../hooks';
import { LearningHubSection, Section } from '../../models/LearningHubContent';
import { Phase } from '../../models/user';

type Props = {
  enable: boolean;
  section?: Section;
  phase?: Phase;
  userToken?: string;
};

const useLearningHubContent = ({
  section,
  phase,
  userToken,
  enable = false,
}: Props) => {
  const { api } = useApi();

  const getLearningHubContent = async (
    section?: string,
    phase?: string
  ): Promise<LearningHubSection> => {
    return await api({
      route: `/private/content/learning-hub/${section}${
        phase ? `?phase=${phase}` : ''
      }`,
      token: userToken,
    });
  };

  const { data, isLoading, isError } = useQuery<LearningHubSection, Error>(
    ['learning-hub-section', section, phase],
    () => getLearningHubContent(section, phase),
    { enabled: enable && !!userToken && !!section, refetchOnWindowFocus: false }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useLearningHubContent;
