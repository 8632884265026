import React from 'react';
import { ILinkProps, Link as NativeBaseLink } from 'native-base';

export const Link = (props: ILinkProps) => {
  return (
    <NativeBaseLink
      _hover={{
        _text: {
          _light: {
            color: 'primary.600',
          },
          color: 'primary.600',
        },
      }}
      _text={{
        color: 'primary.700',
        textTransform: 'none',
      }}
      href={props.href}
      isExternal
      {...props}
    >
      {props.children}
    </NativeBaseLink>
  );
};
