/* eslint-disable react-hooks/exhaustive-deps */
// TODO: Better dependency pattern to avoid ^
import React, { ReactNode, createContext, useContext, useReducer } from 'react';

export enum ModalType {
  EditFoodListModal = 'EditFoodListModal',
  DashboardInfoModal = 'DashboardInfoModal',
}

type State = {
  openedModal: ModalType | null;
  modalProps: any | null;
};

type Action =
  | { type: 'openModal'; modalId: ModalType; modalData: any }
  | { type: 'closeModal' };

type Dispatch = (action: Action) => void;

type ContextType = { state: State; dispatch: Dispatch } | undefined;
const ModalsContext = createContext<ContextType>(undefined);

function actionsReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'openModal': {
      return {
        ...state,
        openedModal: action.modalId,
        modalProps: action?.modalData,
      };
    }

    case 'closeModal': {
      return {
        ...state,
        openedModal: null,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${(action as any).type}`);
    }
  }
}

type Props = {
  children: ReactNode;
};

export function ModalsProvider({ children }: Props) {
  const initialState: State = {
    modalProps: null,
    openedModal: null,
  };
  const [state, dispatch] = useReducer(actionsReducer, initialState);
  const value = { state, dispatch };

  return (
    <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>
  );
}

export function useModalsContext() {
  const context = useContext(ModalsContext);

  if (!context) {
    throw new Error(
      'useModalContext must be used within an ModalsContextProvider'
    );
  }

  return context;
}
