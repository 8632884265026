import styled from '@emotion/styled';
import { Divider, HStack, Image } from 'native-base';
import { useAffiliate } from '../../api/affiliates/useAffiliate';

const ImgContainer = styled('span')`
  & > div > div {
    background-position: left;
  }
`;

type Props = {
  variant?: 'regular' | 'small';
};

export const CoBrandingLogo = ({ variant = 'regular' }: Props) => {
  const { affiliate } = useAffiliate();
  const { logo: affiliateLogo, utm_source: affiliateUtmSource } =
    affiliate || {};

  if (!affiliateLogo) return null;

  return (
    <HStack alignItems={'center'}>
      <Divider
        bgColor={'white'}
        height={'50%'}
        orientation={'vertical'}
        mx={variant === 'regular' ? 8 : 4}
        testID={'cobranding-divider'}
        thickness={1}
      />

      <ImgContainer>
        <Image
          alt={`${affiliateUtmSource} logo`}
          maxHeight={'50px'}
          resizeMode={'contain'}
          size={variant === 'regular' ? 'xl' : 'md'}
          source={{
            uri: affiliateLogo,
          }}
          testID={'cobranding-logo'}
        />
      </ImgContainer>
    </HStack>
  );
};
