import { useAuth } from './useAuth';
import { useApi } from './useApi';
import { useCallback } from 'react';

export const MAX_PHASE_DAYS = 21;

const formatScore = (score): string => {
  const rawScore = (score /= Math.pow(10, -1));
  return rawScore.toFixed(1);
};

// DISCLAIMER: use this hook when you are sure the userToken has been already instantiated
export const useFetchUserData = () => {
  const { api } = useApi();
  const { userToken } = useAuth();

  const getScore = useCallback(
    async ({ route, token }) => {
      const response = await api({
        route,
        token,
      });

      if (response) {
        const { data } = response;
        const todayScore = data.length > 0 ? data[0].ayble_score : 0;
        const overTimeScore =
          data.reduce((sum, dataPoint) => sum + dataPoint.ayble_score, 0) /
            data.length || 0;
        const overviewScore = data.map((dataPoint) =>
          formatScore(dataPoint.ayble_score)
        );
        const stressScore = data.map((dataPoint) => dataPoint.stress_score);

        return {
          overview_score: overviewScore,
          ayble_score: overviewScore.slice(0, MAX_PHASE_DAYS),
          over_time_score: formatScore(overTimeScore),
          overview_stress_score: stressScore,
          stress_score: stressScore.slice(0, MAX_PHASE_DAYS),
          today_score: formatScore(todayScore),
          // Also returning raw data oin case it is needed.
          rawData: data,
        };
      }
      return {
        ayble_score: [],
        over_time_score: '0',
        overview_score: [],
        overview_stress_score: [],
        stress_score: [],
        today_score: '0',
      };
    },
    [api]
  );

  const getScoreSummary = useCallback(
    async ({ route, token }) => {
      const response = await api({
        route,
        token,
      });

      if (response) {
        const scoreObj = response.ayble_score_min;
        const rawScore = scoreObj ? response.ayble_score_min : 0;
        const formattedScore = formatScore(rawScore);
        return { lowest_ayble_score: formattedScore };
      }
      return { lowest_ayble_score: '0' };
    },
    [api]
  );

  const getSelfAybleScores = useCallback(
    async (limit = 1000) => {
      return getScore({
        route: `/private/scores/?limit=${limit}`,
        token: userToken,
      });
    },
    [getScore, userToken]
  );

  const getSelfAybleScoreSummary = useCallback(async () => {
    return getScoreSummary({
      route: `/private/scores/summary`,
      token: userToken,
    });
  }, [getScoreSummary, userToken]);

  const getUserAybleScores = useCallback(
    async (userId, limit = 1000) => {
      return getScore({
        route: `/private/coach/users/${userId}/scores?limit=${limit}`,
        token: userToken,
      });
    },
    [getScore, userToken]
  );

  const getUserAybleScoreSummary = useCallback(
    async (userId) => {
      return getScoreSummary({
        route: `/private/coach/users/${userId}/scores/summary`,
        token: userToken,
      });
    },
    [getScoreSummary, userToken]
  );

  const getSelfCurrentSubPhase = useCallback(async () => {
    const response = await api({
      route: `/private/subphase/`,
      token: userToken,
    });

    if (response) {
      return { current_subphase: response };
    }
    return { current_subphase: null };
  }, [api, userToken]);

  const updateUnreadMessage = useCallback(
    async (userId, status) => {
      const response = await api({
        route: `/private/user/${userId}`,
        method: 'PUT',
        token: userToken,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Coach-Override': `${userId}`,
        },
        payload: JSON.stringify({ unread_message: status }),
      });
      if (response) {
        return { unread_message: response.unread_message };
      }
      return { unread_message: null };
    },
    [api, userToken]
  );

  return {
    getSelfAybleScores,
    getSelfAybleScoreSummary,
    getSelfCurrentSubPhase,
    getUserAybleScores,
    getUserAybleScoreSummary,
    updateUnreadMessage,
  };
};
