import React from 'react';
import { Outlet } from 'react-router-dom';
import { Text, useTheme, VStack } from 'native-base';
import HeaderNav from '../HeaderNav/HeaderNav';


export const HealthCoachDashboard = () => {
  const {
    colors: { muted },
  } = useTheme();

  return (
    <div
      className="health-coach-dashboard"
      style={{
        height: '100vh',
        backgroundColor: muted['50'],
      }}
    >
      <HeaderNav page={1} hideBack />

      <div
        style={{
          height: 'calc(100% - 64px)',
          margin: '0 auto',
          paddingLeft: '12px',
          paddingRight: '12px',
        }}
      >
      <VStack p={6} h={'100%'}>
        <Text color="black" fontSize="lg" fontWeight="normal">
          Please go to dashboard.ayblehealth.com
        </Text>
      </VStack>

      </div>
      <Outlet />
    </div>
  );
};
