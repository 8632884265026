import { useQuery } from '@tanstack/react-query';
import { useApi, useAuth } from '../../hooks';

type MindGutActivity = {
  duration: number;
  id: string;
  status: string;
  testing: boolean;
  title: string;
};

type MindGutWeek = {
  active: boolean;
  activities: MindGutActivity[];
  duration: number;
  learnings: any;
  num_completed: number;
  sort_order: number;
  subtitle: string;
  title: string;
  week_num_display: string;
};

type MindGutProgram = {
  program: string;
  program_complete: boolean;
  weeks: MindGutWeek[];
};

export const useMindGutProgram = (userId: string) => {
  const { api } = useApi();
  const { isAuthenticated, loading, userToken } = useAuth();

  const fetchUserMindGutProgram = async () => {
    const requestObj = {
      route: '/private/mind-gut/program/',
      token: userToken,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Coach-Override': userId,
      },
    };

    return await api(requestObj);
  };

  const { data, isLoading, error } = useQuery<MindGutProgram, Error>(
    ['hc-mind-gut-program', userToken, userId],
    fetchUserMindGutProgram,
    {
      enabled: isAuthenticated && !loading && !!userToken && !!userId,
      refetchOnWindowFocus: false,
    }
  );

  return { mindGutProgram: data, isLoading, error };
};
