export const MAX_CONTENT_WIDTH_PX = '1410px';

// Font sizes
export const HEADER_1_SIZE_PX = '48px';
export const HEADER_1_MOBILE_SIZE_PX = '30px';
export const HEADER_2_SIZE_PX = '40px';
export const HEADER_2_MOBILE_SIZE_PX = '26px';
export const HEADER_3_SIZE_PX = '30px';
export const HEADER_3_MOBILE_SIZE_PX = '24px';
export const HEADER_4_SIZE_PX = '26px';
export const HEADER_4_MOBILE_SIZE_PX = '18px';
export const BODY_1_SIZE_PX = '26px';
export const BODY_1_MOBILE_SIZE_PX = '18px';
export const BODY_2_SIZE_PX = '18px';
export const BODY_2_MOBILE_SIZE_PX = '14px';
export const BODY_3_SIZE_PX = '12px';
export const BODY_3_MOBILE_SIZE_PX = '10px';
