import { Box, HStack } from 'native-base';
import React, { useMemo } from 'react';
import { SeverityBadge } from './SeverityBadge';

type Props = {
  level: number;
};

export const SeverityLevel = ({ level }: Props) => {
  const levels = useMemo(
    () =>
      Array(level)
        .fill(null)
        .map((_, i) => i),
    [level]
  );

  return (
    <HStack alignItems={'flex-end'} justifyContent={'center'}>
      {levels.map((l) => (
        <Box key={l} pl={1}>
          <SeverityBadge level={l} />
        </Box>
      ))}
    </HStack>
  );
};
