import React from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from '@emotion/styled';
import { theme } from 'native-base';

const Wrapper = styled('div')`
  p,
  ol,
  ul,
  blockquote {
    margin: 0;
  }

  h3 {
    text-align: left;
  }

  blockquote {
    border-left: 4px solid ${theme.colors.primary['600']};
    padding: 0 16px;
  }

  a {
    color: ${theme.colors.primary['600']};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  pre {
    background-color: ${theme.colors.muted['200']};
    border-radius: 4px;
  }
`;

export const HtmlRenderer = ({ html }) => {
  const sanitizedHtml = sanitizeHtml(html);

  return <Wrapper dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};
