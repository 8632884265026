import { DietDisplay } from './DietDisplay';
import { SurveyDisplay } from './SurveyDisplay';
import { Box, Divider, HStack } from 'native-base';

export const DietAndUserMealDisplay = () => {
  return (
    <HStack mx={6}>
      <Box w={'45%'}>
        <DietDisplay readonly={true} />
      </Box>

      <Divider orientation={'vertical'} height={'100hv'} />

      <Box w={'55%'}>
        <SurveyDisplay />
      </Box>
    </HStack>
  );
};
