import { Box, Flex, Heading, Image, Text, VStack } from 'native-base';
import { HEADER_3_MOBILE_SIZE_PX, HEADER_3_SIZE_PX } from './constants';

const donutChartImg = './../donut_chart.png';

export const StatBox = () => {
  return (
    <VStack alignItems={'center'} pt={10} pb={16} w={'100%'}>
      <Flex
        alignItems={{ base: 'center', md: undefined }}
        flexDirection={{ base: 'column', md: 'row' }}
        bgColor={'blue.100'}
        maxW={{ base: '330px', md: '880px' }}
        py={6}
        px={{ base: 6, md: 12 }}
        rounded={'xl'}
        width={'100%'}
      >
        <Box px={6} pb={{ base: 12, md: 0 }}>
          <Image
            alt="92% of Ayble patients significantly improve their GI symptoms."
            resizeMethod={'scale'}
            resizeMode={'contain'}
            size={{ base: '192px', md: '256px' }}
            source={{
              uri: donutChartImg,
            }}
          />
        </Box>

        <VStack flex={1} justifyContent={'center'}>
          <VStack flex={1} justifyContent={'end'}>
            <Heading
              mb={{ base: 0, md: -3 }}
              textAlign={{ base: 'center', md: 'start' }}
              fontSize={{ base: HEADER_3_MOBILE_SIZE_PX, sm: HEADER_3_SIZE_PX }}
            >
              of Ayble patients significantly improve their GI symptoms.
            </Heading>
          </VStack>

          <VStack flex={1} justifyContent={'end'} py={{ base: 12, md: 0 }}>
            <Text
              pb={{ base: 0, md: 6 }}
              fontWeight={'normal'}
              textAlign={{ base: 'center', md: 'start' }}
            >
              * Source: Jactel, et. al 2021 and internal Ayble Health data.
              Note: Results are not guaranteed, your experience on Ayble may be
              different.
            </Text>
          </VStack>
        </VStack>
      </Flex>
    </VStack>
  );
};
