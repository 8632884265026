// maps to LearningHub Section enum on the backend
export enum Section {
  GI_ROADMAP = "gi-roadmap",
  OVERVIEW = "phase-overview",
  TIPS = "tracking-tips",
}

export type LearningHubScreen = {
  id: string;
  image_url?: string;
  pre_title?: string;
  title?: string;
  post_title?: string;
  text?: string;
  sub_text?: string;
  list_items?: string[];
  cta?: string;
}

export type LearningHubSection = {
  id: string;
  title?: string;
  screens?: LearningHubScreen[];
}
