import React from 'react';
import { Box, Center, Checkbox, Flex, Image, Text, VStack } from 'native-base';
import { SeverityDisplay } from './Severity';
import styled from '@emotion/styled';

const DEFAULT_IMAGE = '../../cutlery.png';

const PressableCard = styled('div')<{ editing: boolean }>`
  display: contents;
  height: 100%;

  &:hover {
    cursor: ${(p) => (p.editing ? 'pointer' : null)};
  }
`;

type Props = {
  active?: boolean;
  dashedBorder?: boolean;
  disableSeverityColor?: boolean;
  disableSeverityLabel?: boolean;
  editing?: boolean;
  foodId: string;
  foodLabel: string;
  imgURL?: string;
  onChange?: (foodId: string, checked: boolean) => void;
  selected?: boolean;
  severityLabel?: string;
  severityLevel?: number;
};

export const FoodCard = ({
  active = false,
  dashedBorder = false,
  disableSeverityColor = false,
  disableSeverityLabel = false,
  editing = false,
  foodId,
  foodLabel,
  imgURL = DEFAULT_IMAGE,
  onChange = () => null,
  selected,
  severityLabel = '',
  severityLevel = 0,
}: Props) => {
  return (
    <VStack
      mr={'20px'}
      rounded="md"
      w={'140px'}
      borderWidth={selected ? 3 : 1}
      borderColor={selected ? 'primary.700' : null}
      borderStyle={dashedBorder ? 'dashed' : 'solid'}
      h={'auto'}
    >
      <PressableCard
        editing={editing}
        onClick={() => {
          if (!editing) return;
          onChange(foodId, !selected);
        }}
      >
        {editing && (
          <Checkbox
            position={'absolute'}
            my={'auto'}
            top={3}
            left={3}
            justifyContent={'flex-end'}
            isChecked={selected}
            value={foodId}
            borderWidth={1}
            borderRadius={1}
            borderColor={'warmGray.900'}
          />
        )}

        <Box p={2} flex={1}>
          <Center>
            <VStack alignItems="center">
              <Flex w={'56px'} flexDir={'column'} my={4}>
                <Image
                  size="sm"
                  source={{
                    uri: imgURL,
                  }}
                  alt={`${foodLabel}-icon`}
                />
              </Flex>
              <Text textAlign={'center'}>{foodLabel}</Text>
            </VStack>
          </Center>
        </Box>

        <Box
          bgColor={
            disableSeverityColor
              ? 'transparent'
              : active
              ? 'primary.500'
              : 'muted.400'
          }
          borderBottomRadius={selected ? 3 : 5}
          borderTopStyle={dashedBorder ? 'dashed' : 'solid'}
          borderTopWidth={1}
          minH={'44px'}
          p={2}
          display={'flex'}
          justifyContent={'center'}
        >
          {!disableSeverityLabel && (
            <Center>
              <SeverityDisplay label={severityLabel} level={severityLevel} />
            </Center>
          )}
        </Box>
      </PressableCard>
    </VStack>
  );
};
