import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { animated, useSpring } from 'react-spring';
import styled from '@emotion/styled';
import {
  CloseIcon,
  Flex,
  HStack,
  Heading,
  Pressable,
  useTheme,
  VStack,
  Divider,
  Image,
} from 'native-base';

const RESIZE_HANDLE_CLASS = 'drawer-resize-handle';
const DRAWER_MIN_WIDTH_PX = 496;
const LOCAL_STORAGE_DRAWER_WIDTH_KEY = 'hc-drawer-width';

const iconStyle = {
  width: 32,
  height: 32,
};

const DrawerWrapper = styled(animated.div)`
  max-width: 100%;
`;

const ResizeHandle = styled('div')`
  background-color: ${(p) =>
    p.isResizing ? p.theme.colors.primary['600'] : p.theme.colors.muted['400']};
  border: none;
  cursor: col-resize;
  height: 100%;
  position: absolute;
  width: 2px;
  z-index: 99;
`;

export const Drawer = ({
  bgColor = null,
  children,
  onClose = () => null,
  header = '',
  showHeader = true,
}) => {
  const {
    colors: { muted },
  } = useTheme();
  const backBttn = './../chevron-left.png';
  const drawerRef = useRef();

  const [isVisible, setIsVisible] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [drawerWidthDelta, setDrawerWidthDelta] = useState(0);

  const initialWidth = localStorage.getItem(LOCAL_STORAGE_DRAWER_WIDTH_KEY)
    ? `${localStorage.getItem(LOCAL_STORAGE_DRAWER_WIDTH_KEY)}px`
    : '30%';

  const style = useSpring({
    backgroundColor: bgColor || muted['100'],
    borderLeft: `1px solid ${muted['400']}`,
    height: '100vh',
    minWidth: `${DRAWER_MIN_WIDTH_PX}px`,
    overflow: 'auto',
    position: 'fixed',
    right: isVisible ? 0 : -600,
    top: 0,
    width: isVisible ? null : initialWidth,
  });

  const readCurrentWidth = () =>
    parseInt(
      document.defaultView.getComputedStyle(drawerRef.current).width,
      10
    );

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    const currentWidth = Math.max(DRAWER_MIN_WIDTH_PX, readCurrentWidth());
    localStorage.setItem(LOCAL_STORAGE_DRAWER_WIDTH_KEY, currentWidth);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (!isResizing) return;

      setDrawerWidthDelta(
        mouseMoveEvent.clientX - drawerRef.current.getBoundingClientRect().left
      );
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    const currentWidth = readCurrentWidth();
    drawerRef.current.style.width = +currentWidth - drawerWidthDelta + 'px';
  }, [drawerWidthDelta]);

  useLayoutEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <DrawerWrapper
      style={style}
      ref={drawerRef}
      onMouseDown={(e) => {
        if (e.target.classList.contains(RESIZE_HANDLE_CLASS))
          e.preventDefault();
      }}
    >
      <ResizeHandle
        className={RESIZE_HANDLE_CLASS}
        isResizing={isResizing}
        onMouseDown={() => setIsResizing(true)}
      />

      <VStack>
        {showHeader && (
          <VStack
            backgroundColor={muted['100']}
            position="sticky"
            top={0}
            zIndex={11}
          >
            <Flex
              alignItems="start"
              flexDirection="row"
              justifyContent="space-between"
              p={6}
            >
              <HStack alignItems="center">
                <Pressable variant={'closer'} onPress={onClose} mr={2}>
                  <Image source={backBttn} style={iconStyle} />
                </Pressable>
                <Heading size="md">{header}</Heading>
              </HStack>
              <Pressable variant={'closer'} onPress={onClose}>
                <CloseIcon />
              </Pressable>
            </Flex>
            <Divider />
          </VStack>
        )}
        <VStack overflow="auto" p={showHeader ? 6 : null}>
          {children}
        </VStack>
      </VStack>
    </DrawerWrapper>
  );
};
