import { useState, useEffect } from 'react';
import {
  Heading,
  Text,
  Box,
  FormControl,
  Input,
  Spinner,
  Radio,
  Flex,
  Button,
} from 'native-base';
import { useNavigate } from 'react-router-dom';
import SignUpInfoLocation from '../OnboardingForm/SignUpInfoLocation';
import HeaderNav from '../HeaderNav/HeaderNav';
import SignUpInfoAffiliateLookup from '../OnboardingForm/SignUpInfoAffiliateLookup';
import { useAffiliate } from '../../api/affiliates/useAffiliate';
import { useUserRegisterPut } from '../../api/user/mutations/useUserRegisterPut';
import { useAuthenticatedUser } from '../../api/user/useAuthenticatedUser';

const UnsubscribedLanding = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [location, setLocation] = useState();
  const [affiliate, setAffiliate] = useState();
  const [affiliateSearchQuery, setAffiliateSearchQuery] = useState('');
  const [hasRequiredReferrer, setHasRequiredReferrer] = useState(false);

  const { mutation: updateUserRegisterMutation } = useUserRegisterPut();

  const { user: authenticatedUser, isLoading: isUserLoading } =
    useAuthenticatedUser();

  const {
    affiliate: requiredReferrerData,
    isInitialLoading: isRequiredAffiliateLoading,
  } = useAffiliate({
    affiliateId: affiliate?.required_referrer,
  });

  const {
    affiliate: existingReferrer,
    isInitialLoading: isExistingReferrerLoading,
  } = useAffiliate({
    affiliateId: authenticatedUser?.referrer,
  });

  const {
    affiliate: existingAffiliate,
    isInitialLoading: isExistingAffiliateLoading,
  } = useAffiliate({
    affiliateId: authenticatedUser?.affiliate,
  });

  // Effect for auto-populating affiliate search if they already had one from
  // initial registration flow
  useEffect(() => {
    if (!existingAffiliate) return;

    const { name, required_referrer, utm_source } = existingAffiliate;
    setAffiliate({ name, required_referrer, utm_source });
    setAffiliateSearchQuery(name);
    if (required_referrer) {
      if (required_referrer === authenticatedUser.referrer)
        setHasRequiredReferrer(true);
    }
  }, [existingAffiliate, authenticatedUser?.referrer]);

  // Effect to auto populate form fields. These can be changed, which is
  // why we need to take the React Query response and set it to local state
  useEffect(() => {
    if (!authenticatedUser) return;

    setFirstName(authenticatedUser.first_name);
    setLastName(authenticatedUser.last_name);
    setLocation({
      value: authenticatedUser.address_state,
      label: authenticatedUser.address_state,
    });
  }, [authenticatedUser]);

  const onSubmit = async () => {
    const getReferrer = () => {
      // if there isn't a required_referrer, return what was on the user (or null)
      if (!affiliate?.required_referrer)
        return existingReferrer?.utm_source || null;

      // if they selected "yes" to the required referrer prompt, return that utm_source
      if (hasRequiredReferrer) {
        return requiredReferrerData.utm_source;
      } else {
        // if they selected no, and had the required referrer on their user, null it out
        if (affiliate?.required_referrer === authenticatedUser?.referrer) {
          return null;
        }

        // otherwise, return their existing referrer data (or null)
        return existingReferrer?.utm_source || null;
      }
    };

    try {
      const resp = await updateUserRegisterMutation.mutateAsync({
        first_name: firstName,
        last_name: lastName,
        address_state: location.value,
        affiliate: affiliate?.utm_source || null,
        referrer: getReferrer(),
      });

      if (resp.check_eligible) {
        navigate(`/eligibility`, {
          state: {
            affiliate: affiliate?.utm_source,
            referrer: authenticatedUser?.referrer,
            userId: authenticatedUser.id,
            userEmail: authenticatedUser.email,
            firstName,
            lastName,
          },
        });
      } else {
        navigate(`/plans`, {
          state: {
            affiliate: affiliate?.utm_source,
            referrer: authenticatedUser?.referrer,
            userId: authenticatedUser.id,
            userEmail: authenticatedUser.email,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (
    isUserLoading ||
    updateUserRegisterMutation.isLoading ||
    isExistingReferrerLoading ||
    isExistingAffiliateLoading
  )
    return <Spinner />;

  return (
    <>
      <HeaderNav page={1} hideBack />
      <Heading fontSize={'3xl'} mt={10}>
        Welcome Back!
      </Heading>
      <Box my={8} w={'45%'} mx={'auto'}>
        <Text fontSize={'2xl'} mb={6}>
          To finish registering for Ayble, please verify the information on this
          form.
        </Text>
        <Text fontSize={'2xl'}>
          After that, you will have the ability to choose one of our low cost
          plans, or see if you’re eligible for coverage through your employer or
          insurance.
        </Text>
        <FormControl mt={8} mx="auto">
          <FormControl.Label>Email address</FormControl.Label>
          <Input
            type="text"
            value={authenticatedUser?.email}
            isDisabled={true}
          />

          <FormControl.Label>First Name</FormControl.Label>
          <Input type="text" value={firstName} />

          <FormControl.Label>Last Name</FormControl.Label>
          <Input type="text" value={lastName} />

          <SignUpInfoLocation
            defaultValue={{
              value: authenticatedUser.address_state,
              label: authenticatedUser.address_state,
            }}
            dispatch={(selection) =>
              setLocation({
                value: selection.payload,
                label: selection.payload,
              })
            }
          />

          <Box mt={4}>
            <SignUpInfoAffiliateLookup
              affiliateName={affiliateSearchQuery}
              selectedAffiliate={affiliate?.utm_source}
              onAffilSelection={(selection) => {
                setAffiliate(selection);
                setAffiliateSearchQuery(selection.name);
              }}
              onClear={() => {
                setAffiliateSearchQuery('');
                setAffiliate(null);
              }}
              onFieldChange={(e) => {
                setAffiliate(null);
                setAffiliateSearchQuery(e);
              }}
            />

            {affiliate?.required_referrer && !isRequiredAffiliateLoading && (
              <FormControl>
                <FormControl.Label
                  style={{
                    marginBottom: 8,
                    marginTop: 16,
                  }}
                >
                  Have you ever been a patient at {requiredReferrerData?.name}?
                </FormControl.Label>
                <Radio.Group
                  name="requiredReferrer"
                  accessibilityLabel={`Have you ever been a patient at ${requiredReferrerData?.name}?`}
                  value={hasRequiredReferrer}
                  onChange={(e) => {
                    setHasRequiredReferrer(e);
                  }}
                >
                  <Flex flexDir={'row'} gap="10px">
                    <Radio size={'sm'} value={true}>
                      Yes
                    </Radio>
                    <Radio size="sm" value={false}>
                      No
                    </Radio>
                  </Flex>
                </Radio.Group>
              </FormControl>
            )}
          </Box>
          <Button
            zIndex={-1}
            w={'40%'}
            mt={6}
            isDisabled={isRequiredAffiliateLoading}
            onPress={onSubmit}
          >
            Continue
          </Button>
        </FormControl>
      </Box>
    </>
  );
};

export default UnsubscribedLanding;
