import { VStack, Pressable, Text, Box, Center, Heading } from 'native-base';
import { FORM_ACTIONS } from '../../../utils/formActions.js';

function Sex({ formData, dispatch }) {
  const styles = {
    formButtons: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
    },
  };
  const updateSex = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: `sex`,
      payload: data,
    });
  };

  const SexArray = ['Female', 'Male', 'Intersex'];

  return (
    <Center testID={'onboarding-sex'}>
      <VStack space={1} className="personal-info-container">
        <Heading fontSize={'3xl'} mb={'2'}>
          What sex were you assigned at birth?
        </Heading>
        <Heading fontFamily="body" fontWeight={'300'} size={'md'} mb={'4'}>
          We ask this because our research shows that women and men metabolize
          foods differently.
        </Heading>
        <VStack>
          {SexArray.map((item, index) => {
            return (
              <Pressable
                style={styles.formButtons}
                key={index}
                onPress={() => updateSex(item)}
                testID={'onboarding-sex-item'}
              >
                <Box
                  bg={formData['sex'] === item ? 'secondary.500' : 'light.50'}
                  borderWidth="1"
                  borderColor="muted.400"
                  borderRadius="lg"
                  p="5"
                >
                  <Text
                    color={
                      formData['sex'] === item ? 'light.50' : 'secondary.500'
                    }
                    fontWeight="medium"
                    fontSize="xl"
                  >
                    {item}
                  </Text>
                </Box>
              </Pressable>
            );
          })}
        </VStack>
      </VStack>
    </Center>
  );
}

export default Sex;
