import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  InfoIcon,
  Text,
  View,
  VStack,
} from 'native-base';
import { FoodCard } from '../../ui/FoodCard';
import React, { useEffect, useState } from 'react';
import { IconButton } from '../../Form/IconButton';
import { PencilIcon } from '../../../icons/PencilIcon';
import { useParams } from 'react-router-dom';
import { Loader } from '../../Loader';
import { useUpdateSuspectedFoods } from '../../../api/healthCoach/mutations/useUpdateSuspectedFoods';
import { Food } from '../../../api/healthCoach/useSuspectedFoods';
import { getImage } from './util';

const heatLevelMap = {
  unlikely: 1,
  low: 2,
  medium: 3,
  high: 4,
};

export const SuspectedFoods = ({
  error = '',
  isLoading = false,
  suspectedFoods = [],
}: {
  error: string;
  isLoading: boolean;
  suspectedFoods: Food[];
}) => {
  const { patient } = useParams();
  const { mutation } = useUpdateSuspectedFoods(patient ?? '');
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFoods, setSelectedFoods] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const canAddFood = selectedFoods.length < 5;

  const handleFoodSelection = (foodId: string, checked: boolean) => {
    if (checked && canAddFood) {
      return setSelectedFoods([...selectedFoods, foodId]);
    }

    if (checked && !canAddFood) {
      setErrorMsg('You can only select 5 foods.');
      return;
    }

    const unCheckedFoodIndex = selectedFoods.indexOf(foodId);
    return setSelectedFoods([
      ...selectedFoods.slice(0, unCheckedFoodIndex),
      ...selectedFoods.slice(unCheckedFoodIndex + 1),
    ]);
  };

  useEffect(() => {
    if (!isLoading && !!error) {
      setErrorMsg(
        'Error while loading the suspected foods. Please try again later ot contact support.'
      );
    }
  }, [error, isLoading]);

  useEffect(() => {
    // reset error message
    if (!error && !!errorMsg && selectedFoods.length < 5) setErrorMsg('');
  }, [errorMsg, error, selectedFoods.length]);

  if (isLoading) {
    return (
      <Box backgroundColor="muted.100" p={0}>
        <Loader loading={true} />
      </Box>
    );
  }

  return (
    <VStack>
      <Flex alignItems={'center'} flexDir={'row'}>
        <Heading size={'md'} fontWeight={'normal'} textAlign="left">
          Suspect Foods
        </Heading>

        {!isEditing && (
          <IconButton
            onClick={() => {
              setIsEditing(() => !isEditing);
            }}
          >
            <PencilIcon />
          </IconButton>
        )}

        {isEditing && (
          <HStack h={'24px'} alignItems={'center'}>
            <Button
              isDisabled={mutation.isLoading}
              h={'20px'}
              minH={'16px'}
              ml={2}
              onPress={() => {
                setIsEditing(false);
                setSelectedFoods([]);
              }}
              rounded={3}
              size="xs"
              variant={'outline'}
              w={'16px'}
            >
              Cancel
            </Button>

            <Button
              isDisabled={mutation.isLoading || selectedFoods.length !== 5}
              h={'20px'}
              isLoading={mutation.isLoading}
              minH={'16px'}
              ml={2}
              onPress={async () => {
                await mutation.mutateAsync(selectedFoods);
                setIsEditing(false);
                setSelectedFoods([]);
              }}
              rounded={3}
              size="xs"
              w={'16px'}
            >
              Save
            </Button>
          </HStack>
        )}
      </Flex>

      <Box h={'32px'}>
        {isEditing && !errorMsg && canAddFood && (
          <HStack alignItems={'center'} pt={1}>
            <InfoIcon color={'info.600'} mr={1} />
            <Text>Select 5 foods to save</Text>
          </HStack>
        )}

        {errorMsg && <Text color={'red.500'}>{errorMsg}</Text>}
      </Box>

      <View>
        <Flex flexDir={'row'} overflow={'scroll'} pb={3}>
          {suspectedFoods?.map(
            ({ heat, image_url, name, potential_trigger }, index) => (
              <FoodCard
                key={index}
                active={potential_trigger}
                editing={isEditing}
                foodId={name}
                foodLabel={name}
                imgURL={getImage(image_url)}
                onChange={handleFoodSelection}
                selected={selectedFoods.includes(name)}
                severityLabel={heat}
                severityLevel={heatLevelMap[heat]}
              />
            )
          )}
        </Flex>

        <HStack alignItems={'center'}>
          <Text italic>
            From left to right, discover the highest-scoring food to the lowest
            →
          </Text>
        </HStack>
      </View>
    </VStack>
  );
};
