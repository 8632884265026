import { useApi, useAuth } from '../../hooks';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Diagnosis } from '../types';

export const useDiagnosisSelect = () => {
  const { api } = useApi();
  const { userToken } = useAuth();

  const fetchDiagnoses = async (): Promise<Diagnosis[]> => {
    const requestObj = {
      route: '/private/diagnosis/',
      token: userToken,
    };

    return await api(requestObj);
  };

  const { data: diagnoses } = useQuery<Diagnosis[], Error>(
    ['hc-diagnosis-select', userToken],
    () => fetchDiagnoses(),
    {
      enabled: !!userToken,
      refetchOnWindowFocus: false,
    }
  );

  const loadDiagnosisOptions = useCallback(
    async (inputValue) => {
      if (!diagnoses) return [];

      const selectOpts = diagnoses.map(({ id, diagnosis }) => ({
        label: diagnosis,
        value: id,
      }));
      return selectOpts.filter((o) =>
        o.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    },
    [diagnoses]
  );

  return { loadDiagnosisOptions };
};
