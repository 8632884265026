import React from 'react';
import IframeResizer from '@iframe-resizer/react';
export const IFrame = ({ src }: { src: string }) => {
  return (
    <IframeResizer
      license={'GPLv3'}
      src={src}
      style={{ width: '100%', height: '100vh' }}
      frameBorder="0"
    />
  );
};
