import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Card } from 'native-base';

import { PublicRoute } from './components/PublicRoute';
import { ProtectedRoute } from './components/ProtectedRoute';
import Form from './components/OnboardingForm/Form';
import ThankYou from './components/ThankYouView/ThankYou';
import Plans from './components/PlansView/Plans';
import Eligibility from './components/EligibilityView/Eligibility';
import { HealthCoachDashboard } from './components/HealthCoachDashboard/HealthCoachDashboard';
import { useAuth } from './hooks';
import { useModal } from './hooks/useModal';
import { UserDetailsRoute } from './components/UserDashboard/UserDetailsRoute';
import ContentPreview from './components/ContentPreview/ContentPreview';
import UnsubscribedLanding from './components/UnsubscribedLanding/UnsubscribedLanding';
import PhoneNumber from './components/PhoneNumberView/PhoneNumber';

const Main = () => {
  const { user, userData } = useAuth();

  return (
    <>
      <div
        className="background-container"
        style={{ overflowX: 'hidden' }}
      ></div>

      <Form />

      {user && (
        <Card>
          <pre>{JSON.stringify(user, null, 2)}</pre>
        </Card>
      )}

      {userData && (
        <Card>
          <pre>{JSON.stringify(userData, null, 2)}</pre>
        </Card>
      )}
    </>
  );
};

export const Root = () => {
  const modalToRender = useModal();

  return (
    <>
      {modalToRender}

      <Router>
        <Routes>
          <Route
            path="/hc-dashboard"
            element={
              <ProtectedRoute>
                <HealthCoachDashboard />
              </ProtectedRoute>
            }
          />

          <Route path="/content-preview" element={<ContentPreview />} />

          <Route
            path="/user-details/:patient/*"
            element={
              <ProtectedRoute>
                <UserDetailsRoute />
              </ProtectedRoute>
            }
          />

          <Route path="/onboarding" element={<Form initialPage={1} />} />

          <Route path="/thank-you" element={<ThankYou />} />

          <Route path="/eligibility" element={<Eligibility />} />

          <Route path="/phone" element={<PhoneNumber />} />

          <Route
            path="/unsubscribed-landing"
            element={
              <ProtectedRoute>
                <UnsubscribedLanding />
              </ProtectedRoute>
            }
          />

          <Route path="/plans" element={<Plans />} />

          <Route
            path="/"
            element={
              <PublicRoute>
                <Main />
              </PublicRoute>
            }
          />

          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </Router>
    </>
  );
};
