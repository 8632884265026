import {
  HStack,
  VStack,
  Box,
  Image,
  Text,
  useBreakpointValue,
} from 'native-base';

const star = '../../star.png';

type TestimonialProps = {
  testimonial: {
    image: string;
    title: string;
    body: string;
    bio: string;
    numStars: number;
    diagnosis: string;
  };
};

export function TestimonialCard({ testimonial }: TestimonialProps) {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });
  const { image, title, body, bio, numStars, diagnosis } = testimonial;

  return (
    <Box
      bg="white"
      borderColor={'#C4B5A6'}
      borderWidth={1}
      py={10}
      rounded="md"
    >
      <HStack
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'center' : 'flex-start'}
        space={3}
        justifyContent={'space-evenly'}
      >
        <Image
          source={{ uri: image }}
          alt="Image of Ayble User"
          height={174}
          width={179}
          marginBottom={isMobile ? 10 : 'auto'}
        />
        <VStack
          justifyContent="space-evenly"
          flexShrink={1}
          w={isMobile ? '85%' : '60%'}
        >
          <Text
            fontSize="24px"
            lineHeight="30px"
            fontWeight="500"
            color={'secondary.500'}
            mb={4}
          >
            {title}
          </Text>
          <Text fontSize={'20px'} lineHeight={'30px'}>
            {body}
          </Text>
          <HStack
            flexDir={isMobile ? 'column' : 'row'}
            alignItems={'center'}
            space={6}
            mt={5}
          >
            <HStack space={3}>
              {Array.from({ length: numStars }).map((_, index) => {
                return (
                  <Image
                    key={`star-${index}`}
                    source={{ uri: star }}
                    alt="rating star"
                    w={25}
                    h={25}
                  />
                );
              })}
            </HStack>
            <Text my={isMobile ? 5 : 'auto'}>{bio}</Text>
            <Box backgroundColor={'#C9C6BA'} rounded={'full'}>
              <Text
                px={5}
                py={2}
                color={'secondary.500'}
                fontWeight={500}
                textTransform={'uppercase'}
              >
                {diagnosis}
              </Text>
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
}

export default TestimonialCard;
