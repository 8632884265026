import { Image, VStack } from 'native-base';
import { BulletList } from './BulletList';

const gif = '../../screens-carousel.gif';

export const MobileInfoItems = () => {
  return (
    <VStack pt={6} alignItems="center">
      <Image
        resizeMode={'contain'}
        flex={1}
        height={470}
        width={300}
        source={{
          uri: gif,
        }}
        alt={'Food screen'}
      />

      <BulletList highlightedBulletIndex={10} />
    </VStack>
  );
};
