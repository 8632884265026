import { Section } from '../../models/LearningHubContent';
import { Phase } from '../../models/user';

// maps to sections we expect to see in the LearningHub
// section + phase are params to be used in the query
export const learningHubContentSections = [
  // { section: Section.GI_ROADMAP },
  { section: Section.OVERVIEW, phase: Phase.One },
  { section: Section.OVERVIEW, phase: Phase.Two },
  { section: Section.OVERVIEW, phase: Phase.Three },
  { section: Section.OVERVIEW, phase: Phase.Four },
  { section: Section.TIPS, phase: Phase.One },
  { section: Section.TIPS, phase: Phase.Two },
  { section: Section.TIPS, phase: Phase.Three },
  { section: Section.TIPS, phase: Phase.Four },
];

export type LearningHubSectionParams = typeof learningHubContentSections[number];


// TODO: uncomment when building out mind gut previews
// export const mindGutContentOptions = []

export enum Product {
  learningHub  = 'learning-hub',
  mindGut = 'mind-gut'
}

export const contentPreviewSections = {
  [Product.learningHub]: {
    title: 'Learning Hub',
    label: Product.learningHub,
    sections: learningHubContentSections
  },
  [Product.mindGut]: {
    title: 'Mind Gut',
    label: Product.mindGut,
  },
} as const
