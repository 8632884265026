import {
  VStack,
  Heading,
  Pressable,
  Box,
  Text,
  Spinner,
  Center,
  Button,
} from 'native-base';
import { useFormData } from '../../utils/formReducer.js';
import { FORM_ACTIONS } from '../../utils/formActions.js';
import { useSymptom } from '../../api/onboarding/useSymptom';
import { useGoogleTagManager } from '../../hooks/useGoogleTagManager';
import { useEffect } from 'react';
import { TRACKING_ONBOARDING_STARTED } from './constants';

const ButtonWrapper = ({ symptom, fontSize, isSelected }) => {
  return (
    <Box
      bg={isSelected ? 'secondary.500' : 'light.50'}
      p="5"
      borderWidth="1"
      borderColor="muted.400"
      borderRadius="lg"
    >
      <Text
        color={isSelected ? 'light.50' : 'secondary.500'}
        fontWeight="medium"
        fontSize={fontSize}
      >
        {symptom.symptom}
      </Text>
    </Box>
  );
};

const GiSymptomsList = ({ onNext }) => {
  const { state: formData, dispatch } = useFormData();
  const { trackEventInGoogleTagManager } = useGoogleTagManager();

  const { data, isLoading } = useSymptom();

  useEffect(() => {
    trackEventInGoogleTagManager(TRACKING_ONBOARDING_STARTED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Spinner />;

  const primarySymptoms = data?.filter(
    (symptom) => symptom.category === 'Primary'
  );

  const secondaryGiSymptoms = data?.filter(
    (symptom) => symptom.category === 'Secondary'
  );

  const handleSelect = (symptomId) => {
    let symptomCopy = [...formData.symptom_ids];
    if (!symptomCopy.includes(symptomId)) {
      symptomCopy.push(symptomId);
    } else {
      symptomCopy = symptomCopy.filter((id) => id !== symptomId);
    }

    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'symptom_ids',
      payload: symptomCopy,
    });
  };

  return (
    <VStack maxWidth={'100%'} space={1} testID={'onboarding-gi-symptoms-list'}>
      <VStack mb={6}>
        <Heading fontSize={'3xl'}>What symptoms are you experiencing?</Heading>
      </VStack>
      <VStack space={1}>
        <Text fontSize={'lg'} mb={3} fontWeight={400}>
          Select all that apply:
        </Text>
        {primarySymptoms?.map((symptom) => {
          return (
            <Pressable
              key={symptom.id}
              my={2}
              onPress={() => handleSelect(symptom.id)}
            >
              <ButtonWrapper
                symptom={symptom}
                fontSize={'xl'}
                isSelected={formData.symptom_ids.includes(symptom.id)}
              />
            </Pressable>
          );
        })}

        <Text fontSize={'lg'} mt={12} mb={3} fontWeight={400}>
          We can also help you manage the below symptoms. Select all that apply:
        </Text>
        <Box flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          {secondaryGiSymptoms?.map((symptom) => {
            return (
              <Pressable
                key={symptom.id}
                my={2}
                w={'48%'}
                onPress={() => handleSelect(symptom.id)}
              >
                <ButtonWrapper
                  symptom={symptom}
                  fontSize={'md'}
                  isSelected={formData.symptom_ids.includes(symptom.id)}
                />
              </Pressable>
            );
          })}
        </Box>
      </VStack>
      <Center>
        <Button mt={6} minWidth={'300px'} size={'lg'} onPress={onNext}>
          Next
        </Button>
      </Center>
    </VStack>
  );
};

export default GiSymptomsList;
