import styled from '@emotion/styled';
import { ITheme, Text } from 'native-base';
import { useAffiliate } from '../../api/affiliates/useAffiliate';

const Link = styled('a')<{ theme?: ITheme }>`
  color: white;
  text-decoration: underline;
  transition: color linear 200ms;

  &:hover {
    color: ${(p) => p.theme.colors.primary['600']};
    cursor: pointer;
  }
`;

export const FaqLink = () => {
  const { affiliate: fetchedAffiliate } = useAffiliate();

  if (!fetchedAffiliate?.faq_link) return null;

  return (
    <Text color={'white'} fontSize={'lg'} fontWeight={'medium'} pl={3} pt={6}>
      Questions? Read Ayble Health's{' '}
      <Link href={fetchedAffiliate?.faq_link} target={'_blank'}>
        FAQ
      </Link>
    </Text>
  );
};
