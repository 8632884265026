import React from 'react';
import { useParams } from 'react-router-dom';
import { Drawer } from '../Drawer';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';
import { Loader } from '../Loader';
import { CelebrationMain } from '../Celebration/CelebrationMain';

export const PhaseDrawer = ({ onClose }) => {
  const { phase } = useParams();
  // we convert the phase read from the URL (which is a string) into a number
  // representing the id of the phase. We do that because when comparing a certain
  // phase with strict equal operator (===) if they are not of the same type
  // the check would fail even if they would have the same value.
  const phaseId = +phase;

  const {
    state: { loading, user },
  } = useDashboardPageState();

  if (loading) return <Loader loading={loading} />;

  const { first_name, last_name } = user;

  return (
    <Drawer onClose={onClose} header={`${first_name} ${last_name[0]}.`}>
      <CelebrationMain selectedPhase={phaseId} user={user} />
    </Drawer>
  );
};
