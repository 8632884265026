export enum DiagnosisCategory {
  IBD = 'IBD',
  IBS = 'IBS',
  Other = 'Other',
}

export type Diagnosis = {
  id: string;
  diagnosis: string;
  category?: DiagnosisCategory;
};
