import { useState, useEffect } from 'react';
import { HStack, VStack, Heading } from 'native-base';
import { useNavigate } from 'react-router-dom';
import { contentPreviewSections, Product } from './constants';
import MindGutContentPreview from './MindGutContentPreview';
import LearningHubContentPreview from './LearningHubContentPreview';
import PreviewSelector from './PreviewSelector';
import { useAuth } from '../../hooks';

export type ActiveSection = typeof contentPreviewSections[Product];

const ContentPreview = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const [activeProduct, setActiveProduct] = useState<Product>(
    Product.learningHub
  );
  const [activeSection, setActiveSection] = useState<number | null>(null);

  if (!user?.id && !loading) {
    navigate('/');
  }

  useEffect(() => {
    setActiveSection(null);
  }, [activeProduct]);

  return (
    <VStack maxW={'100%'} flexWrap={'wrap'}>
      <Heading my={2}>Content Preview</Heading>
      <HStack>
        <PreviewSelector
          activeProduct={activeProduct}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          setActiveProduct={setActiveProduct}
        />
        <VStack px={4} mt={0} flexShrink={1} w={'100%'}>
          <Heading size="md" my={2} w={'100%'}>
            Preview
          </Heading>
          {activeProduct === Product.mindGut && <MindGutContentPreview />}
          {activeProduct === Product.learningHub && (
            <LearningHubContentPreview activeSection={activeSection} />
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};

export default ContentPreview;
