export const isYesGiConditionOnlyForOtherDiagnosis = (formData) => {
  const {
    diagnosis_ids,
    hasCrohnsDisease,
    hasUlcerativeColitis,
    ibsConstipation,
    ibsDiarrhea,
    ibsMixed,
    ibsUnspecified,
    yesDiagnosedGiCondition,
  } = formData;

  return (
    yesDiagnosedGiCondition &&
    !!diagnosis_ids.length &&
    !hasCrohnsDisease &&
    !hasUlcerativeColitis &&
    !ibsConstipation &&
    !ibsDiarrhea &&
    !ibsMixed &&
    !ibsUnspecified
  );
};

export const shuffleArray = <T>(array: T[]) => {
  const mutableArray = [...array];

  for (let i = mutableArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [mutableArray[i], mutableArray[j]] = [mutableArray[j], mutableArray[i]];
  }
  return mutableArray;
};
