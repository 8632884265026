import { useApi } from '../../hooks';
import { useQuery } from '@tanstack/react-query';

interface Symptom {
  id: string;
  symptom: string;
  category: 'Primary' | 'Secondary';
}

type SymptomResponse = Symptom[];

export const useSymptom = () => {
  const { api } = useApi();

  const fetchSymptoms = async (): Promise<SymptomResponse> => {
    return api({
      route: `/symptom/`,
    });
  };

  const { data, isLoading, isError } = useQuery<SymptomResponse, Error>(
    ['onboarding-symptom'],
    fetchSymptoms
  );

  return {
    isLoading,
    isError,
    data,
  };
};
