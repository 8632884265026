import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import { AffiliateType } from '../../models/affiliate';
import styled from '@emotion/styled';
import {
  BODY_1_MOBILE_SIZE_PX,
  BODY_1_SIZE_PX,
  BODY_2_MOBILE_SIZE_PX,
  BODY_2_SIZE_PX,
  HEADER_2_MOBILE_SIZE_PX,
  HEADER_2_SIZE_PX,
  MAX_CONTENT_WIDTH_PX,
} from './constants';

const evidenceImgUrl = '../../app_screens_sketch.png';

const ImgContainer = styled('div')`
  flex: 1;
  width: 100%;
`;

const NoWrap = styled('span')`
  text-wrap: nowrap;
`;

// map of key-value pairs where the key is an affiliate type,
// and the value is a function returning the header and the subheader message elements.
type AffiliateTypeGenContentFnMap = Record<
  AffiliateType,
  (affiliateName: string | undefined) => {
    body: React.ReactNode;
    subheader: React.ReactNode;
  }
>;

const contentAffiliateTypeMap: AffiliateTypeGenContentFnMap = {
  [AffiliateType.employer]: (affiliateName: string | undefined) => ({
    body: (
      <>
        Specialist consultations for digestive health can cost up to $300 per
        session. With Ayble Health, you can pay $0 for digestive care paid for
        by {affiliateName}.
      </>
    ),
    subheader: <>Your partner in digestive wellness</>,
  }),
  [AffiliateType.healthPlan]: () => ({
    body: (
      <>
        Specialist consultations for digestive health can cost up to $300 per
        session without insurance. Most Ayble members pay $0 for their care
        through their health insurance. If copays apply, members typically pay
        less than $20/mo.
      </>
    ),
    subheader: (
      <>
        Your partner in digestive wellness,
        <br />
        covered by insurance
      </>
    ),
  }),
  [AffiliateType.healthSystem]: () => ({
    body: (
      <>
        GI specialist consultations can cost up to $300 per session without
        insurance. With Ayble Health, you’re likely to pay less than $20/month.
        In fact, most Ayble Health members pay $0 for their care through their
        health insurance.
      </>
    ),
    subheader: (
      <>
        Your partner in digestive wellness,
        <br />
        covered by insurance
      </>
    ),
  }),
};

type Props = {
  onButtonClick: () => void;
};

export const Claim = ({ onButtonClick }: Props) => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const genFn = fetchedAffiliate?.affiliate_type
    ? contentAffiliateTypeMap[fetchedAffiliate.affiliate_type]
    : contentAffiliateTypeMap[AffiliateType.healthPlan];
  const { body, subheader } = genFn(fetchedAffiliate?.name);

  return (
    <VStack alignItems={'center'} bgColor={'muted.100'}>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        maxW={MAX_CONTENT_WIDTH_PX}
        p={8}
        px={{ base: 8, lg: 24 }}
        width={'100%'}
      >
        <VStack alignItems={isMobile ? 'start' : 'start'} flex={1}>
          <VStack maxW={'496px'}>
            <Heading
              color={'black'}
              fontSize={{ base: HEADER_2_MOBILE_SIZE_PX, sm: HEADER_2_SIZE_PX }}
              textAlign={isMobile ? 'center' : 'left'}
            >
              <NoWrap>We Make It Affordable</NoWrap>
            </Heading>

            {isMobile && (
              <Image
                alignSelf={'center'}
                resizeMode={'contain'}
                flex={1}
                w={{ base: 310, md: 500 }}
                h={{ base: 250, md: 350 }}
                source={{
                  uri: evidenceImgUrl,
                }}
                alt={'Food screen'}
              />
            )}
            <Text
              textAlign={'left'}
              color={'black'}
              fontSize={{ base: BODY_1_MOBILE_SIZE_PX, sm: BODY_1_SIZE_PX }}
              fontWeight={'medium'}
              pt={isMobile ? 0 : 3}
            >
              {subheader}
            </Text>

            <Text
              color={'black'}
              fontSize={{ base: BODY_2_MOBILE_SIZE_PX, sm: BODY_2_SIZE_PX }}
              fontWeight={'normal'}
              pt={6}
            >
              {body}
            </Text>

            <Button
              _text={{ color: 'secondary.900', textTransform: 'none' }}
              alignSelf={{ base: 'center', md: 'start' }}
              height={{ base: undefined, sm: '72px' }}
              mt={12}
              minW={'300px'}
              onPress={onButtonClick}
              rounded={'72px'}
              size={{ base: 'sm', sm: 'md' }}
              w={'40%'}
            >
              <Text
                color={'secondary.500'}
                fontSize={{ base: undefined, sm: 'xl' }}
                fontWeight={'bold'}
              >
                TRY AYBLE FOR FREE
              </Text>
            </Button>
          </VStack>
        </VStack>

        {!isMobile && (
          <VStack alignItems={'flex-start'} flex={1}>
            <ImgContainer>
              <Image
                alt={'Food screen'}
                flex={1}
                height={'450px'}
                resizeMode={'contain'}
                source={{
                  uri: evidenceImgUrl,
                }}
              />
            </ImgContainer>
          </VStack>
        )}
      </Flex>
    </VStack>
  );
};
