import { HStack, Text } from 'native-base';
import React from 'react';
import { generateDateStringByTimestamp } from './utils';

export const PhaseDateRangeDisplay = ({ phaseSummary }) => {
  const start = generateDateStringByTimestamp(phaseSummary?.start_date);
  const end = generateDateStringByTimestamp(phaseSummary?.end_date);

  return (
    <HStack pb={1}>
      {!!start && (
        <Text fontStyle={'italic'} fontSize={'xs'} mr={1}>
          Started: <Text fontWeight={'medium'}>{start}</Text>
        </Text>
      )}

      {!!end && (
        <Text fontStyle={'italic'} fontSize={'xs'}>
          - Ended: <Text fontWeight={'medium'}>{end}</Text>
        </Text>
      )}
    </HStack>
  );
};
