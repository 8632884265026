import { useApi } from '../../hooks';
import { useQuery } from '@tanstack/react-query';

export type UserPublicInfo = {
  coach_id: 'string';
  provider_id: 'string';
};

export const useUserPublicInfo = (userId: string | undefined) => {
  const { api } = useApi();

  const fetchPublicInfo = async (): Promise<UserPublicInfo> => {
    return api({
      route: `/user/${userId}/`,
    });
  };

  const { data, isLoading, isError } = useQuery<UserPublicInfo, Error>(
    ['public-user-info', userId],
    fetchPublicInfo,
    { enabled: !!userId, refetchOnWindowFocus: false }
  );

  return {
    isLoading,
    isError,
    data,
  };
};
