import React from 'react';
import { useTheme } from 'native-base';
import { Path } from 'react-native-svg-charts';

export const Shadow = ({ line }) => {
  const {
    colors: { secondary },
  } = useTheme();

  return (
    <Path
      key={'shadow'}
      y={4}
      d={line}
      fill={'none'}
      strokeWidth={2}
      stroke={secondary['700']}
      opacity={'1'}
    />
  );
};
