import React, {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
  useMemo,
} from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  Switch,
  Text,
  Tooltip,
  useTheme,
  VStack,
} from 'native-base';
import { LineChart, XAxis } from 'react-native-svg-charts';
import * as shape from 'd3-shape';
import { Grid } from './Grid';
import { Shadow } from './Shadow';
import { YAxis } from './YAxis';
import { Tooltip as ChartTooltip } from './Tooltip';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';
import { MAX_PHASE_DAYS } from '../../hooks/useFetchUserData';
import { Dots } from './Dots';
import { ScoreNumber } from '../UserDashboard/ScoreNumber';

const Y_AXIS_WIDTH = 16;
const Y_AXIS_MARGIN = 4;

export const ScoreChart = ({ chartHeight = 200, isCurved = false }) => {
  const {
    state: {
      all_scores_data,
      ayble_score,
      lowest_ayble_score,
      over_time_score,
      overview_score,
      overview_stress_score,
      stress_score,
      today_score,
    },
  } = useDashboardPageState();
  const compareTo = over_time_score >= today_score ? 'less' : 'more';

  const {
    colors: { primary, secondary },
  } = useTheme();

  const [positionX, setPositionX] = useState(-1);
  const [positionY, setPositionY] = useState(-1);
  const [chartWidth, setChartWidth] = useState(-1);
  const [needsCurve, setNeedsCurve] = useState(isCurved);
  const [switchValue, setSwitchValue] = useState(false);

  const [lineData, setLineData] = useState(
    ayble_score.map((s) => +s).reverse()
  );

  const [stressLineData, setStressLineData] = useState(
    stress_score.map((s) => s).reverse()
  );

  const data = [
    {
      data: lineData,
      svg: {
        overflow: 'visible',
        stroke: secondary['500'],
        strokeWidth: 2,
      },
    },
    {
      data: stressLineData,
      svg: {
        overflow: 'visible',
        stroke: primary['500'],
        strokeWidth: 2,
      },
    },
  ];

  // we use that type of indexing because we read data from the right
  // to the left of the array since the BE returns it in a descending way
  const scoreDate = useMemo(() => {
    // excludedPortion = N - min(N, daysToInclude)
    // e.g. N = 40, daysToInclude = 21 => excludedPortion = 40 - 21 = 19
    // e.g. N = 17, daysToInclude = 21 => excludedPortion = 17 - 17 = 0 => no days to exclude
    const excludedArrayPortionLen =
      all_scores_data.length - Math.min(all_scores_data.length, MAX_PHASE_DAYS);

    const index = switchValue
      ? all_scores_data.length - 1 - positionX
      : all_scores_data.length - 1 - positionX - excludedArrayPortionLen;
    return all_scores_data[index]?.score_date;
  }, [all_scores_data, positionX, switchValue]);

  const size = useRef(lineData.length);
  const chartRef = useRef(lineData.length);

  const handleSwitch = useCallback(() => {
    setSwitchValue(!switchValue);
    setLineData(
      !switchValue
        ? overview_score.map((s) => (s !== null ? +s : s)).reverse()
        : ayble_score.map((s) => (s !== null ? +s : s)).reverse()
    );
    setStressLineData(
      !switchValue
        ? overview_stress_score.map((s) => (s !== null ? +s : s)).reverse()
        : stress_score.map((s) => (s !== null ? +s : s)).reverse()
    );
    setNeedsCurve(!needsCurve);
  }, [
    ayble_score,
    needsCurve,
    overview_score,
    overview_stress_score,
    stress_score,
    switchValue,
  ]);

  const updatePosition = useCallback(
    (x) => {
      const x0 = 0; // x0 position
      const xDistance = chartWidth / size.current; // The width of each coordinate point

      const toSubtract = Y_AXIS_MARGIN + Y_AXIS_WIDTH;
      let value = ((x - toSubtract - x0) / xDistance).toFixed(0);
      if (value >= size.current - 1) {
        value = size.current - 1; // Out of chart range, automatic correction
      }

      setPositionX(Number(value));
    },
    [chartWidth]
  );

  const onMouseMove = useCallback(
    (e) => {
      let rect = e.currentTarget.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      updatePosition(x, y);
    },
    [updatePosition]
  );

  const onMouseOut = useCallback(() => {
    setPositionX(-1);
    setPositionY(-1);
  }, []);

  useLayoutEffect(() => {
    size.current = lineData.length;
  }, [lineData]);

  useLayoutEffect(() => {
    setChartWidth(chartRef?.current.clientWidth);
  }, []);

  useEffect(() => {
    function onWindowResize() {
      const actualChartWidth =
        chartRef?.current.clientWidth - Y_AXIS_WIDTH - Y_AXIS_MARGIN;
      setChartWidth(actualChartWidth);
    }

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    <>
      <VStack mb={8}>
        <Box width={'fit-content'}>
          <Tooltip
            label={
              'The Ayble Score is an aggregate score of all the gut symptoms, based on clinical protocols. The lower the number, the lower the symptoms.'
            }
            maxW="80"
            placement={'right'}
            bg="muted.100"
            hasArrow={true}
            _text={{
              color: 'black',
              fontSize: 14,
              fontWeight: 'normal',
            }}
          >
            <Heading size={'md'} fontWeight={'normal'} textAlign="left">
              Ayble Score
            </Heading>
          </Tooltip>
        </Box>

        <HStack
          flex={1}
          justifyContent={'space-between'}
          overflow={'visible'}
          my={6}
        >
          <Flex
            direction="row"
            flex={2}
            py={4}
            justifyContent={'space-between'}
          >
            <ScoreNumber label="Today's Score" score={today_score} />
            <ScoreNumber label="Lowest Score" score={lowest_ayble_score} />
            <ScoreNumber
              compareTo={compareTo}
              label="Over Time"
              score={over_time_score}
            />
          </Flex>

          <HStack alignItems="center" flex={3} justifyContent={'right'}>
            <Text>Overview</Text>
            <Switch
              defaultIsChecked={false}
              isChecked={switchValue}
              onToggle={handleSwitch}
              style={{ marginLeft: 10 }}
              isDisabled={!lineData.length}
            />
          </HStack>
        </HStack>

        {lineData?.length || stressLineData?.length ? (
          <Box>
            <Flex
              zIndex={'0'}
              overflow={'visible'}
              style={{ height: chartHeight }}
              flexDir={'row'}
              onMouseMove={onMouseMove}
              onMouseOut={onMouseOut}
              ref={chartRef}
            >
              <LineChart
                overflow={'visible'}
                data={data}
                style={{ flex: 1 }}
                shape={shape.curveNatural}
                contentInset={{ top: 20, bottom: 20 }}
                numberOfTicks={2}
                yMax={Math.max(8, ...lineData) + 2} // +2 to give more space
                yMin={0}
                zIndex={'0'}
              >
                <Grid />
                <Dots />
                <Shadow />
                <ChartTooltip
                  chartHeight={chartHeight}
                  chartWidth={chartWidth}
                  datum={lineData[positionX]}
                  date={scoreDate} // pass the scores data into tooltip to get data values
                  positionX={positionX}
                  positionY={positionY}
                  size={size.current}
                />
              </LineChart>
              <YAxis ml={`${Y_AXIS_MARGIN}px`} w={`${Y_AXIS_WIDTH}px`} />
            </Flex>

            {!switchValue && (
              <XAxis
                data={lineData}
                formatLabel={(value, index) => index + 1}
                contentInset={{ left: 0, right: 20 }}
                svg={{ fontSize: 10, fill: 'black' }}
              />
            )}

            <Text w={'100%'} textAlign={switchValue ? 'center' : 'left'}>
              {switchValue ? 'PHASE' : 'DAYS'}
            </Text>
          </Box>
        ) : (
          <Box
            borderStyle={'solid'}
            borderRadius={'sm'}
            borderWidth={'1px'}
            borderColor={'muted.200'}
            alignItems={'center'}
            justifyContent={'center'}
            height={chartHeight}
            width={chartWidth}
          >
            <Text fontSize={'2xl'}>No Data</Text>
          </Box>
        )}
      </VStack>
    </>
  );
};
