import { View } from 'native-base';
import Svg, { Rect, Path } from 'react-native-svg';

export const CircledCheckSVG = ({ fill = '#043338', size = '42px' }) => {
  return (
    <View>
      <Svg
        width={size}
        height={size}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.3751 14.4697C30.6679 14.7626 30.6679 15.2374 30.3751 15.5303L18.3751 27.5303C18.0822 27.8232 17.6073 27.8232 17.3144 27.5303L12.3144 22.5303C12.0215 22.2374 12.0215 21.7626 12.3144 21.4697C12.6073 21.1768 13.0822 21.1768 13.3751 21.4697L17.8447 25.9393L29.3144 14.4697C29.6073 14.1768 30.0822 14.1768 30.3751 14.4697Z"
          fill={fill}
        />
        <Rect x="0.5" y="0.5" width="41" height="41" rx="20.5" stroke={fill} />
      </Svg>
    </View>
  );
};
