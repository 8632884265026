import { Heading, Box, VStack } from 'native-base';
import { FORM_ACTIONS } from '../../../../utils/formActions.js';
import CustomSliderLabels from '../../../Molecules/CustomSliderLabels.js';
import CustomSlider from '../../../Molecules/CustomSlider.js';

function ColitisViewSeven({ formData, dispatch }) {
  const assessmentTrack = 'onboarding-uc-ibs';
  const assessmentObject = formData[assessmentTrack];

  const setBmSatisfaction = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-uc-ibs-4',
      assessment: assessmentTrack,
      payload: data,
    });
  };

  const setSymptomInterference = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-uc-ibs-5',
      assessment: assessmentTrack,
      payload: data,
    });
  };

  const setStressLevel = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-stress',
      payload: data,
    });
  };

  return (
    <VStack w={{ base: '100%' }} px={'3em'}>
      <VStack>
        <Heading fontSize={'3xl'} mb={4}>
          Tell us more about your symptoms.
        </Heading>
      </VStack>
      <h3>Over the last 10 days, on average how satisfied have you been with your bowel habits?</h3>
      <Box mb={4} px={{ base: '28px', md: '14px' }}>
        <CustomSlider
          value={assessmentObject['onboarding-uc-ibs-4']}
          setSliderValue={setBmSatisfaction}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels
          labels={['Very Happy', 'Just Ok', 'Very Unhappy']}
        />
      </Box>

      <h3>Over the last 10 days, how much have your symptoms interfered with your life in general?</h3>
      <Box mb={4} px={{ base: '28px', md: '14px' }}>
        <CustomSlider
          value={assessmentObject['onboarding-uc-ibs-5']}
          setSliderValue={setSymptomInterference}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels labels={['Not at all', 'Somewhat', 'Completely']} />
      </Box>

      <h3>Over the last 10 days, how would you rate your stress level in general?</h3>
      <Box px={{ base: '28px', md: '14px' }}>
        <CustomSlider
          value={formData['onboarding-stress']}
          setSliderValue={setStressLevel}
          minValue={0}
          maxValue={10}
        />
        <CustomSliderLabels labels={['Very low', 'Moderate', 'Very high']} />
      </Box>
    </VStack>
  );
}

export default ColitisViewSeven;
