import { Box, VStack, Button } from 'native-base';
import { useFormData } from '../../../utils/formReducer.js';
import Sex from './Sex';
import Gender from './Gender';

export const SexAndGender = ({ onNext }) => {
  const { state: formData, dispatch } = useFormData();

  return (
    <VStack>
      <Box mb={8}>
        <Sex dispatch={dispatch} formData={formData}></Sex>
      </Box>
      <Gender dispatch={dispatch} formData={formData} />
      <Button
        testID="onboarding-sex-gender-button"
        mt={6}
        isDisabled={!formData.sex || !formData.gender}
        alignSelf="center"
        width={'125px'}
        onPress={onNext}
      >
        Next
      </Button>
    </VStack>
  );
};
