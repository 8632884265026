import { useQuery } from '@tanstack/react-query';
import { useApi, useAuth } from '../../hooks';

export const useAuthenticatedUser = () => {
  const { api } = useApi();
  const { userToken } = useAuth();

  const getUser = async (token) => {
    return await api({
      route: '/private/user/self',
      method: 'GET',
      token: token,
    });
  };

  const {
    data: user,
    isPending,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['user', userToken],
    queryFn: () => getUser(userToken),
    enabled: !!userToken,
  });

  return {
    isPending,
    isLoading,
    isError,
    isSuccess,
    user,
    refetch,
  };
};

export default useAuthenticatedUser;
