import * as React from 'react';
import {
  ScrollView,
  Heading,
  Button,
  Modal,
  Box,
  VStack,
  Pressable,
  HStack,
  Text,
  CheckIcon,
} from 'native-base';
import { useCallback, useState } from 'react';

export const EditFoodListModal = ({
  foodList,
  initialSelectedFood,
  onClose,
  onSave,
  subtitle,
  title,
}) => {
  const [selectedFood, setSelectedFood] = useState(initialSelectedFood);

  const handleFoodSelection = useCallback(
    (item) => {
      if (!selectedFood.includes(item)) {
        setSelectedFood([...selectedFood, item]);
        return;
      }

      const arr = selectedFood.filter((food) => food !== item);
      setSelectedFood(arr);
    },
    [selectedFood]
  );

  const onClickSave = useCallback(
    (evt) => {
      evt.preventDefault();
      onSave(selectedFood);
      onClose();
    },
    [onClose, onSave, selectedFood]
  );

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size={'lg'}
      h="100vh"
      position="fixed"
    >
      <Modal.Content bg={'light.100'} p={6} margin="0 auto">
        <HStack alignItems={'center'} justifyContent={'center'} mb={4}>
          <VStack p={1} alignItems={'center'} w={'100%'}>
            <Heading fontFamily="heading">{title}</Heading>
            <Text
              fontSize={'md'}
              fontWeight={400}
              lineHeight={20}
              mt={2}
              textAlign={'center'}
            >
              {subtitle}
            </Text>
          </VStack>
          <Modal.CloseButton top={0} right={0} />
        </HStack>

        <ScrollView>
          <Box bg={'light.100'} rounded={0}>
            {foodList.map(({ display_name, ayble_id }, index) => (
              <Box
                key={index}
                mb={3}
                bg={
                  selectedFood.includes(ayble_id) ? 'secondary.500' : 'light.50'
                }
                rounded={'8px'}
                borderWidth="1"
                borderColor={'muted.400'}
              >
                <Pressable
                  p="16px"
                  key={index}
                  position={'relative'}
                  onPress={() => handleFoodSelection(ayble_id)}
                >
                  <HStack alignItems={'center'} justifyContent="space-between">
                    <VStack w={'75%'}>
                      <Text
                        color={
                          selectedFood.includes(ayble_id)
                            ? 'light.50'
                            : 'secondary.500'
                        }
                      >
                        {display_name}
                      </Text>
                    </VStack>
                    {selectedFood.includes(ayble_id) && (
                      <CheckIcon color={'light.50'} />
                    )}
                  </HStack>
                </Pressable>
              </Box>
            ))}
          </Box>
        </ScrollView>

        <Button mt={4} onPress={onClickSave} type="button" w={'100%'}>
          Save
        </Button>
      </Modal.Content>
    </Modal>
  );
};
