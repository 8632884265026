import {
  VStack,
  Pressable,
  Text,
  Box,
  Center,
  Heading,
  Flex,
  Button,
} from 'native-base';
import { FORM_ACTIONS } from '../../../utils/formActions.js';
import {
  cacheFormDataInLocalStorage,
  useFormData,
} from '../../../utils/formReducer.js';

export const ETHNICITIES = [
  'White',
  'Black or African American',
  'Hispanic or Latino',
  'American Indian or Alaska Native',
  'Asian',
  'Native Hawaiian or Other Pacific Islander',
  'Prefer not to say',
];

const styles = {
  formButtons: {
    marginTop: '.5rem',
    marginBottom: '.5rem',
    width: 'calc(50% - .5rem)',
  },
};

function Identify({ onNext }) {
  const { state: formData, dispatch } = useFormData();

  const updateRace = (data) => {
    const cacheData = JSON.stringify({ ...formData, race: data });
    cacheFormDataInLocalStorage(cacheData);
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: `race`,
      payload: data,
    });
  };

  return (
    <Center testID={'onboarding-identify'}>
      <VStack space={3} className="personal-info-container">
        <VStack my={0}>
          <Heading fontSize={'3xl'} mb={'2'}>
            How do you identify?
          </Heading>
          <Heading fontFamily="body" fontWeight={'300'} size={'md'} mb={'6'}>
            We ask this question because ethnicity has an impact on gut health
            and we want to ensure we provide culturally competent care.
          </Heading>
          <Flex direction="row" wrap={'wrap'} justifyContent={'space-between'}>
            {ETHNICITIES.map((item, index) => {
              return (
                <Pressable
                  style={styles.formButtons}
                  key={index}
                  onPress={() => updateRace(item)}
                  testID={'onboarding-identify-item'}
                >
                  <Box
                    h={{ base: '160px', sm: 'auto' }}
                    bg={
                      formData['race'] === item ? 'secondary.500' : 'light.50'
                    }
                    borderWidth="1"
                    borderColor="muted.400"
                    borderRadius="lg"
                    minHeight="70px"
                    maxHeight="125px"
                    justifyContent="center"
                    p="4"
                    style={{}}
                  >
                    <Text
                      color={
                        formData['race'] === item ? 'light.50' : 'secondary.500'
                      }
                      fontWeight="medium"
                      fontSize="md"
                    >
                      {item}
                    </Text>
                  </Box>
                </Pressable>
              );
            })}
          </Flex>
          <Button
            mt={6}
            testID="onboarding-identify-button"
            alignSelf="center"
            width={'125px'}
            onPress={onNext}
            isDisabled={!formData.race}
          >
            Next
          </Button>
        </VStack>
      </VStack>
    </Center>
  );
}

export default Identify;
