import React from 'react';
import { Flex, Heading, Text, Tooltip } from 'native-base';

const style = {
  arrowColor: '#72CCC6',
  borderBottomColor: '#F7F5ED',
  borderBottomStyle: 'dotted',
  borderBottomWidth: 1,
};

// commenting types for when we will have TS enabled
/*
type Props = {
  compareTo?: 'less' | 'more';
  label: string;
  score: number;
};
*/

export const ScoreNumber = ({ compareTo, label, score }) => {
  const {
    arrowColor,
    borderBottomColor,
    borderBottomStyle,
    borderBottomWidth,
  } = style;

  const compareToArrow = compareTo === 'less' ? '↓' : '↑';

  return (
    <Flex direction="column" pr={6}>
      <Heading color={'secondary.200'} size="xl">
        <Flex direction="row" justifyContent="center">
          {compareTo && (
            <Text color={arrowColor} fontSize="lg">
              {compareToArrow}
            </Text>
          )}
          {score}
        </Flex>
      </Heading>
      <Tooltip
        label={
          'Your Ayble Score is an aggregate score of all your gut symptoms, based on clinical protocols. The lower the number, the lower the symptoms.'
        }
        maxW="80"
        placement={'bottom'}
        hasArrow={true}
        _text={{
          color: 'black',
          fontSize: 14,
          fontWeight: 'normal',
        }}
        offset={8}
      >
        <Text
          color={'secondary.200'}
          textAlign="center"
          fontSize="sm"
          fontWeight="400"
        >
          <span
            style={{ borderBottomColor, borderBottomWidth, borderBottomStyle }}
          >
            {label}
          </span>
        </Text>
      </Tooltip>
    </Flex>
  );
};
