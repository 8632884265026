import React from 'react';
import { Heading, HStack, Box, VStack, Text } from 'native-base';
import { Image } from 'react-native';
import { foodList } from '../../constants/foodList';
import { getCompletedPhaseNumber } from '../UserDashboard/utils';

export const CelebrationResults = ({ selectedPhase, user }) => {
  const isPhaseCompleted =
    selectedPhase <= getCompletedPhaseNumber(user.current_phase);

  const currentFoodList = () => {
    switch (selectedPhase) {
      case 1:
        return user.recommended_foods.length > 0 ? user.recommended_foods : [];

      case 2:
        return user.potential_triggers.length > 0
          ? user.potential_triggers
          : user.recommended_foods;

      case 3:
        return user.suspected_triggers.length > 0
          ? user.suspected_triggers
          : user.recommended_foods;

      case 4:
        return user.verified_triggers.length > 0
          ? user.verified_triggers
          : user.recommended_foods;

      default:
        return [];
    }
  };

  return (
    <VStack alignItems="center" flex={1} px={'15px'}>
      <VStack>
        <Box my={8} alignItems="center">
          <Heading
            fontFamily={'heading'}
            fontSize="4xl"
            w={'100%'}
            textAlign={'center'}
            mb={2}
            mt={2}
          >
            Your Results
          </Heading>
          {selectedPhase === 4 && user.current_phase === 'PhaseFour' ? (
            <Text fontSize={'lg'} fontWeight={400}>
              Based on your Food Challenges, we found that{' '}
              {user.verified_triggers.length} of your{' '}
              {user.suspected_triggers.length} suspected foods were actually
              triggering your gut symptoms.
            </Text>
          ) : (
            <Text fontSize={'lg'} fontWeight={400}>
              {user?.first_name}, based on your Gut Checks, we've narrowed down
              your list to {user.potential_triggers?.length} "prime suspects".
              These are foods that are most likely causing your symptoms or
              making them worse.
            </Text>
          )}
        </Box>
      </VStack>

      <VStack mt={0} rounded="md" alignItems="center">
        {selectedPhase === 4 && isPhaseCompleted ? (
          <Box>
            {currentFoodList().map((item, index) => (
              <Box key={index}>
                <Box py="10px" key={index}>
                  <Box
                    key={index}
                    p={2}
                    borderColor={'muted.400'}
                    borderWidth={'1px'}
                    borderRadius={8}
                  >
                    <VStack
                      alignItems={'center'}
                      justifyContent={'center'}
                      p={5}
                    >
                      <Image
                        source={foodList[item]?.imgURL}
                        resizeMode="cover"
                        style={{
                          height: 60,
                          width: 60,
                        }}
                      />
                      <Heading
                        fontFamily={'heading'}
                        size="lg"
                        w={'100%'}
                        textAlign={'center'}
                        mt={3}
                      >
                        {foodList[item].name}
                      </Heading>
                      <Text fontWeight={400}>{foodList[item].description}</Text>
                      {/* <HStack my={4}  alignItems={'center'} rounded={18} px={4} py={2} bg={'primary.500'} justifyContent={'space-between'}>
                          <Text color={'secondary.600'} fontWeight={500} mr={10}>{item.severity}</Text>
                          @TODO: @Brenden, @Nick -> need to find a way to map this dynamically
                          {renderPips(item.severity_rank)}
                        </HStack> */}
                      <Text fontWeight={400} textAlign={'center'}>
                        Even the smallest portion causes intolerable symptoms.
                        Avoid at all costs!
                      </Text>
                    </VStack>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            p={2}
            borderColor={'muted.400'}
            borderWidth={'1px'}
            borderRadius={8}
          >
            {currentFoodList().map((item, index) => (
              <Box p="12px" key={index}>
                <Box py="10px" key={index}>
                  <HStack
                    mb={'20px'}
                    space={[2, 3]}
                    justifyContent="flex-start"
                    key={index}
                  >
                    <Image
                      source={foodList[item]?.imgURL}
                      resizeMode="cover"
                      style={{
                        height: 60,
                        width: 60,
                      }}
                    />
                    <VStack w={'75%'} ml={4}>
                      <Text bold mb={1}>
                        {foodList[item]?.name}
                      </Text>
                      <Text>{foodList[item]?.description}</Text>
                    </VStack>
                  </HStack>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </VStack>
    </VStack>
  );
};
