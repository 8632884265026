import { Box, useBreakpointValue, VStack } from 'native-base';
import { Body } from './Body';
import { Header } from './Header';
import { FORM_ACTIONS } from '../../utils/formActions';
import { useEffect, useState } from 'react';
import { useAffiliate } from '../../api/affiliates/useAffiliate';
import { useFormData } from '../../utils/formReducer';
import { MobileHeader } from './MobileHeader';
import LoginModal from '../LoginModal/LoginModal';

type Props = {
  onStartOnboarding: () => void;
};

export const LandingPage = ({ onStartOnboarding }: Props) => {
  const [showLogin, setShowLogin] = useState(false);
  const { dispatch } = useFormData();
  const { affiliate } = useAffiliate();

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  useEffect(() => {
    if (!!affiliate) {
      dispatch({
        type: FORM_ACTIONS.INIT_AFFILIATE,
        payload: affiliate,
      });
    }
  }, [affiliate, dispatch]);

  if (isMobile) {
    return (
      <>
        <Box height={showLogin ? undefined : '100vh'}>
          <VStack testID={'mobile-landing-page'}>
            <MobileHeader onGetStartedButtonClick={onStartOnboarding} />

            <Body
              onGetStartedButtonClick={onStartOnboarding}
              onOpenLoginModal={() => setShowLogin(true)}
            />
          </VStack>
        </Box>
        <LoginModal setShowLogin={setShowLogin} showLogin={showLogin} />
      </>
    );
  }

  return (
    <VStack testID={'landing-page'}>
      <Header
        onGetStartedButtonClick={onStartOnboarding}
        onOpenLoginModal={() => setShowLogin(true)}
      />

      <Body onGetStartedButtonClick={onStartOnboarding} />

      <LoginModal setShowLogin={setShowLogin} showLogin={showLogin} />
    </VStack>
  );
};
