import { useState, useEffect } from 'react';
import {
  VStack,
  Pressable,
  Text,
  Box,
  HStack,
  Flex,
  Center,
  Heading,
  Button,
  useBreakpointValue,
} from 'native-base';
import { useFormData } from '../../../utils/formReducer.js';
import { FORM_ACTIONS } from '../../../utils/formActions.js';
import ColitisViewTwo from './AssessmentRangeSliderForms/ColitisViewTwo.js';
import ColitisViewSix from './AssessmentRangeSliderForms/ColitisViewSix.js';
import ColitisViewSeven from './AssessmentRangeSliderForms/ColitisViewSeven.js';

import {
  onboarindUCOneAnswers,
  onboarindUCTwoAnswers,
} from '../../../data/onboardingDataMapping.js';
import AssessmentWrapUp from './AssessmentWrapUp.js';

function AssessmentColitis({ onNext }) {
  const { state: formData, dispatch } = useFormData();

  const [page, setPage] = useState(0);

  const flexDir = useBreakpointValue({
    base: 'column',
    md: 'row',
  });
  const flexBtns = useBreakpointValue({
    base: '50%',
    md: '100%',
  });
  const flexBtnsMar = useBreakpointValue({
    base: '0',
    md: '.5rem',
  });
  const styles = {
    formButtons: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
      flex: 1,
      textAlign: 'center',
      flexBasis: flexBtns,
      marginRight: flexBtnsMar,
      marginLeft: flexBtnsMar,
    },
    formRow: {
      justifyContent: 'space-between',
      flexDirection: flexDir,
    },
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToTop();
  }, [page]);

  const assessmentTrack = 'onboarding-uc';
  const frequencyResponse = (data) => {
    const newData = onboarindUCOneAnswers[data];
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-uc-1',
      assessment: assessmentTrack,
      payload: newData,
    });
  };
  const nightlyResponse = (data) => {
    const newData = onboarindUCTwoAnswers[data];
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: 'onboarding-uc-2',
      assessment: assessmentTrack,
      payload: newData,
    });
  };

  const assessmentObject = formData[assessmentTrack];
  // view 1?
  const frequencyArray = Object.keys(onboarindUCOneAnswers);
  const nightlyArray = Object.keys(onboarindUCTwoAnswers);
  // view 2?

  const ViewOne = () => {
    return (
      <VStack w={{ base: '100%' }}>
        <Heading fontSize={'3xl'} mb={'2'}>
          Tell us more about your symptoms.
        </Heading>
        <h3>
          In an average week, how many bowel movements do you have each day
          (while going about your daily routine)?
        </h3>
        <Flex style={styles.formRow}>
          {frequencyArray.map((item, index) => {
            return (
              <Pressable
                flexBasis={{ base: '50%', sm: '100%' }}
                style={styles.formButtons}
                key={index}
                onPress={() => frequencyResponse(item)}
              >
                {() => {
                  return (
                    <Box
                      minH={'70'}
                      justifyContent={'center'}
                      bg={
                        assessmentObject['onboarding-uc-1'] ===
                        onboarindUCOneAnswers[item]
                          ? 'secondary.500'
                          : 'light.50'
                      }
                      p="3"
                      borderWidth="1"
                      borderColor="muted.400"
                      borderRadius="lg"
                    >
                      <Text
                        color={
                          assessmentObject['onboarding-uc-1'] ===
                          onboarindUCOneAnswers[item]
                            ? 'light.50'
                            : 'secondary.500'
                        }
                        fontWeight="medium"
                        fontSize="l"
                      >
                        {item}
                      </Text>
                    </Box>
                  );
                }}
              </Pressable>
            );
          })}
        </Flex>

        <h3>
          In an average week, how many bowel movements do you have at night when
          trying to sleep?
        </h3>
        <Flex style={styles.formRow}>
          {nightlyArray.map((item, index) => {
            return (
              <Pressable
                style={styles.formButtons}
                key={index}
                onPress={() => nightlyResponse(item)}
              >
                {() => {
                  return (
                    <Box
                      minH={'70'}
                      justifyContent={'center'}
                      bg={
                        assessmentObject['onboarding-uc-2'] ===
                        onboarindUCTwoAnswers[item]
                          ? 'secondary.500'
                          : 'light.50'
                      }
                      p="3"
                      borderWidth="1"
                      borderColor="muted.400"
                      borderRadius="lg"
                    >
                      <Text
                        color={
                          assessmentObject['onboarding-uc-2'] ===
                          onboarindUCTwoAnswers[item]
                            ? 'light.50'
                            : 'secondary.500'
                        }
                        fontWeight="medium"
                        fontSize="l"
                      >
                        {item}
                      </Text>
                    </Box>
                  );
                }}
              </Pressable>
            );
          })}
        </Flex>
      </VStack>
    );
  };

  const PageDisplay = () => {
    if (page === 0) {
      return <ViewOne />;
    } else if (page === 1) {
      return <ColitisViewTwo formData={formData} dispatch={dispatch} />;
    } else if (page === 2) {
      return <ColitisViewSix formData={formData} dispatch={dispatch} />;
    } else if (page === 3) {
      return <ColitisViewSeven formData={formData} dispatch={dispatch} />;
    } else if (page === 4) {
      return <AssessmentWrapUp onNext={onNext} />;
    }
  };

  return (
    <Center>
      <VStack space={1} className="personal-info-container">
        {PageDisplay()}
        <Center>
          <HStack mt={4}>
            {page === 0 && (
              <Button
                block
                isDisabled={
                  !formData['onboarding-uc']['onboarding-uc-1'] ||
                  !formData['onboarding-uc']['onboarding-uc-2']
                }
                minWidth={'300px'}
                size={'lg'}
                onPress={() => {
                  setPage((currPage) => currPage + 1);
                }}
              >
                {'Next'}
              </Button>
            )}
            {page > 0 && page !== 4 && (
              <>
                <Button
                  mr={2}
                  block
                  minWidth={'175px'}
                  size={'lg'}
                  onPress={() => {
                    setPage((currPage) => currPage - 1);
                  }}
                >
                  {'Previous'}
                </Button>
                <Button
                  block
                  minWidth={'175px'}
                  size={'lg'}
                  isDisabled={
                    !formData['onboarding-uc']['onboarding-uc-3'] ||
                    !formData['onboarding-uc']['onboarding-uc-4']
                  }
                  onPress={() => {
                    setPage((currPage) => currPage + 1);
                  }}
                >
                  {'Next'}
                </Button>
              </>
            )}
          </HStack>
        </Center>
      </VStack>
    </Center>
  );
}

export default AssessmentColitis;
