import { useQuery } from '@tanstack/react-query';
import { useApi, useAuth } from '../../hooks';

export type RecommendedFood = {
  id: string;
  image_url: string;
  is_recommended_user_food: boolean;
  name: string;
};

export const useRecommendedFoods = (userId: string = '') => {
  const { api } = useApi({ throwIfError: true });
  const { isAuthenticated, loading, userToken } = useAuth();

  const fetchRecommendedFoods = async () => {
    const requestObj = {
      route: `/private/coach/users/${userId}/foods`,
      token: userToken,
    };

    return await api(requestObj);
  };

  const { data, isLoading, error } = useQuery<RecommendedFood[], any>(
    ['hc-recommended-foods', userToken, userId],
    fetchRecommendedFoods,
    {
      enabled: isAuthenticated && !loading && !!userToken && !!userId,
      refetchOnWindowFocus: false,
    }
  );

  return { foods: data, isLoading, error };
};
