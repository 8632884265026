import { Box, Button, Flex, HStack, Image } from 'native-base';
import { CoBrandingLogo } from './CoBrandingLogo';
import { Banner } from './Banner';

const logo = '../../FA__ayble_health_Logo_Isabelline_white.png';

type Props = {
  onGetStartedButtonClick: () => void;
};

export const MobileHeader = ({ onGetStartedButtonClick }: Props) => {
  return (
    <Box position={'sticky'} top={0} zIndex={999}>
      <Banner />

      <Flex
        alignItems={'center'}
        bgColor={'secondary.500'}
        direction={'row'}
        justifyContent={'space-between'}
        px={6}
      >
        <HStack>
          <Image
            alt="Ayble Health logo"
            resizeMode={'contain'}
            size={'sm'}
            source={{
              uri: logo,
            }}
            testID={'ayble-health-logo'}
          />

          <CoBrandingLogo variant={'small'} />
        </HStack>

        <Button
          bgColor={'muted.100'}
          _text={{ color: 'secondary.500', textTransform: 'none' }}
          minHeight={'32px'}
          maxW={'96px'}
          size={'sm'}
          onPress={onGetStartedButtonClick}
          testID={'onboarding-signup-button'}
        >
          Get Started
        </Button>
      </Flex>
    </Box>
  );
};
