import styled from '@emotion/styled';
import { Box, Divider, HStack, ITheme, Text, VStack } from 'native-base';
import { BODY_2_MOBILE_SIZE_PX } from './constants';

const Section = styled('section')<{ theme?: ITheme }>`
  align-items: center;
  background-color: ${(p) => p.theme.colors.secondary['600']};
  display: flex;
  overflow-x: hidden;
  margin-right: 8px;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  min-width: 100%;
  white-space: nowrap;

  .news-message {
    align-items: center;
    animation: slide-left 15s linear infinite;
    display: flex;
    flex-shrink: 0;

    @keyframes slide-left {
      from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
      to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
      }
    }
  }
`;

export const ScrollingText = () => {
  return (
    <HStack bgColor={'secondary.600'} color={'white'} height={'65px'}>
      <VStack justifyContent={'center'} px={4}>
        Why Ayble?
      </VStack>

      <Box py={4} pr={4}>
        <Divider bgColor={'white'} orientation={'vertical'} thickness={1} />
      </Box>

      <Section>
        <Wrapper>
          <Section className="news-message">
            <Text color={'white'} fontSize={BODY_2_MOBILE_SIZE_PX} px={2}>
              <Text color={'tertiary.500'}>92%</Text> significantly improve
              symptoms*
            </Text>

            <Text color={'white'} fontSize={BODY_2_MOBILE_SIZE_PX} px={2}>
              <Text color={'tertiary.500'}>7 days</Text> until results you can
              feel
            </Text>
          </Section>

          <Section className="news-message">
            <Text color={'white'} fontSize={BODY_2_MOBILE_SIZE_PX} px={2}>
              <Text color={'tertiary.500'}>92%</Text> significantly improve
              symptoms*
            </Text>

            <Text color={'white'} fontSize={BODY_2_MOBILE_SIZE_PX} px={2}>
              <Text color={'tertiary.500'}>7 days</Text> until results you can
              feel
            </Text>
          </Section>
        </Wrapper>
      </Section>
    </HStack>
  );
};
