import React, { useState } from 'react';
import {
  Image,
  Button,
  Link,
  Pressable,
  Flex,
  HStack,
  useMediaQuery,
} from 'native-base';
import { useAuth } from '../../hooks';
import LoginModal from '../LoginModal/LoginModal';

function HeaderNav({
  changePage = () => {},
  formData = {},
  hideBack = false,
  hideLogin = false,
  page,
  pregnancyException = null,
  setParentPage = null,
  pageLinks = [],
}) {
  const [showLogin, setShowLogin] = useState(false);
  const { isAuthenticated, signOut } = useAuth();
  const [isMobile] = useMediaQuery({
    maxWidth: 768,
  });

  const backBttn = '../chevron-left-white.png';

  const logo = './../FA__ayble_health_Logo_Isabelline_white.png';
  const logoDark = './../FA__ayble_health_Logo_Isabelline.png';
  const styles = {
    navIcon: {
      backgroundSize: 'contain',
      maxHeight: '32px',
      width: '44px',
      marginTop: '4px',
      resizeMode: 'contain',
      zIndex: 2,
    },
    logo: {
      backgroundSize: 'contain',
      maxHeight: '56px',
      resizeMode: 'contain',
    },
    backBttn: {
      width: 32,
      height: 32,
    },
  };

  const handlePageChange = () => {
    if (!!changePage) {
      changePage();
      return;
    }

    if (page === 1) {
      // skip old login and go to the home page
      setParentPage(-2);
    } else if (
      // skips pregnancy question (PregnancyInfo) if sex and gender are 'Male'
      page === 9 &&
      formData.sex === 'Male' &&
      formData.gender === 'Male'
    ) {
      pregnancyException(true);
    } else setParentPage((currPage) => currPage - 1);
  };

  const hasLinksToShow = !!pageLinks.length;
  const isDark = !hasLinksToShow;

  return (
    <Flex
      alignItems={'center'}
      backgroundColor={hasLinksToShow ? 'muted.100' : 'secondary.500'}
      flexDirection={isAuthenticated ? 'row-reverse' : 'row'}
      h={'64px'}
      justifyContent={'space-between'}
      position={'relative'}
      px={'2em'}
      py={0}
      style={{ isolation: 'isolate' }}
    >
      {isAuthenticated && (
        <Link
          _text={{
            fontSize: 'xs',
            _light: {
              color: isDark ? 'light.100' : 'secondary.600',
            },
            color: isDark ? 'light.100' : 'secondary.600',
          }}
          _hover={{
            _text: {
              _light: {
                color: isDark ? 'primary.100' : 'secondary.600',
              },
              color: isDark ? 'primary.100' : 'secondary.600',
            },
          }}
          onPress={() => signOut({ returnTo: window.location.origin })}
        >
          Logout
        </Link>
      )}

      {hasLinksToShow && (
        <HStack w={300} justifyContent={'space-between'}>
          {pageLinks.map((pageLink, i) => (
            <Link
              key={`${pageLink}-${i}`}
              _text={{
                color: 'secondary.600',
                textTransform: 'none',
                textDecoration: 'none',
                fontWeight: 'medium',
              }}
              _hover={{
                _text: {
                  textDecoration: 'underline',
                  fontWeight: 'medium',
                },
              }}
              href={pageLink.href}
            >
              {pageLink.label}
            </Link>
          ))}
        </HStack>
      )}

      <Image
        size={'md'}
        style={{
          ...styles.logo,
          ...(isDark && {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }),
        }}
        source={{
          uri: isDark ? logo : logoDark,
        }}
        alt="Ayble Health logo"
        onClick={() => window.open('https://ayblehealth.com', '_blank')}
      />

      {!hasLinksToShow && page !== 0 && page !== -2 && (
        <Pressable variant={'closer'} onPress={handlePageChange}>
          {!hideBack && (
            <Image
              zIndex="1"
              alt="back button"
              source={backBttn}
              style={styles.backBttn}
            />
          )}
        </Pressable>
      )}

      {!isAuthenticated && !hideLogin && (
        <Link
          zIndex={5}
          _text={{
            fontSize: 'xs',
            _light: {
              color: 'light.100',
            },
            color: 'light.100',
          }}
          _hover={{
            _text: {
              _light: {
                color: 'primary.100',
              },
              color: 'primary.100',
            },
          }}
          onPress={() => setShowLogin(true)}
        >
          Login
        </Link>
      )}
      <LoginModal showLogin={showLogin} setShowLogin={setShowLogin} />

      {/* Only show on homepage */}
      {page === -2 && !isMobile && (
        <Button onPress={() => setParentPage(1)}>Get Started</Button>
      )}
    </Flex>
  );
}

export default HeaderNav;
