/* eslint-disable react-hooks/exhaustive-deps */
// TODO: Better dependency pattern to avoid ^
import React, {
  FC,
  ReactElement,
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import qs from 'qs';
import { ApiRequest } from '../types';
import { useApi } from './useApi';
// import { isAndroid, isIOS } from 'react-device-detect';
import { User } from '../models/user';

const TOKEN_KEY = 'ayble-auth-token';

// Create the Auth Context with the data type specified
// and a empty object
const AuthContext = createContext({});

const AuthProvider: FC<{ children?: ReactNode }> = ({
  children,
}): ReactElement => {
  const [authData, setAuthData] = useState<any>(null);
  const [userData, setUserData] = useState<any>(null);
  const [userToken, setUserToken] = useState<any>(null);

  const { api } = useApi();

  // the AuthContext start with loading equals true
  // and stay like this, until the data be load from Async Storage
  const [loading, setLoading] = useState(true);

  const updateUserToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
    setUserToken(token);
  };

  useEffect(() => {
    let findToken;

    setLoading(true);

    if (window.location.hash && window.location.hash.trim() !== '') {
      const hashData = qs.parse(window.location.hash.replace(/^#/, ''));

      if (hashData.error) {
        console.error('useAuth() - HASH ERROR:', hashData.error, hashData);
      } else if (hashData.access_token) {
        findToken = hashData.access_token;
        window.location.replace(window.location.pathname);
      }
    }

    if (!findToken && localStorage.getItem(TOKEN_KEY)) {
      findToken = localStorage.getItem(TOKEN_KEY);
    }

    if (findToken) {
      updateUserToken(findToken);
    } else {
      setLoading(false);
    }

    if (window.location?.hash?.trim() === '' && !findToken) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const getUserMetadata = async () => {
      if (!userToken) {
        return;
      }

      setLoading(true);

      try {
        const userRequest: ApiRequest = {
          route: `/private/user/self`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        };

        const userResponse = await api(userRequest);

        setUserData(userResponse);

        if (userResponse?.subscription_id || userResponse?.iap_purchase_id) {
          // if (isAndroid || isIOS) {
          //   // Automatically attempt to open native app with user token
          //   window.location.href = `com.ayblehealth.app://login?token=${userToken}`;
          // }

          setTimeout(() => {
            // TODO: Better redirect logic (using "ProtectedRoute" ?)
            if (
              !/\/(thank-you|dashboard|hc-dashboard|settings|user-details|content-preview)/i.test(
                window.location.pathname
              )
            ) {
              // TODO: Refactor for navigate? (ERROR: Cannot use outside of <Router> context?)
              window.location.assign('/thank-you');
            }
          }, 1000);
        } else if (userResponse) {
          // we will redirect the user to '/plans' through the regular router workflow in App.tsx
          console.debug('User exists, without subscription');
        }
      } catch (e) {
        console.error('api error', (e as Error).message);
      } finally {
        setLoading(false);
      }
    };

    getUserMetadata();
  }, [userToken]);

  const updateUserById = useCallback(
    (userId, payload) => {
      const update = async () => {
        setLoading(true);

        try {
          const userRequest: ApiRequest<string> = {
            route: `/private/user/${userId}`,
            method: 'PUT',
            payload: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userToken}`,
            },
          };

          const userResponse = await api(userRequest);
          setUserData(userResponse.data);
        } catch (e) {
          console.warn(e);
        } finally {
          setLoading(false);
        }
      };

      update();
    },
    [userToken]
  );

  const updateUserSelf = useCallback(
    (payload) => {
      updateUserById(userData.id, {
        email: userData.email,
        auth_id: userData.auth_id,
        ...payload,
      });
    },
    [userData]
  );

  const signIn = async () => {
    // loginWithRedirect();
    // TODO: Hookup with final steps to send magic link from API
  };

  const signOut = async () => {
    // Remove data from context, so the App can be notified
    // and send the user to the AuthStack
    setAuthData(null);
    setUserData(null);
    setUserToken(null);
    localStorage.removeItem(TOKEN_KEY);
    // TODO: Refactor for navigate? (ERROR: Cannot use outside of <Router> context?)
    window.location.assign('/');
    // logout();
  };

  return (
    // This component will be used to encapsulate the whole App,
    // so all components will have access to the Context
    <AuthContext.Provider
      value={{
        authData,
        userToken,
        userData,
        user: userData,
        isAuthenticated: !!userData,
        loading,
        updateUserSelf,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// A simple hooks to facilitate the access to the AuthContext
// and permit components to subscribe to AuthContext updates
function useAuth(): {
  user: User;
  userToken: string;
  authData: any;
  userData: any;
  isAuthenticated: boolean;
  loading: boolean;
  signIn: () => void;
  signOut: () => void;
  updateUserSelf: (userObject: User) => Promise<void>;
} {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context as {
    user: any;
    userToken: string;
    authData: any;
    userData: any;
    isAuthenticated: boolean;
    loading: boolean;
    signIn: () => void;
    signOut: () => void;
    updateUserSelf: (userObject: User) => Promise<void>;
  };
}

export { AuthContext, AuthProvider, useAuth };
