import { Flex, Heading, Image, Text, VStack } from 'native-base';
import React from 'react';
import moment from 'moment';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';
import { useFoodChallengesData } from '../../hooks/useFoodChallengesData';

const timeIcon = '../../time.svg';

export const TimeCount = () => {
  const {
    state: { user },
  } = useDashboardPageState();
  const { totalPhaseDays } = useFoodChallengesData(user);
  const { current_phase, days_of_phase, phase_start_date, timezone } = user;
  const currentPhaseTotalDays = totalPhaseDays(current_phase);
  // Found current date local to the user and their local start date, to compute the correct day of phase
  const currentLocalDate = moment.tz(timezone).format('YYYY-MM-DD');
  const localStartDate = moment
    .tz(phase_start_date, timezone)
    .format('YYYY-MM-DD');
  const daysSinceStart =
    moment(currentLocalDate).diff(moment(localStartDate), 'days') + 1;

  return (
    <VStack>
      <Flex w={'40px'} h={'56px'} flexDir={'column'}>
        <Image
          size="sm"
          source={{
            uri: timeIcon,
          }}
          alt="phases"
        />
      </Flex>
      <Heading mt={1} textAlign={'left'}>
        Day {daysSinceStart}
      </Heading>
      {daysSinceStart <= currentPhaseTotalDays && (
        <Text color="muted.500" fontSize={'lg'} fontWeight={500} mt={1}>
          Of {currentPhaseTotalDays}
        </Text>
      )}

      {(current_phase === 'PhaseOne' || current_phase === 'PhaseTwo') && (
        <Text mt={2} fontSize={'lg'}>
          Gut checks logged: {days_of_phase}
        </Text>
      )}
    </VStack>
  );
};
