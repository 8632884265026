import { Pressable, useTheme } from 'native-base';
import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  display: flex;
  background-color: ${(p) =>
    p.isHovered ? p.colors.muted['200'] : 'transparent'};
  border-radius: 3px;
  margin-left: 4px;
  padding: 4px;
  transition: background-color 350ms linear;
`;

export const IconButton = ({ children, onClick }) => {
  const { colors } = useTheme();

  return (
    <Pressable onPress={onClick}>
      {({ isHovered }) => (
        <Wrapper colors={colors} isHovered={isHovered}>
          {children}
        </Wrapper>
      )}
    </Pressable>
  );
};
