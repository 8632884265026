import { User } from '../models/user';
import { useCallback } from 'react';
import moment from 'moment';
import { useDashboardPageState } from './useDashboardPageState';

export const useFoodChallengesData = (user: User) => {
  const {
    state: { current_subphase },
  } = useDashboardPageState();

  const phaseStartDate = moment(user.phase_start_date)
    .format('YYYY-MM-DD')
    .toString();

  const currentPhaseDay = useCallback(() => {
    const today = moment();
    const diff = today.diff(phaseStartDate, 'days');

    if (user.current_phase === 'PhaseThree') {
      return current_subphase?.current_day
        ? current_subphase?.current_day
        : diff;
    }

    return diff + 1;
  }, [current_subphase, phaseStartDate, user.current_phase]);

  const totalPhaseDays = useCallback(
    (userPhaseName: string) => {
      return userPhaseName === 'PhaseThree'
        ? 3
        : currentPhaseDay() >= 21
        ? 28
        : 21;
    },
    [currentPhaseDay]
  );

  return { totalPhaseDays };
};
