import { omitBy } from 'lodash';

export const userPhaseProgressWeightMap = {
  NEW: 1,
  'ON TRACK': 2,
  'AT RISK': 3,
  'OFF TRACK': 4,
};

export const toTitleCase = (str) =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(' ');

export const countDecimals = (number) => {
  const converted = number.toString();
  if (converted.includes('.')) return converted.split('.')[1].length;
  return 0;
};

export const cleanMeetingNotes = (meeting) => omitBy(meeting, (f) => !f);
