import { View, Text } from 'native-base';
import { parseStyleTags } from './utils';

const textStyles = {
  textAlign: 'left',
  lineHeight: 21,
  flexDirection: 'row',
  flexWrap: 'wrap',
} as const;

const listStyles = {
  maxW: '80%',
  mt: 1,
  mx: 6,
};

const PreviewText = ({ text, list }: { text: string; list?: boolean }) => {
  const parsedText = parseStyleTags(text);
  const styleProps = list ? listStyles : {};

  return (
    <View my={2} style={textStyles} {...styleProps}>
      <Text>
        {parsedText.map((parsedObject, i) => {
          const { props, value } = parsedObject || {};
          return (
            <Text key={i} {...props}>
              {value}
            </Text>
          );
        })}
      </Text>
    </View>
  );
};

export default PreviewText;
