import { useApi, useAuth } from '../../hooks';
import { useQuery } from '@tanstack/react-query';
import { cleanMeetingNotes } from '../../components/HealthCoachDashboard/utils';

type MeetingNotes = {
  id: string;
  coach_id: string;
  user_id: string;
  created_at: string;
  meeting_date: string;
  meeting_type: string | null;
  status: string | null;
  meeting_duration: number;
  notes_url: string;
  highlights: string[];
  notes: string | null;
};

export const useMeetingNotes = (userId: string) => {
  const { api } = useApi();
  const { isAuthenticated, loading, userToken } = useAuth();

  const fetchMeetingNotes = async () => {
    const requestObj = {
      route: `/private/coach-meeting-notes/?user_uuid=${userId}`,
      token: userToken,
    };

    const meetingNotes = await api(requestObj);

    return meetingNotes.map(cleanMeetingNotes);
  };

  const { data, isLoading, isError } = useQuery<MeetingNotes[], Error>(
    ['hc-meeting-notes', userToken, userId],
    () => fetchMeetingNotes(),
    {
      enabled: isAuthenticated && !loading && !!userToken,
      refetchOnWindowFocus: false,
    }
  );

  return { meetingNotes: data, isLoading, isError };
};
