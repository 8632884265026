import { useUserPublicInfo } from '../api/public/useUserPublicInfo';
import { useCoachPublicInfo } from '../api/public/useCoachPublicInfo';
import { useProviderPublicInfo } from '../api/public/useProviderPublicInfo';

export const useUserCoachProviderPublicInfo = (userId: string | undefined) => {
  const { data } = useUserPublicInfo(userId);
  const coachInfo = useCoachPublicInfo(data?.coach_id);
  const providerInfo = useProviderPublicInfo(data?.provider_id);

  return {
    coachInfo: {
      ...coachInfo,
      isLoading: coachInfo?.isLoading && !!userId && !!data?.coach_id,
    },
    providerInfo: {
      ...providerInfo,
      isLoading: providerInfo?.isLoading && !!userId && !!data?.provider_id,
    },
  };
};
