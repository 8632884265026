import { useCallback } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

export const useGoogleTagManager = () => {
  const sendDataToGTM = useGTMDispatch();

  const trackEventInGoogleTagManager = useCallback(
    (eventName) => {
      // send page view event to Google Tag Manager
      // because not supported in Segment
      sendDataToGTM({ event: 'page_view', page_title: eventName });
    },
    [sendDataToGTM]
  );

  return { trackEventInGoogleTagManager };
};
