import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useEligibilityRouting = ({
  checkEligibility,
  mutationSuccess,
  state,
  search,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const { affiliate, referrer, userId, email, firstName, lastName } = state;
    if (mutationSuccess) {
      if (checkEligibility) {
        navigate(`/eligibility${search}`, {
          state: {
            affiliate,
            referrer,
            userId,
            userEmail: email,
            firstName,
            lastName,
          },
        });
      } else {
        navigate(`/plans${search}`, {
          state: {
            affiliate,
            referrer,
            // We pass this boolean to determined if they added an affiliate via the lookup
            lookedUpAffiliate: !!affiliate,
            userId,
            userEmail: email,
          },
        });
      }
    }
  }, [mutationSuccess, checkEligibility, navigate, search, state]);
};

export default useEligibilityRouting;
