import React from 'react';
import { HStack, SmallCloseIcon, Text } from 'native-base';
import { IconButton } from './IconButton';
import { AsyncSelect } from './AsyncSelect';

export const MultilineAsyncSelect = ({
  name,
  entries,
  loadOptions,
  onEntriesChange,
  readonly,
}) => {
  if (!entries?.length)
    return (
      <Text italic fontSize={'md'}>
        No Entries
      </Text>
    );

  return (
    <>
      {entries?.map(({ label, value }, index) => (
        <HStack
          alignItems={'center'}
          key={`${name}-${value}-item-${index}`}
          marginBottom={index !== entries.length - 1 ? 3 : 0}
        >
          <AsyncSelect
            placeholder="Type..."
            value={{ label, value }}
            size={'sm'}
            isSearchable={true}
            isDisabled={readonly}
            height={8}
            loadOptions={loadOptions}
            marginBottom={0}
            width={'100%'}
            maxWidth={'100%'}
            onChange={({ label, value }) => {
              const updatedValues = [
                ...entries.slice(0, index),
                { label, value },
                ...entries.slice(index + 1),
              ];
              onEntriesChange(updatedValues);
            }}
          />

          {!readonly && (
            <IconButton
              onClick={() => {
                const updatedValues = [
                  ...entries.slice(0, index),
                  ...entries.slice(index + 1),
                ];
                onEntriesChange(updatedValues);
              }}
            >
              <SmallCloseIcon />
            </IconButton>
          )}
        </HStack>
      ))}
    </>
  );
};
