import React from 'react';
import { Text, VStack } from 'native-base';

export const YAxis = ({ ml, w }) => (
  <VStack h={'100%'} bg={'blue'} w={w} ml={ml} justifyContent={'space-evenly'}>
    <Text>H</Text>
    <Text>M</Text>
    <Text>L</Text>
  </VStack>
);
