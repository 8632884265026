import { VStack, Pressable, Text, Box, Center, Heading } from 'native-base';
import { FORM_ACTIONS } from '../../../utils/formActions.js';
import { GENDERS } from '../../../constants/misc';

function Gender({ formData, dispatch }) {
  const styles = {
    formButtons: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
    },
  };
  const updateGender = (data) => {
    dispatch({
      type: FORM_ACTIONS.UPDATE_FORM_FIELD,
      fieldName: `gender`,
      payload: data,
    });
  };

  return (
    <Center testID={'onboarding-gender'}>
      <VStack space={1} className="personal-info-container">
        <Heading fontSize={'3xl'} mb={'4'}>
          What gender do you identify with?
        </Heading>
        <Heading fontFamily="body" fontWeight={'300'} size={'md'} mb={'6'}>
          Some people don’t identify with the sex they were assigned at birth.
          So we ask for your gender so that we can address you correctly.
        </Heading>
        <VStack>
          {GENDERS.map((item, index) => {
            return (
              <Pressable
                style={styles.formButtons}
                key={index}
                onPress={() => updateGender(item)}
                testID={'onboarding-gender-item'}
              >
                <Box
                  bg={
                    formData['gender'] === item ? 'secondary.500' : 'light.50'
                  }
                  borderWidth="1"
                  borderColor="muted.400"
                  borderRadius="lg"
                  p="5"
                  style={{}}
                >
                  <Text
                    color={
                      formData['gender'] === item ? 'light.50' : 'secondary.500'
                    }
                    fontWeight="medium"
                    fontSize="xl"
                  >
                    {item}
                  </Text>
                </Box>
              </Pressable>
            );
          })}
        </VStack>
      </VStack>
    </Center>
  );
}

export default Gender;
