import React, { useCallback, useMemo } from 'react';
import { StatusBar } from 'react-native';
import { SceneMap, TabView } from 'react-native-tab-view';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Center } from 'native-base';
import { TabsBar } from './TabsBar';

const Route = () => (
  <Center flex={1}>
    <Outlet />
  </Center>
);

/**
 * This component accepts an array of routes like the following one:
 * [{
 *     key: 'someUniqueKey',
 *     path: '/foo/bar',
 *     searchParams?: 'userId=1',
 *     title: 'TITLE',
 *   }]
 * and it will render the tabs accordingly.
 * Also, clicking on a tab, it will navigate to the specified path.
 * In the React router, be sure you provide the content you want to display when clicking on a tab.
 *
 * Example of a React router rendering content when clicking on the "bar" tab in the "foo" route:
 *
 * <Route
 *   path="/foo"
 *   element={<Foo />}
 * >
 *   <Route path="/foo/bar" element={<Bar />} />
 * </Route>
 *
 */
export const Tabs = ({ routes }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentTabIndex = useMemo(() => {
    return routes.findLastIndex((r) => pathname.includes(r.path));
  }, [pathname, routes]);

  // always render the same component since it will be the outlet to render
  // the content according to the route we navigate to
  const renderScene = useMemo(() => {
    const sceneMap = routes.reduce(
      (map, route) => ({ ...map, [route.key]: Route }),
      {}
    );
    return SceneMap(sceneMap);
  }, [routes]);

  const onTabPress = useCallback(
    ({ path, searchParams = '' }) => {
      const url = `${path}${searchParams ? `?${searchParams}` : ''}`;
      navigate(url);
    },
    [navigate]
  );

  return (
    <TabView
      animationEnabled={false}
      navigationState={{
        index: +currentTabIndex,
        routes,
      }}
      renderScene={renderScene}
      renderTabBar={(props) => (
        <TabsBar
          {...props}
          currentTabIndex={currentTabIndex}
          onTabPress={onTabPress}
        />
      )}
      style={{
        marginTop: StatusBar.currentHeight,
      }}
    />
  );
};
