import { useQuery } from '@tanstack/react-query';
import { useApi, useAuth } from '../../hooks';

export type Food = {
  description: string;
  heat: string;
  image_url: string;
  name: string;
  potential_trigger: boolean;
  t_value: number;
};

export const useSuspectedFoods = (
  userId: string,
  canSeeSuspectedFoods: boolean = true
) => {
  const { api } = useApi({ throwIfError: true });
  const { isAuthenticated, loading, userToken } = useAuth();

  const fetchSuspectedFoods = async () => {
    const requestObj = {
      route: '/private/backstep/foods/start/',
      token: userToken,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Coach-Override': userId,
      },
    };

    return await api(requestObj);
  };

  const { data, isLoading, error } = useQuery<Food[], any>(
    ['hc-suspected-foods', userToken, userId],
    fetchSuspectedFoods,
    {
      enabled:
        canSeeSuspectedFoods &&
        isAuthenticated &&
        !loading &&
        !!userToken &&
        !!userId,
      refetchOnWindowFocus: false,
    }
  );

  return { foods: data, isLoading, error };
};
