export const foodList = {
  Alliums: {
    name: 'Alliums',
    description:
      'Garlic, Onions (including shallots and scallions), Leeks, Asparagus',
    imgURL: require('../assets/images/foods/Alliums-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Alliums-Garlic-individual-icon.png'),
        description: '1 clove',
      },
      {
        imgURL: require('../assets/images/foods/Alliums-Onion-individual-icon.png'),
        description: '3/4 onion chopped',
      },
      {
        imgURL: require('../assets/images/foods/Alliums-Asparagus-individual-icon.png'),
        description: '5 spears',
      },
    ],
    eatThisInstead:
      'Garlic infused oil, chives, and the green tops of scallions',
    avoidThese:
      'Garlic, Onions (including shallots and scallions), Leeks, Asparagus',
  },
  'Cruc Veg': {
    name: 'Cruciferous Vegetables',
    description: 'Broccoli, Brussel Sprouts, Cabbage, Cauliflower, Kale',
    imgURL: require('../assets/images/foods/Cruciferous-Vegetables-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Cruciferous-Vegetables-Icon.png'),
        description: '1 cup',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Carrots, eggplant, green beans, cucumber, lettuce, potato, tomato, spinach, and zucchini',
    avoidThese: 'Broccoli, Brussel Sprouts, Cabbage, Cauliflower, Kale',
  },
  'Cruciferous Vegetables': {
    name: 'Cruciferous Vegetables',
    description: 'Broccoli, Brussel Sprouts, Cabbage, Cauliflower, Kale',
    imgURL: require('../assets/images/foods/Cruciferous-Vegetables-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Cruciferous-Vegetables-Icon.png'),
        description: '1 cup',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Carrots, eggplant, green beans, cucumber, lettuce, potato, tomato, spinach, and zucchini',
    avoidThese: 'Broccoli, Brussel Sprouts, Cabbage, Cauliflower, Kale',
  },
  Nightshade: {
    name: 'Nightshades',
    description: 'Eggplants, Goji Berries, Peppers, Potatoes, Tomatoes',
    imgURL: require('../assets/images/foods/Nightshades-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Nightshades-Tomatoes-Category-Individual-Icon.png'),
        description: '1 small',
      },
      {
        imgURL: require('../assets/images/foods/Nightshades-Eggplant-individual-icon.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Nightshades-Pepper-individual-icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Carrots, green beans, bok choy, broccoli heads, cucumber, lettuce, kale, spinach, and zucchini',
    avoidThese:
      'Eggplants, Goji Berries, Peppers, Potatoes (not sweet potatoes), Tomatoes',
  },
  Nightshades: {
    name: 'Nightshades',
    description:
      'Eggplants, Goji Berries, Peppers, Potatoes (not sweet potatoes), Tomatoes',
    imgURL: require('../assets/images/foods/Nightshades-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Nightshades-Tomatoes-Category-Individual-Icon.png'),
        description: '1 small',
      },
      {
        imgURL: require('../assets/images/foods/Nightshades-Eggplant-individual-icon.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Nightshades-Pepper-individual-icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Carrots, green beans, bok choy, broccoli heads, cucumber, lettuce, kale, spinach, and zucchini',
    avoidThese:
      'Eggplants, Goji Berries, Peppers, Potatoes (not sweet potatoes), Tomatoes',
  },
  Legumes: {
    name: 'Beans, Peas, & Lentils',
    description:
      'Beans (Black, Garbanzo, Kidney, Lima, Navy, Pinto), Peas (Green, Split), Lentils. etc.',
    imgURL: require('../assets/images/foods/Beans-Peas-Lentils-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Beans_Peas_Lentils-Individual-Icon.png'),
        description: '1/2 cup',
      },
      {
        imgURL: require('../assets/images/foods/Beans_Peas_Lentils-Peas-individual-Icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead:
      'Other protein, packed, plant-based options such as seitan, whole grains (quinoa, oats), low fodmap nuts & seeds (chia seeds, macademia nuts, peanuts, pumpkin seeds, walnuts)',
    avoidThese:
      'Beans (Black, Garbanzo, Kidney, Lima, Navy, Pinto), Peas (Green, Split), Lentils. etc.',
  },
  'Beans, Peas, & Lentils': {
    name: 'Beans, Peas, & Lentils',
    description:
      'Beans (Black, Garbanzo, Kidney, Lima, Navy, Pinto), Peas (Green, Split), Lentils. etc.',
    imgURL: require('../assets/images/foods/Beans-Peas-Lentils-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Beans-Peas-Lentils-Icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead:
      'Other protein, packed, plant-based options such as seitan, whole grains (quinoa, oats), low fodmap nuts & seeds (chia seeds, macademia nuts, peanuts, pumpkin seeds, walnuts)',
    avoidThese:
      'Beans (Black, Garbanzo, Kidney, Lima, Navy, Pinto), Peas (Green, Split), Lentils. etc.',
  },
  'Beans, Peas, ': {
    name: 'Beans, Peas, & Lentils',
    description:
      'Beans (Black, Garbanzo, Kidney, Lima, Navy, Pinto), Peas (Green, Split), Lentils. etc.',
    imgURL: require('../assets/images/foods/Beans-Peas-Lentils-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Beans_Peas_Lentils-Individual-Icon.png'),
        description: '1/2 cup',
      },
      {
        imgURL: require('../assets/images/foods/Beans_Peas_Lentils-Peas-individual-Icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead:
      'Other protein, packed, plant-based options such as seitan, whole grains (quinoa, oats), low fodmap nuts & seeds (chia seeds, macademia nuts, peanuts, pumpkin seeds, walnuts)',
    avoidThese:
      'Beans (Black, Garbanzo, Kidney, Lima, Navy, Pinto), Peas (Green, Split), Lentils. etc.',
  },
  'Wheat Products': {
    name: 'Wheat Products',
    description:
      'Bread (White or Wheat), Bagels, Flour Tortillas, Pasta, & Grains (Cous Cous, Farro, Rye, & Barley), etc.',
    imgURL: require('../assets/images/foods/Wheat-Products-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Wheat-Products-Bread-individual-icon.png'),
        description: '2 slices',
      },
      {
        imgURL: require('../assets/images/foods/Wheat-Products-Pasta-Individual-Icon.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Wheat-Products-Flour-individual-icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead:
      'Gluten-free (bread, pasta), oats, quinoa, rice, rice noodles, etc.',
    avoidThese:
      'Bread (White or Wheat), Bagels, Flour Tortillas, Pasta, & Grains (Cous Cous, Farro, Rye, & Barley), etc.',
  },
  'Distilled Liquor': {
    name: 'Distilled Liquor',
    description: 'Rum, Tequila, Vodka, Other Spirits',
    imgURL: require('../assets/images/foods/Distilled-Liquor-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Distilled-Liquor-Mixed-Drink-individual-icon.png'),
        description: '1.5 oz or 1 shot',
      },
    ],
    eatThisInstead:
      'Plain tap water, sparkling water or flavored drinks like Spindrift',
    avoidThese: 'Rum, Tequila, Vodka, Other Spirits',
  },
  Cheese: {
    name: 'Soft Cheese',
    description:
      'Cottage Cheese, Cream Cheese, Goat, Haloumi, Mozzarella, Ricotta',
    imgURL: require('../assets/images/foods/Soft-Cheese-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Soft-Cheese-Cottage-Cheese-individual-icon.png'),
        description: '2 tbsp',
      },
      // {
      //   imgURL: require('../assets/images/foods/Soft-Cheese-Icon.png'),
      //   description: '1/4 cup'
      // },
      // {
      //   imgURL: require('../assets/images/foods/Soft-Cheese-Icon.png'),
      //   description: '1 tbsp'
      // },
    ],
    eatThisInstead: 'Hard, Aged Cheeses: Parmesan, Swiss, and Cheddars',
    avoidThese:
      'Cottage Cheese, Cream Cheese, Goat, Haloumi, Mozzarella, Ricotta',
  },
  'Soft Cheese': {
    name: 'Soft Cheese',
    description:
      'Cottage Cheese, Cream Cheese, Goat, Haloumi, Mozzarella, Ricotta',
    imgURL: require('../assets/images/foods/Soft-Cheese-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Soft-Cheese-Icon.png'),
        description: '2 tbsp',
      },
      // {
      //   imgURL: require('../assets/images/foods/Soft-Cheese-Icon.png'),
      //   description: '1/4 cup'
      // },
      // {
      //   imgURL: require('../assets/images/foods/Soft-Cheese-Icon.png'),
      //   description: '1 tbsp'
      // },
    ],
    eatThisInstead: 'Hard, Aged Cheeses: Parmesan, Swiss, and Cheddars',
    avoidThese:
      'Cottage Cheese, Cream Cheese, Goat, Haloumi, Mozzarella, Ricotta',
  },
  Cream: {
    name: 'Milk & Cream',
    description:
      "Buttermilk, Milk (Cow's, Sheep's, & Goat), Cream (Sour, Whipped), Ice Cream, etc.",
    imgURL: require('../assets/images/foods/Milk-Cream-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Glass-of-Milk-individual-icon.png'),
        description: '8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Cream-individual-icon.png'),
        description: '2 tbsp',
      },
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Ice-Cream-individual-icon.png'),
        description: '2 scoops or 8 oz',
      },
    ],
    eatThisInstead:
      'Lactose-free dairy products, and low FODMAP plant-based alternatives (almond milk, rice milk, soy milk made from soy protein)',
    avoidThese:
      "Buttermilk, Milk (Cow's, Sheep's, & Goat), Cream (Sour, Whipped), Ice Cream, etc.",
  },
  'Milk ': {
    name: 'Milk & Cream',
    description:
      "Buttermilk, Milk (Cow's, Sheep's, & Goat), Cream (Sour, Whipped), Ice Cream, etc.",
    imgURL: require('../assets/images/foods/Milk-Cream-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Glass-of-Milk-individual-icon.png'),
        description: '8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Cream-individual-icon.png'),
        description: '2 tbsp',
      },
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Ice-Cream-individual-icon.png'),
        description: '2 scoops or 8 oz',
      },
    ],
    eatThisInstead:
      'Lactose-free dairy products, and low FODMAP plant-based alternatives (almond milk, rice milk, soy milk made from soy protein)',
    avoidThese:
      "Buttermilk, Milk (Cow's, Sheep's, & Goat), Cream (Sour, Whipped), Ice Cream, etc.",
  },
  'Milk & Cream': {
    name: 'Milk & Cream',
    description:
      "Buttermilk, Milk (Cow's, Sheep's, & Goat), Cream (Sour, Whipped), Ice Cream, etc.",
    imgURL: require('../assets/images/foods/Milk-Cream-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Glass-of-Milk-individual-icon.png'),
        description: '8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Cream-individual-icon.png'),
        description: '2 tbsp',
      },
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Ice-Cream-individual-icon.png'),
        description: '2 scoops or 8 oz',
      },
    ],
    eatThisInstead:
      'Lactose-free dairy products, and low FODMAP plant-based alternatives (almond milk, rice milk, soy milk made from soy protein)',
    avoidThese:
      "Buttermilk, Milk (Cow's, Sheep's, & Goat), Cream (Sour, Whipped), Ice Cream, etc.",
  },
  'Cultured Dairy': {
    name: 'Yogurt',
    description: 'Traditional (low fat, regular), Greek, Icelandic, Kefir',
    imgURL: require('../assets/images/foods/Milk-Cream-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Milk-Cream-Option-1.png'),
        description: '1 cup or 8 oz',
      },
    ],
    eatThisInstead:
      'Lactose- free yogurts and plant- based yogurts (Almond, Coconut) that have been fortified with calcium & vitamin D',
    avoidThese: 'Traditional (low fat, regular), Greek, Icelandic, Kefir',
  },
  Yogurt: {
    name: 'Yogurt',
    description: 'Traditional (low fat, regular), Greek, Icelandic, Kefir',
    imgURL: require('../assets/images/foods/yogurt.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/yogurt.png'),
        description: '1 cup or 8 oz',
      },
    ],
    eatThisInstead:
      'Lactose- free yogurts and plant- based yogurts (Almond, Coconut) that have been fortified with calcium & vitamin D',
    avoidThese: 'Traditional (low fat, regular), Greek, Icelandic, Kefir',
  },
  Milk: {
    name: 'Plant-Based Dairy',
    description: 'Almond, Coconut, Hemp, Oat, Soy, etc.',
    imgURL: require('../assets/images/foods/Plant-Based-Milks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Plant-Based-Milks-Icon.png'),
        description: '1 glass or 8 oz',
      },
    ],
    eatThisInstead: 'Lactose-free dairy products',
    avoidThese: 'Almond, Coconut, Hemp, Oat, Soy, etc.',
  },
  'Plant-Based Dairy': {
    name: 'Plant-Based Dairy',
    description: 'Almond, Coconut, Hemp, Oat, Soy, etc.',
    imgURL: require('../assets/images/foods/Plant-Based-Milks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Plant-Based-Milks-Icon.png'),
        description: '1 glass or 8 oz',
      },
    ],
    eatThisInstead: 'Lactose-free dairy products',
    avoidThese: 'Almond, Coconut, Hemp, Oat, Soy, etc.',
  },
  Fruit: {
    name: 'Dried Fruit',
    description: 'Dried (Apple, Apricot, Mango, etc.), Prunes, Raisins',
    imgURL: require('../assets/images/foods/Pitted-Fruit-Icon-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Pitted-Fruit-Icon-Option-1.png'),
        description: '12 oz or 1 can',
      },
    ],
    eatThisInstead:
      'Low FODMAP Fresh Fruits: Blueberries, cantaloupe, kiwi fruit (green), mandarin oranges, pineapple, raspberries',
    avoidThese: 'Dried (Apple, Apricot, Mango, etc.), Prunes, Raisins',
  },
  'Dried Fruit': {
    name: 'Dried Fruit',
    description: 'Dried (Apple, Apricot, Mango, etc.), Prunes, Raisins',
    imgURL: require('../assets/images/foods/dried-fruit.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/dried-fruit.png'),
        description: '1/4 c',
      },
    ],
    eatThisInstead:
      'Low FODMAP Fresh Fruits: Blueberries, cantaloupe, kiwi fruit (green), mandarin oranges, pineapple, raspberries',
    avoidThese: 'Dried (Apple, Apricot, Mango, etc.), Prunes, Raisins',
  },
  'Pitted Fruit': {
    name: 'Pitted Fruit',
    description:
      'Apricots, Cherries, Coconuts, Dates, Figs, Mangos, Nectarines, Peaches, Plums',
    imgURL: require('../assets/images/foods/Pitted-Fruit-Icon-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Pitted-Fruit-Peach-individual-icon.png'),
        description: '1 small',
      },
      {
        imgURL: require('../assets/images/foods/Pitted-Fruit-Icon-Option-2.png'),
        description: '1/3 medium avocado',
      },
      {
        imgURL: require('../assets/images/foods/Pitted-Fruit-Cherries-individual-icon.png'),
        description: '20 cherries',
      },
    ],
    eatThisInstead:
      'Low FODMAP Fruits: Blueberries, cantaloupe, kiwi fruit (green), mandarin, orange, pineapple, raspberries',
    avoidThese:
      'Apricots, Cherries, Coconuts, Dates, Figs, Mangos, Nectarines, Peaches, Plums',
  },
  Pomes: {
    name: 'Pomes',
    description: 'Apples, Pears, Quinces',
    imgURL: require('../assets/images/foods/Pomes-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Pomes-Apple-individual-icon.png'),
        description: '1 fruit',
      },
    ],
    eatThisInstead:
      'Low FODMAP fruits: Blueberries, cantaloupe, kiwi fruit (green), mandarin, orange, pineapple, raspberries',
    avoidThese: 'Apples, Pears, Quinces',
  },
  'Nuts/Seeds': {
    name: 'Seeds',
    description: 'Chia, Flax, Hemp, Poppy, Pumpkin, Sesame, Sunflower Seeds',
    imgURL: require('../assets/images/foods/Seeds-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Seeds-Tablespoon-of-seeds-individual-icon.png'),
        description: '1 tbsp',
      },
      {
        imgURL: require('../assets/images/foods/Seeds-Icon.png'),
        description: '4 tbsp or 1/4 cup',
      },
    ],
    eatThisInstead:
      'Other protein, packed, plant-based options such as seitan, whole grains (quinoa, oats), low fodmap nuts (macademia nuts, peanuts, walnuts)',
    avoidThese: 'Chia, Flax, Hemp, Poppy, Pumpkin, Sesame, Sunflower Seeds',
  },
  Seeds: {
    name: 'Seeds',
    description: 'Chia, Flax, Hemp, Poppy, Pumpkin, Sesame, Sunflower Seeds',
    imgURL: require('../assets/images/foods/Seeds-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Seeds-Tablespoon-of-seeds-individual-icon.png'),
        description: '1 tbsp',
      },
      {
        imgURL: require('../assets/images/foods/Seeds-Icon.png'),
        description: '4 tbsp or 1/4 cup',
      },
    ],
    eatThisInstead:
      'Other protein, packed, plant-based options such as seitan, whole grains (quinoa, oats), low fodmap nuts (macademia nuts, peanuts, walnuts)',
    avoidThese: 'Chia, Flax, Hemp, Poppy, Pumpkin, Sesame, Sunflower Seeds',
  },
  Treenuts: {
    name: 'Treenuts',
    description:
      'Almonds, Brazil nuts, Cashews, Hazelnuts, Pistachios, Pecans, Walnuts',
    imgURL: require('../assets/images/foods/Treenuts-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Treenuts-Almonds-individual-icon.png'),
        description: '20 Almonds',
      },
      {
        imgURL: require('../assets/images/foods/Nuts-Cashew-individual-icon.png'),
        description: '20 Cashews',
      },
      {
        imgURL: require('../assets/images/foods/Treenuts-Pistachios-individual-icon.png'),
        description: '30 Pistachios',
      },
    ],
    eatThisInstead:
      'Seeds like pumkpin or sesame. Peanuts are technically a legume, so they can be good swaps too.',
    avoidThese:
      'Almonds, Brazil nuts, Cashews, Hazelnuts, Pistachios, Pecans, Walnuts',
  },
  'Soy Products': {
    name: 'Soy Products',
    description: 'Soy Beans, Tofu, Tempeh, Textured Vegetable Protein (TVP)',
    imgURL: require('../assets/images/foods/Soy-Products-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Soy-Products-Soy-Bean-individual-icon.png'),
        description: '1/2 cup',
      },
      {
        imgURL: require('../assets/images/foods/Soy-Products-Icon.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Soy-Products-Tempeh-individual-icon.png'),
        description: '1 slice',
      },
    ],
    eatThisInstead:
      'Low Fodmap bean options (canned lentils, canned chickpeas), and other protein, packed, plant-based options such as seitan, whole grains (quinoa, oats), low fodmap nuts & seeds (chia seeds, macademia nuts, peanuts, pumpkin seeds, walnuts)',
    avoidThese: 'Soy Beans, Tofu, Tempeh, Textured Vegetable Protein (TVP)',
  },
  'Soy ': {
    name: 'Soy Products',
    description: 'Soy Beans, Tofu, Tempeh, Textured Vegetable Protein (TVP)',
    imgURL: require('../assets/images/foods/Soy-Products-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Soy-Products-Soy-Bean-individual-icon.png'),
        description: '1/2 cup',
      },
      {
        imgURL: require('../assets/images/foods/Soy-Products-Icon.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Soy-Products-Tempeh-individual-icon.png'),
        description: '1 slice',
      },
    ],
    eatThisInstead:
      'Low Fodmap bean options (canned lentils, canned chickpeas), and other protein, packed, plant-based options such as seitan, whole grains (quinoa, oats), low fodmap nuts & seeds (chia seeds, macademia nuts, peanuts, pumpkin seeds, walnuts)',
    avoidThese: 'Soy Beans, Tofu, Tempeh, Textured Vegetable Protein (TVP)',
  },
  Mushrooms: {
    name: 'Mushrooms',
    description: 'Button, Enoki, Portobello, Shiitake, etc.',
    imgURL: require('../assets/images/foods/Mushrooms-Icon-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Mushrooms-Icon-Option-1.png'),
        description: '1 cup',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Carrots, eggplant, green beans, bok choy, broccoli heads, cucumber, lettuce, potato, tomato, kale, spinach, and zucchini',
    avoidThese: 'Button, Enoki, Portobello, Shiitake, etc.',
  },
  Berries: {
    name: 'Berries',
    description: 'Blackberries, Blueberries, Raspberries, Strawberries, etc.',
    imgURL: require('../assets/images/foods/Berries-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Berries-Blackberry-individual-icon.png'),
        description: '2/3 cup',
      },
      {
        imgURL: require('../assets/images/foods/Berries-Blueberries-individual-icon-thicker-line.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Berries-Strawberry-individual-icon.png'),
        description: '6 strawberries',
      },
    ],
    eatThisInstead:
      'Low FODMAP Fruits: Cantaloupe, kiwi, mandarin, orange, pineapple',
    avoidThese: 'Blackberries, Blueberries, Raspberries, Strawberries, etc.',
  },
  'Root Vegetables': {
    name: 'Root Vegetables',
    description: 'Artichoke, Beet, Carrot, Celery, Chicory, etc.',
    imgURL: require('../assets/images/foods/Root-Vegetables-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Root-Vegetables-Beet-Individual-Icon.png'),
        description: '1 small beet',
      },
      {
        imgURL: require('../assets/images/foods/Root-Vegetables-Artichoke-individual-icon.png'),
        description: '1 small artichoke',
      },
      {
        imgURL: require('../assets/images/foods/Root-Vegetable-Celery-individual-icon.png'),
        description: '2 stalks celery',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Eggplant, green beans, bok choy, broccoli heads, cucumber, lettuce, tomato, kale, spinach, and zucchini ',
    avoidThese: 'Artichoke, Beet, Carrot, Celery, Chicory, etc.',
  },
  Melons: {
    name: 'Melons',
    description: 'Canteloupe, Honeydew, Watermelon',
    imgURL: require('../assets/images/foods/Melon-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Melon-Icon.png'),
        description: '1 cup',
      },
    ],
    eatThisInstead:
      'Low FODMAP Fruits: Kiwi fruit (green), mandarin, orange, pineapple, raspberries, blueberries',
    avoidThese: 'Canteloupe, Honeydew, Watermelon',
  },
  'Processed Meat': {
    name: 'Processed Meat',
    description: 'Deli Meats, Hot Dogs, Jerky, Salami, Sausages, etc.',
    imgURL: require('../assets/images/foods/Processed-Meat-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Processed-Meat-Hot-dog-individual-icon.png'),
        description: '1 hot dog',
      },
      {
        imgURL: require('../assets/images/foods/Processed-Meat-Sausage-(individual-icon).png'),
        description: '2 pieces',
      },
    ],
    eatThisInstead:
      'Meat, fish, or poultry that is not pre-marinated or processed (ie. Chicken breasts, steak, pork chop)',
    avoidThese: 'Deli Meats, Hot Dogs, Jerky, Salami, Sausages, etc.',
  },
  'Packaged Snacks': {
    name: 'Packaged Snacks',
    description: 'Chips, Crackers, Pretzels, etc.',
    imgURL: require('../assets/images/foods/Packaged-Snacks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Packaged-Snacks-Icon.png'),
        description: '1 oz',
      },
    ],
    eatThisInstead:
      "Homemade or pre-packaged snack products that don't contain preservatives or ingredients you don't recognize or can't pronounce.",
    avoidThese: 'Chips, Crackers, Pretzels, etc.',
  },
  'Packaged Snacks ': {
    name: 'Packaged Snacks',
    description: 'Chips, Crackers, Pretzels, etc.',
    imgURL: require('../assets/images/foods/Packaged-Snacks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Packaged-Snacks-Icon.png'),
        description: '1 oz',
      },
    ],
    eatThisInstead:
      "Homemade or pre-packaged snack products that don't contain preservatives or ingredients you don't recognize or can't pronounce.",
    avoidThese: 'Chips, Crackers, Pretzels, etc.',
  },
  'Packaged Snacks & Cereal': {
    name: 'Packaged Snacks & Cereal',
    description: 'Chips, Crackers, Pretzels, etc.',
    imgURL: require('../assets/images/foods/Packaged-Snacks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Packaged-Snacks-Icon.png'),
        description: '1 oz',
      },
    ],
    eatThisInstead:
      "Homemade or pre-packaged snack products that don't contain preservatives or ingredients you don't recognize or can't pronounce.",
    avoidThese: 'Chips, Crackers, Pretzels, etc.',
  },
  Sweets: {
    name: 'Sweets',
    description: 'Baked Goods, Candy, Chocolate, Pastries',
    imgURL: require('../assets/images/foods/Sweets-Icon-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Sweets-Pastry-individual-icon.png'),
        description: '1 pastry',
      },
      {
        imgURL: require('../assets/images/foods/Sweets-Chocolate-individual-icon.png'),
        description: '1 oz',
      },
      {
        imgURL: require('../assets/images/foods/Sweets-Candy-individual-icon.png'),
        description: '1 piece',
      },
    ],
    eatThisInstead:
      'Sweet Swaps: Apple & peanut butter, yogurt with fruit & granola, fruit, Nice Cream™, etc.',
    avoidThese: 'Baked Goods, Candy, Chocolate, Pastries',
  },
  'Sweets & Sweeteners': {
    name: 'Sweets & Sweeteners',
    description: 'Baked Goods, Candy, Chocolate, Pastries',
    imgURL: require('../assets/images/foods/Sweets-Icon-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Sweets-Pastry-individual-icon.png'),
        description: '1 pastry',
      },
      {
        imgURL: require('../assets/images/foods/Sweets-Chocolate-individual-icon.png'),
        description: '1 oz',
      },
      {
        imgURL: require('../assets/images/foods/Sweets-Candy-individual-icon.png'),
        description: '1 piece',
      },
    ],
    eatThisInstead:
      'Sweet Swaps: Apple & peanut butter, yogurt with fruit & granola, fruit, Nice Cream™, etc.',
    avoidThese: 'Baked Goods, Candy, Chocolate, Pastries',
  },
  'Pumpkin ': {
    name: 'Pumpkin & Squash',
    description:
      'Butternut Squash, Cucumber, Pumpkin, Spaghetti Squash, Zucchini',
    imgURL: require('../assets/images/foods/Pumpkin-Squash-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Pumpkin_Squash-Pumpkin-individual-icon.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Pumpkin_Squash-Squash-individual-icon.png'),
        description: '2/3 cup',
      },
      {
        imgURL: require('../assets/images/foods/Pumpkin_Squash-Zucchini-individual-icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Carrots, eggplant, green beans, bok choy, broccoli heads, lettuce, potato, tomato, kale, and spinach',
    avoidThese:
      'Butternut Squash, Cucumber, Pumpkin, Spaghetti Squash, Zucchini',
  },
  'Pumpkin & Squash': {
    name: 'Pumpkin & Squash',
    description:
      'Butternut Squash, Cucumber, Pumpkin, Spaghetti Squash, Zucchini',
    imgURL: require('../assets/images/foods/Pumpkin-Squash-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Pumpkin_Squash-Pumpkin-individual-icon.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Pumpkin_Squash-Squash-individual-icon.png'),
        description: '2/3 cup',
      },
      {
        imgURL: require('../assets/images/foods/Pumpkin_Squash-Zucchini-individual-icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Carrots, eggplant, green beans, bok choy, broccoli heads, lettuce, potato, tomato, kale, and spinach',
    avoidThese:
      'Butternut Squash, Cucumber, Pumpkin, Spaghetti Squash, Zucchini',
  },
  'Citrus Fruit': {
    name: 'Citrus Fruit',
    description: 'Grapefruit, Lemon, Lime, Orange, etc.',
    imgURL: require('../assets/images/foods/Citrus-Fruit-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Citrus-Fruit-Lemon-individual-icon.png'),
        description: '1 medium',
      },
    ],
    eatThisInstead:
      'Low FODMAP Fruits: Cantaloupe, kiwi fruit (green), pineapple, raspberries, blueberries',
    avoidThese: 'Grapefruit, Lemon, Lime, Orange, etc.',
  },
  'Starchy Vegetables': {
    name: 'Starchy Vegetables',
    description: 'Corn, Parsnips, Potatoes, Sweet Potatoes, Yams, Yucca',
    imgURL: require('../assets/images/foods/Starchy-Vegetables-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Starchy-Vegetables-Corn-individual-icon.png'),
        description: '1 medium',
      },
      {
        imgURL: require('../assets/images/foods/Starchy-Vegetables-Potato-individual-icon.png'),
        description: '1 medium',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Carrots, eggplant, green beans, bok choy, broccoli heads, cucumber, lettuce, tomato, kale, spinach, and zucchini ',
    avoidThese: 'Corn, Parsnips, Potatoes, Sweet Potatoes, Yams, Yucca',
  },
  'Hard Cheese': {
    name: 'Hard Cheese',
    description: 'Cheddar, Harvarti, Manchego, Parmesan, Swiss',
    imgURL: require('../assets/images/foods/Hard-Cheese-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Hard-Cheese-Individual-Icon.png'),
        description: '2 slices',
      },
    ],
    eatThisInstead: 'Lactose-free or plant-based alternative cheese products',
    avoidThese: 'Cheddar, Harvarti, Manchego, Parmesan, Swiss',
  },
  'Butter, Margarine, & Coconut Oil': {
    name: 'Butter, Margarine, & Coconut Oil',
    description: 'Butter, Margarine, Coconut Oil, Ghee, Lard, etc.',
    imgURL: require('../assets/images/foods/Butter-Margarine-Coconut-Oil-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Butter-Margarine-Coconut-Oil-Individual-icon.png'),
        description: '1 tbsp',
      },
    ],
  },
  'Butter, Margarine, & Coconut Oil  ': {
    name: 'Butter, Margarine, & Coconut Oil',
    description: 'Butter, Margarine, Coconut Oil, Ghee, Lard, etc.',
    imgURL: require('../assets/images/foods/Butter-Margarine-Coconut-Oil-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Butter-Margarine-Coconut-Oil-Individual-icon.png'),
        description: '1 tbsp',
      },
    ],
    eatThisInstead:
      'Spreads or oils that contain healthy fats such as, olive oil, avocado, avocado oil, nut butters, or greek yogurt.',
    avoidThese: 'Butter, Margarine, Coconut Oil, Ghee, Lard, etc.',
  },
  'Butter, Margarine, ': {
    name: 'Butter, Margarine, & Coconut Oil',
    description: 'Butter, Margarine, Coconut Oil, Ghee, Lard, etc.',
    imgURL: require('../assets/images/foods/Butter-Margarine-Coconut-Oil-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Butter-Margarine-Coconut-Oil-Individual-icon.png'),
        description: '1 tbsp',
      },
    ],
    eatThisInstead:
      'Spreads or oils that contain healthy fats such as, olive oil, avocado, avocado oil, nut butters, or greek yogurt.',
    avoidThese: 'Butter, Margarine, Coconut Oil, Ghee, Lard, etc.',
  },
  'Gluten Free Grains': {
    name: 'Gluten Free Grains',
    description: 'Oats, Quinoa, Rice, Sorghum, etc.',
    imgURL: require('../assets/images/foods/GF-Grains-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/GF-Grains-Rice-individual-icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead:
      'Veggie "rice" and "noodles", chia seeds, or nut flours. Examples of substitute items include, cauliflower pizza crust, zoodles, and chia pudding.',
    avoidThese: 'Oats, Quinoa, Rice, Sorghum, etc.',
  },
  'Nut Butters': {
    name: 'Nut Butters',
    description: 'Almond, Hazelnut, Peanut, Pecan Butters, etc.',
    imgURL: require('../assets/images/foods/Nut-Butters-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Nut-Butters-Icon.png'),
        description: '2 tbsp',
      },
    ],
    eatThisInstead: 'Seed butters like sunflower seed butter or tahini ',
    avoidThese: 'Almond, Hazelnut, Peanut, Pecan Butters, etc.',
  },
  'Leafy Greens': {
    name: 'Leafy Greens',
    description: 'Arugula, Lettuce, Mircogreens, Spinach, etc.',
    imgURL: require('../assets/images/foods/Leafy-Greens-Category-Icon-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Leafy-Greens-Spinach-Category-or-Individual-icon.png'),
        description: '1.5 cup',
      },
      {
        imgURL: require('../assets/images/foods/Leafy-Greens-or-Kale.png'),
        description: '2 cup',
      },
      {
        imgURL: require('../assets/images/foods/Leafy-Greens-Lettuce-individual-icon.png'),
        description: '1.5 cup',
      },
    ],
    eatThisInstead:
      'Low FODMAP Vegetables: Carrots, eggplant, green beans, broccoli heads, cucumber, potato, tomato, and zucchini',
    avoidThese: 'Arugula, Lettuce, Mircogreens, Spinach, etc.',
  },
  'Alternative Flour (Gluten Free)': {
    name: 'Alternative Flour (Gluten Free)',
    description: 'Almond, Coconut, Corn, Quinoa, Rice Flours',
    imgURL: require('../assets/images/foods/Alternative-Flour-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Alternative-Flour-Individual-Icon.png'),
        description: '2/3 cup',
      },
      {
        imgURL: require('../assets/images/foods/Alternative-Flour-Icon.png'),
        description: '1/2 cup',
      },
    ],
    eatThisInstead: 'I am sorry, there are no good swaps at this time.',
    avoidThese: 'Almond, Coconut, Corn, Quinoa, Rice Flours',
  },
  'Jams ': {
    name: 'Jams & Jellies',
    description:
      'Blueberry, Grape, Mixed Berry, Strawberry Jams or Jellies, etc.',
    imgURL: require('../assets/images/foods/Jams-Jellies-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Jams-Jellies-Individual-Icon-Option-2.png'),
        description: '2 tbsp',
      },
    ],
    eatThisInstead: 'Fresh fruit (ie. Put fresh strawberries on your PB&J)',
    avoidThese:
      'Blueberry, Grape, Mixed Berry, Strawberry Jams or Jellies, etc.',
  },
  'Jams & Jellies': {
    name: 'Jams & Jellies',
    description:
      'Blueberry, Grape, Mixed Berry, Strawberry Jams or Jellies, etc.',
    imgURL: require('../assets/images/foods/Jams-Jellies-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Jams-Jellies-Individual-Icon-Option-2.png'),
        description: '2 tbsp',
      },
    ],
    eatThisInstead: 'Fresh fruit (ie. Put fresh strawberries on your PB&J)',
    avoidThese:
      'Blueberry, Grape, Mixed Berry, Strawberry Jams or Jellies, etc.',
  },
  'Sugar Free Gum': {
    name: 'Sugar Free Gum',
    description: 'Sugar Free Gum',
    imgURL: require('../assets/images/foods/Sugar-Free-Gum-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Sugar-Free-Gum-Individual-Icon.png'),
        description: '1 stick',
      },
    ],
    eatThisInstead: 'I am sorry, there are no good swaps at this time.',
    avoidThese: 'Sugar Free Gum',
  },
  'Frozen Meals (ready-to-go)': {
    name: 'Frozen Meals (ready-to-go)',
    description: 'Bagel Bites, Frozen Pizzas, Lean Cusine, etc.',
    imgURL: require('../assets/images/foods/Frozen-Food-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Frozen-Food-Icon.png'),
        description: '1 meal or 1 serving',
      },
    ],
    eatThisInstead: 'Fresh meals or meal prep and freeze yourself',
    avoidThese: 'Bagel Bites, Frozen Pizzas, Lean Cusine, etc.',
  },
  'Cooking Oils (Except Olive & Canola)': {
    name: 'Cooking Oils (Except Olive & Canola)',
    description:
      'Avocado, Corn, Grapeseed, Safflower, Sesame, Sunflower Oils, etc.',
    imgURL: require('../assets/images/foods/Cooking-Oils-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Cookin-Oils-except-olive-and-canola-category-option-1.png'),
        description: '1 tbsp',
      },
    ],
    eatThisInstead:
      'Low saturated fat is the goal here: swap for olive or canola oil or try steaming or boiling cooking methods.',
    avoidThese:
      'Avocado, Corn, Grapeseed, Safflower, Sesame, Sunflower Oils, etc.',
  },
  'Olive ': {
    name: 'Olive & Canola Oil',
    description: 'Canola & Olive Oils',
    imgURL: require('../assets/images/foods/Olive-Canola-Oil-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Olive-Oil-Category-Individual-Icon.png'),
        description: '1 tbsp',
      },
    ],
    eatThisInstead:
      'I am sorry, there are no good food swaps at this time. Low fat is the goal here, so try steaming or boiling cooking methods.',
    avoidThese: 'Canola & Olive Oils',
  },
  'Olive & Canola Oil': {
    name: 'Olive & Canola Oil',
    description: 'Canola & Olive Oils',
    imgURL: require('../assets/images/foods/Olive-Canola-Oil-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Olive-Oil-Category-Individual-Icon.png'),
        description: '1 tbsp',
      },
    ],
    eatThisInstead:
      'I am sorry, there are no good food swaps at this time. Low fat is the goal here, so try steaming or boiling cooking methods.',
    avoidThese: 'Canola & Olive Oils',
  },
  'Packaged Condiments': {
    name: 'Packaged Condiments',
    description: 'BBQ Sauce, Ketchup, Mayo, Ranch, Salad Dressings, etc.',
    imgURL: require('../assets/images/foods/Condiments-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Condiments-Ketchup-individual-icon.png'),
        description: '1 tbsp',
      },
      {
        imgURL: require('../assets/images/foods/Condiments-Mustard-individual.png'),
        description: '1 tsp',
      },
      {
        imgURL: require('../assets/images/foods/Condiments-BBQ-Sauce-individual-icon.png'),
        description: '2 tbsp',
      },
    ],
    eatThisInstead:
      'Homemade condiments are always the way to go. Be sure to not include any high fodmap foods such as, onions and garlic.',
    avoidThese: 'BBQ Sauce, Ketchup, Mayo, Ranch, Salad Dressings, etc.',
  },
  'Red Meat': {
    name: 'Red Meat',
    description: 'Beef, Lamb, Pork',
    imgURL: require('../assets/images/foods/Red-Meat-Icon-Option-1.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Red-Meat-Steak-individual-icon.png'),
        description: '4 oz or 1 small fillet',
      },
    ],
    eatThisInstead:
      'Swap cuts of red meat for chicken, turkey or fish instead.',
    avoidThese: 'Beef, Lamb, Pork',
  },
  Sweetener: {
    name: 'Sweeteners',
    description: 'Sweeteners (Natural, Refined, Artificial)',
    imgURL: require('../assets/images/foods/Sweeteners-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Sweeteners-Honey-individual-icon.png'),
        description: '1 tbsp',
      },
      {
        imgURL: require('../assets/images/foods/Sweeteners-Sugar-individual-Icon.png'),
        description: '1/4 cup',
      },
      {
        imgURL: require('../assets/images/foods/Sweeteners-Sugar-Packet-individual-icon.png'),
        description: '1 pkt',
      },
    ],
    eatThisInstead: 'Cinnamon, cocoa powder, mint or vanilla ',
    avoidThese:
      "Agave, Honey, Molasses, Stevia, Brown Sugar, White Sugar, High Fructose Corn Syrup, Splenda™, Sweet n' Low™, etc.",
  },
  Sweeteners: {
    name: 'Sweeteners',
    description:
      "Agave, Honey, Molasses, Stevia, Brown Sugar, White Sugar, High Fructose Corn Syrup, Splenda™, Sweet n' Low™, etc.",
    imgURL: require('../assets/images/foods/Sweeteners-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Sweeteners-Honey-individual-icon.png'),
        description: '1 tbsp',
      },
      {
        imgURL: require('../assets/images/foods/Sweeteners-Sugar-individual-Icon.png'),
        description: '1/4 cup',
      },
      {
        imgURL: require('../assets/images/foods/Sweeteners-Sugar-Packet-individual-icon.png'),
        description: '1 pkt',
      },
    ],
    eatThisInstead: 'Cinnamon, cocoa powder, mint or vanilla ',
    avoidThese:
      "Agave, Honey, Molasses, Stevia, Brown Sugar, White Sugar, High Fructose Corn Syrup, Splenda™, Sweet n' Low™, etc.",
  },
  'Sweeteners (Natural, Refined, Artificial)': {
    name: 'Sweeteners',
    description:
      "Agave, Honey, Molasses, Stevia, Brown Sugar, White Sugar, High Fructose Corn Syrup, Splenda™, Sweet n' Low™, etc.",
    imgURL: require('../assets/images/foods/Sweeteners-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Sweeteners-Honey-individual-icon.png'),
        description: '1 tbsp',
      },
      {
        imgURL: require('../assets/images/foods/Sweeteners-Sugar-individual-Icon.png'),
        description: '1/4 cup',
      },
      {
        imgURL: require('../assets/images/foods/Sweeteners-Sugar-Packet-individual-icon.png'),
        description: '1 pkt',
      },
    ],
    eatThisInstead: 'Cinnamon, cocoa powder, mint or vanilla ',
    avoidThese:
      "Agave, Honey, Molasses, Stevia, Brown Sugar, White Sugar, High Fructose Corn Syrup, Splenda™, Sweet n' Low™, etc.",
  },
  Tea: {
    name: 'Herbal Tea',
    description: 'Chamomile, Dandelion, Fennel, etc.',
    imgURL: require('../assets/images/foods/Herbal-Tea-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Herbal-Tea-Icon.png'),
        description: '1 cup',
      },
    ],
    eatThisInstead: 'Hot water with lemon, ginger, or turmeric.',
    avoidThese: 'Chamomile, Dandelion, Fennel, etc.',
  },
  'Herbal Tea': {
    name: 'Herbal Tea',
    description: 'Chamomile, Dandelion, Fennel, etc.',
    imgURL: require('../assets/images/foods/Herbal-Tea-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Herbal-Tea-Icon.png'),
        description: '1 cup',
      },
    ],
    eatThisInstead: 'Hot water with lemon, ginger, or turmeric.',
    avoidThese: 'Chamomile, Dandelion, Fennel, etc.',
  },
  Coffee: {
    name: 'Caffeinated Tea & Coffee',
    description: 'Caffeinated Coffee, Tea (Black, Green, & White)',
    imgURL: require('../assets/images/foods/Caffeinated-Beverages-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Caffeinated-Beverages-Coffee-tea-Cup-individual-icon.png'),
        description: '1 cup or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Caffeinated-Beverages-Icon-Option-2.png'),
        description: '1 cup or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Caffeinated-Beverages-Espresso-individual-icon.png'),
        description: '1 shot or 1.5 oz',
      },
    ],
    eatThisInstead: 'Caffeine-free/decaf coffees or tea',
    avoidThese: 'Caffeinated Coffee, Tea (Black, Green, & White)',
  },
  'Caffeinated Tea ': {
    name: 'Caffeinated Tea & Coffee',
    description: 'Caffeinated Coffee, Tea (Black, Green, & White)',
    imgURL: require('../assets/images/foods/Caffeinated-Beverages-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Caffeinated-Beverages-Coffee-tea-Cup-individual-icon.png'),
        description: '1 cup or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Caffeinated-Beverages-Icon-Option-2.png'),
        description: '1 cup or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Caffeinated-Beverages-Espresso-individual-icon.png'),
        description: '1 shot or 1.5 oz',
      },
    ],
    eatThisInstead: 'Caffeine-free/decaf coffees or tea',
    avoidThese: 'Caffeinated Coffee, Tea (Black, Green, & White)',
  },
  'Caffeinated Tea & Coffee': {
    name: 'Caffeinated Tea & Coffee',
    description: 'Caffeinated Coffee, Tea (Black, Green, & White)',
    imgURL: require('../assets/images/foods/Caffeinated-Beverages-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Caffeinated-Beverages-Coffee-tea-Cup-individual-icon.png'),
        description: '1 cup or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Caffeinated-Beverages-Icon-Option-2.png'),
        description: '1 cup or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Caffeinated-Beverages-Espresso-individual-icon.png'),
        description: '1 shot or 1.5 oz',
      },
    ],
    eatThisInstead: 'Caffeine-free/decaf coffees or tea',
    avoidThese: 'Caffeinated Coffee, Tea (Black, Green, & White)',
  },
  Soda: {
    name: 'Soda & Energy Drinks',
    description:
      'Coke, Diet Coke, Pepsi, Dr. Pepper, Mountain Dew, Sprite, Red Bull, Monster, etc.',
    imgURL: require('../assets/images/foods/Soda-Energy-Drinks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Soda-Energy-Drinks-Icon.png'),
        description: '1 can or 12 oz',
      },
    ],
  },
  'Soda ': {
    name: 'Soda & Energy Drinks',
    description:
      'Coke™, Diet Coke™, Pepsi™, Dr. Pepper™, Mountain Dew™, Sprite™, Red Bull™, Monster™, etc.',
    imgURL: require('../assets/images/foods/Soda-Energy-Drinks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Soda-Energy-Drinks-Icon.png'),
        description: '1 can or 12 oz',
      },
    ],
    eatThisInstead:
      'Plain tap water, sparkling water or flavored drinks like Spindrift',
    avoidThese:
      'Coke™, Diet Coke™, Pepsi™, Dr.Pepper™, Mountain Dew™, Sprite™, Red Bull™, Monster™, etc.',
  },
  'Soda & Energy Drinks': {
    name: 'Soda & Energy Drinks',
    description:
      'Coke™, Diet Coke™, Pepsi™, Dr. Pepper™, Mountain Dew™, Sprite™, Red Bull™, Monster™, etc.',
    imgURL: require('../assets/images/foods/Soda-Energy-Drinks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Soda-Energy-Drinks-Icon.png'),
        description: '1 can or 12 oz',
      },
    ],
    eatThisInstead:
      'Plain tap water, sparkling water or flavored drinks like Spindrift',
    avoidThese:
      'Coke™, Diet Coke™, Pepsi™, Dr.Pepper™, Mountain Dew™, Sprite™, Red Bull™, Monster™, etc.',
  },
  'Spicy Food': {
    name: 'Spicy Food',
    description:
      'Cayenne, Chilies (jalapeno, habanero, other), Chili Flakes, Hot Sauce, etc.',
    imgURL: require('../assets/images/foods/Spicy-Food-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Spicy-Food-Mild-Category-Icon.png'),
        description: 'Mild heat',
      },
      {
        imgURL: require('../assets/images/foods/Spicy-Food-Medium-Category-Icon.png'),
        description: 'Medium heat',
      },
      {
        imgURL: require('../assets/images/foods/Spicy-Food-Hot-Category-Icon.png'),
        description: 'Hot heat',
      },
    ],
    eatThisInstead:
      'To add some flavor: Fresh green herbs such as mint, oregano, parsely, rosemary, etc.',
    avoidThese:
      'Cayenne, Chilies (jalapeno, habanero, other), Chili Flakes, Hot Sauce, etc.',
  },
  'Fried Food': {
    name: 'Fried & Fast Food',
    description:
      'French Fries, Fried Chicken, Onion Rings, Pizza, Potato Chips, etc.',
    imgURL: require('../assets/images/foods/Fried-Fast-Food-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Fried-Fast-Food-Fries-individual-icon.png'),
        description: '12 fries',
      },
      {
        imgURL: require('../assets/images/foods/Fried-Fast-Food-Doughnut-individual-icon.png'),
        description: '1 donut',
      },
      {
        imgURL: require('../assets/images/foods/Fried-Fast-Food-Pizza-individual-icon.png'),
        description: '1 slice',
      },
    ],
    eatThisInstead: 'Baked, broiled, grilled, sauteed, or air fried prepartion',
    avoidThese:
      'French Fries, Fried Chicken, Onion Rings, Pizza, Potato Chips, etc.',
  },
  'Fried ': {
    name: 'Fried & Fast Food',
    description:
      'French Fries, Fried Chicken, Onion Rings, Pizza, Potato Chips, etc.',
    imgURL: require('../assets/images/foods/Fried-Fast-Food-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Fried-Fast-Food-Fries-individual-icon.png'),
        description: '12 fries',
      },
      {
        imgURL: require('../assets/images/foods/Fried-Fast-Food-Doughnut-individual-icon.png'),
        description: '1 donut',
      },
      {
        imgURL: require('../assets/images/foods/Fried-Fast-Food-Pizza-individual-icon.png'),
        description: '1 slice',
      },
    ],
    eatThisInstead: 'Baked, broiled, grilled, sauteed, or air fried prepartion',
    avoidThese:
      'French Fries, Fried Chicken, Onion Rings, Pizza, Potato Chips, etc.',
  },
  'Fried & Fast Food': {
    name: 'Fried & Fast Food',
    description:
      'French Fries, Fried Chicken, Onion Rings, Pizza, Potato Chips, etc.',
    imgURL: require('../assets/images/foods/Fried-Fast-Food-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Fried-Fast-Food-Fries-individual-icon.png'),
        description: '12 fries',
      },
      {
        imgURL: require('../assets/images/foods/Fried-Fast-Food-Doughnut-individual-icon.png'),
        description: '1 donut',
      },
      {
        imgURL: require('../assets/images/foods/Fried-Fast-Food-Pizza-individual-icon.png'),
        description: '1 slice',
      },
    ],
    eatThisInstead: 'Baked, broiled, grilled, sauteed, or air fried prepartion',
    avoidThese:
      'French Fries, Fried Chicken, Onion Rings, Pizza, Potato Chips, etc.',
  },
  Beer: {
    name: 'Beer, Cider, & Hard Seltzer',
    description:
      'Ale, Lager, Porter, Stout, Hard Cider, Hard Seltzers (White Claw™, Truly™, etc.)',
    imgURL: require('../assets/images/foods/Beer-Cider-Hard-Seltzer-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Beer-Cider-Hard-Seltzer-Beer-individual-icon.png'),
        description: '12 oz or 1 can',
      },
    ],
    eatThisInstead: 'Caffeine-free/decaf coffees or tea',
    avoidThese:
      'Ale, Lager, Porter, Stout, Hard Cider, Hard Seltzers(White Claw™, Truly™, etc.)',
  },
  Alcohol: {
    name: 'Alcohol',
    description:
      'Distilled Liquor, Wine, Beer, Cider, & Hard Seltzer',
    imgURL: require('../assets/images/foods/Beer-Cider-Hard-Seltzer-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Beer-Cider-Hard-Seltzer-Beer-individual-icon.png'),
        description: '12 oz or 1 can',
      },
    ],
    eatThisInstead: 'Caffeine-free/decaf coffees or tea',
    avoidThese:
      'Ale, Lager, Porter, Stout, Hard Cider, Hard Seltzers(White Claw™, Truly™, etc.)',
  },
  'Beer, Cider, ': {
    name: 'Beer, Cider, & Hard Seltzer',
    description:
      'Ale, Lager, Porter, Stout, Hard Cider, Hard Seltzers (White Claw™, Truly™, etc.)',
    imgURL: require('../assets/images/foods/Beer-Cider-Hard-Seltzer-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Beer-Cider-Hard-Seltzer-Beer-individual-icon.png'),
        description: '12 oz or 1 can',
      },
    ],
    eatThisInstead: 'Caffeine-free/decaf coffees or tea',
    avoidThese:
      'Ale, Lager, Porter, Stout, Hard Cider, Hard Seltzers(White Claw™, Truly™, etc.)',
  },
  'Beer, Cider, & Hard Seltzer': {
    name: 'Beer, Cider, & Hard Seltzer',
    description:
      'Ale, Lager, Porter, Stout, Hard Cider, Hard Seltzers (White Claw™, Truly™, etc.)',
    imgURL: require('../assets/images/foods/Beer-Cider-Hard-Seltzer-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Beer-Cider-Hard-Seltzer-Beer-individual-icon.png'),
        description: '12 oz or 1 can',
      },
    ],
    eatThisInstead: 'Caffeine-free/decaf coffees or tea',
    avoidThese:
      'Ale, Lager, Porter, Stout, Hard Cider, Hard Seltzers(White Claw™, Truly™, etc.)',
  },
  'Cereal ': {
    name: 'Cereal & Granola (Including Bars)',
    description:
      'Cereal (Cocoa Krispies, Cornflakes, Frosted Flakes, Rice Krispies, Speical K), Granola/Muesli, Other Granola Bars',
    imgURL: require('../assets/images/foods/Cereal-Granola-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Cereal-Granola-Cereal-individual-icon.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Cereal-Granola-Granola-individual-icon.png'),
        description: '1/2 cup',
      },
      {
        imgURL: require('../assets/images/foods/Cereal-Granola-Granola-bar-individual-icon.png'),
        description: '1 bar',
      },
    ],
    eatThisInstead: 'Oats and oatmeal based-products without added sugars.',
    avoidThese:
      'Cereal(Cocoa Krispies™, Cornflakes™, Frosted Flakes™, Rice Krispies™, Speical K™), Granola/ Muesli, Other Granola Bars',
  },
  'Cereal & Granola (Including Bars)': {
    name: 'Cereal & Granola (Including Bars)',
    description:
      'Cereal (Cocoa Krispies, Cornflakes, Frosted Flakes, Rice Krispies, Special K), Granola/Muesli, Other Granola Bars',
    imgURL: require('../assets/images/foods/Cereal-Granola-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Cereal-Granola-Cereal-individual-icon.png'),
        description: '1 cup',
      },
      {
        imgURL: require('../assets/images/foods/Cereal-Granola-Granola-individual-icon.png'),
        description: '1/2 cup',
      },
      {
        imgURL: require('../assets/images/foods/Cereal-Granola-Granola-bar-individual-icon.png'),
        description: '1 bar',
      },
    ],
    eatThisInstead: 'Oats and oatmeal based-products without added sugars.',
    avoidThese:
      'Cereal(Cocoa Krispies™, Cornflakes™, Frosted Flakes™, Rice Krispies™, Speical K™), Granola/ Muesli, Other Granola Bars',
  },
  Wine: {
    name: 'Wine',
    description: 'Red, White, Rose, Sparkling',
    imgURL: require('../assets/images/foods/Wine-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Wine-Individual-Icon.png'),
        description: '5 oz or 1 glass',
      },
    ],
    eatThisInstead:
      'Plain tap water, sparkling water or flavored drinks like Spindrift™',
    avoidThese: 'Red, White, Rose, Sparkling',
  },
  'Juice ': {
    name: 'Juice & Sports Drinks',
    description:
      'Juice (Apple, Orange, Cranberry, Grape, etc.), Sports Drinks (Gatorade, Powerade), Coconut Water',
    imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Icon.png'),
        description: '1 glass or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Coconut-Water-individual-icon.png'),
        description: '1 glass or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Sport-Drink-individual-icon.png'),
        description: '1 bottle or 20 oz',
      },
    ],
    eatThisInstead:
      'Plain tap water, sparkling water or flavored drinks like Spindrift™',
    avoidThese:
      'Juice(Apple, Orange, Cranberry, Grape, etc.), Sports Drinks(Gatorade™, Powerade™), Coconut Water',
  },
  'Juice & Sports Drinks': {
    name: 'Juice & Sports Drinks',
    description:
      'Juice (Apple, Orange, Cranberry, Grape, etc.), Sports Drinks (Gatorade, Powerade), Coconut Water',
    imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Icon.png'),
        description: '1 glass or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Coconut-Water-individual-icon.png'),
        description: '1 glass or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Sport-Drink-individual-icon.png'),
        description: '1 bottle or 20 oz',
      },
    ],
    eatThisInstead:
      'Plain tap water, sparkling water or flavored drinks like Spindrift™',
    avoidThese:
      'Juice(Apple, Orange, Cranberry, Grape, etc.), Sports Drinks(Gatorade™, Powerade™), Coconut Water',
  },
  'Sweetened Beverages': {
    name: 'Sweetened Beverages',
    description:
      'Juice (Apple, Orange, Cranberry, Grape, etc.), Sports Drinks (Gatorade, Powerade), Coconut Water',
    imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Icon.png'),
    servingSizes: [
      {
        imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Icon.png'),
        description: '1 glass or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Coconut-Water-individual-icon.png'),
        description: '1 glass or 8 oz',
      },
      {
        imgURL: require('../assets/images/foods/Juice-Sports-Drinks-Sport-Drink-individual-icon.png'),
        description: '1 bottle or 20 oz',
      },
    ],
    eatThisInstead:
      'Plain tap water, sparkling water or flavored drinks like Spindrift™',
    avoidThese:
      'Juice(Apple, Orange, Cranberry, Grape, etc.), Sports Drinks(Gatorade™, Powerade™), Coconut Water',
  },
};
