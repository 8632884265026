import {
  VStack,
  Image,
  Text,
  Button,
  Divider,
  Heading,
  Box,
  HStack,
} from 'native-base';
import PreviewText from './PreviewText';
import { LearningHubScreen } from '../../models/LearningHubContent';
import { CircledCheckSVG, TimelapseSVG } from '../CustomSVGs';

const PreviewScreen = ({
  screen,
  sectionTitle,
  onCtaClick,
}: {
  sectionTitle?: string;
  screen: LearningHubScreen;
  onCtaClick: () => void;
}) => {
  return (
    <VStack
      w={350}
      maxW={350}
      minH={500}
      mx={'auto'}
      my={8}
      p={4}
      borderWidth={1}
      borderRadius={8}
      borderStyle={'solid'}
      borderColor={'warmGray.300'}
    >
      {sectionTitle && (
        <>
          <Text>{sectionTitle}</Text>
          <Divider />
        </>
      )}
      {screen?.image_url && (
        <Image
          mt={4}
          zIndex={1}
          mx={0}
          resizeMode="contain"
          source={{
            uri: screen.image_url,
          }}
          style={{ width: '100%', height: 300 }}
        />
      )}
      {screen.pre_title && (
        <Text fontWeight={'400'} mt="1rem">
          {screen.pre_title}
        </Text>
      )}
      {screen.title && <Heading fontSize={36}>{screen.title}</Heading>}
      {screen.post_title && (
        <HStack>
          <TimelapseSVG size={23} />
          <Text
            fontSize={'md'}
            mb={5}
            ml={2}
            fontWeight={'400'}
            textAlign={'center'}
          >
            {screen.post_title}
          </Text>
        </HStack>
      )}
      {screen.text && <PreviewText text={screen.text} />}
      {screen.sub_text && <PreviewText text={screen.sub_text} />}
      {screen.list_items?.map((item, i) => {
        return (
          <Box key={i} maxW="100%" m={0} mb={6}>
            <HStack maxW="100%">
              <CircledCheckSVG size={'28px'} />
              <PreviewText key={i} text={item} list />
            </HStack>
          </Box>
        );
      })}
      {screen.cta && <Button onPress={onCtaClick}>{screen.cta}</Button>}
    </VStack>
  );
};

export default PreviewScreen;
