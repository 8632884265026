import { useState } from 'react';
import {
  Input,
  FormControl,
  Box,
  Text,
  Button,
  Spinner,
  Link,
  VStack,
} from 'native-base';

import { useAffiliateLookup } from '../../api/affiliates/useAffiliateLookup';

const SearchResults = ({ results, onSelect, onClear }) => {
  if (results?.length === 0) {
    return (
      <VStack my={4} height={'40px'} alignItems={'center'}>
        <Text mb={2}>Sorry, no results found</Text>
        <Link
          _text={{ textTransform: 'lowercase', fontSize: 13 }}
          onMouseDown={(e) => e.preventDefault()}
          onPress={onClear}
        >
          Clear Search
        </Link>
      </VStack>
    );
  }

  return results?.map((affil) => (
    <Button
      key={affil.utm_source}
      justifyContent={'flex-start'}
      paddingLeft={'12px'}
      minHeight={'44px'}
      onMouseDown={(e) => e.preventDefault()}
      onPress={() => onSelect(affil)}
      borderRadius={'none'}
      backgroundColor={'white'}
      borderColor={'coolGray.100'}
      borderWidth={1}
      _hover={{
        backgroundColor: 'coolGray.100',
      }}
      overflow={'hidden'}
    >
      <Text
        color="black"
        fontWeight={500}
        maxWidth={310}
        textOverflow={'ellipsis'}
      >
        {affil.name}
      </Text>
    </Button>
  ));
};

const SignUpInfoAffiliateLookup = ({
  affiliateName,
  selectedAffiliate,
  onAffilSelection,
  onClear,
  onFieldChange,
  isMobile,
}) => {
  const [hideSearchResults, setHideSearchResults] = useState(true);
  const [showMissingAffilMessage, setShowMissingAffilMessage] = useState(false);

  const {
    affiliates: affiliatesSearchResults,
    isInitialLoading: isAffiliateLookupLoading,
  } = useAffiliateLookup({
    searchString: affiliateName || '',
  });

  const onSearchClear = () => {
    setShowMissingAffilMessage(false);
    setHideSearchResults(true);
    onClear();
  };

  return (
    <Box mt={4} maxW={isMobile ? '100%' : '70%'}>
      <FormControl.Label>Your health plan</FormControl.Label>

      <FormControl>
        <Input
          autoComplete={'off'}
          minWidth="200"
          maxW={'100%'}
          type="text"
          testID={'signup-affiliate-input'}
          value={affiliateName}
          onFocus={() => setHideSearchResults(false)}
          onBlur={() => {
            setHideSearchResults(true);
            if (!selectedAffiliate && affiliateName) {
              setShowMissingAffilMessage(true);
            }
          }}
          onChangeText={(e) => {
            setHideSearchResults(false);
            setShowMissingAffilMessage(false);
            onFieldChange(e);
          }}
        />
        {!selectedAffiliate && !hideSearchResults && (
          <Box
            minWidth="200"
            w={'100%'}
            maxHeight={'200'}
            overflowY={'auto'}
            mt={'-15px'}
            borderColor={'black'}
            borderTopRadius={'none'}
            borderRadius={'sm'}
            backgroundColor={'white'}
            position={'absolute'}
            top={'64px'}
          >
            {isAffiliateLookupLoading ? (
              <Spinner my={4} />
            ) : (
              <SearchResults
                results={affiliatesSearchResults}
                onSelect={(affil) => {
                  onAffilSelection(affil);
                  setHideSearchResults(true);
                }}
                onClear={onSearchClear}
              />
            )}
          </Box>
        )}
        {showMissingAffilMessage && (
          <Text color="red.500" mt={4}>
            Please select a valid health plan or{' '}
            <Link
              _text={{ textTransform: 'lowercase', fontSize: 13 }}
              onPress={onSearchClear}
            >
              Clear your search
            </Link>
          </Text>
        )}
      </FormControl>
    </Box>
  );
};

export default SignUpInfoAffiliateLookup;
