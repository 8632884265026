import { Heading, Box, VStack } from "native-base";
import { FORM_ACTIONS } from "../../../../utils/formActions.js"
import CustomSlider from "../../../Molecules/CustomSlider.js";
import CustomSliderLabels from "../../../Molecules/CustomSliderLabels.js";

function CrohnsViewOne({ formData, dispatch }) {
  const assessmentTrack = 'onboarding-crohns'
  const assessmentObject = formData[assessmentTrack]
  const setRangeValue = (data) => {
    dispatch({ type: FORM_ACTIONS.UPDATE_FORM_FIELD, fieldName: 'onboarding-crohns-1', assessment: assessmentTrack, payload: data })
  }
  const setPainSeverity = (data) => {
    dispatch({ type: FORM_ACTIONS.UPDATE_FORM_FIELD, fieldName: 'onboarding-crohns-2', assessment: assessmentTrack, payload: data })
  }
  const wellBeingResponse = (data) => {
    dispatch({ type: FORM_ACTIONS.UPDATE_FORM_FIELD, fieldName: 'onboarding-crohns-3', assessment: assessmentTrack, payload: data })
  }

  return (
    <VStack w={{base: '100%'}}>
      <Heading fontSize={'3xl'} mb={'2'}>Tell us more about your symptoms.</Heading>

      <h3>In an average week, how many liquid or soft stools do you have (per day)?</h3>
      <Box mb={4} px={'14px'}>
        <CustomSlider value={assessmentObject['onboarding-crohns-1']} setSliderValue={setRangeValue} minValue={0} maxValue={10}/>
        <CustomSliderLabels labels={['', '', '']} />
      </Box>

      <h3>In an average week, how severe was your abdominal pain?</h3>
      <Box mb={4} px={'14px'}>
        <CustomSlider value={assessmentObject['onboarding-crohns-2']} setSliderValue={setPainSeverity} minValue={0} maxValue={10} />
        <CustomSliderLabels labels={['No Pain', 'Somewhat Severe', 'Very Severe']}/>
      </Box>

      <h3>In an average week, how would you rate your general well being?</h3>
      <Box mb={4} px={'14px'}>
        <CustomSlider value={assessmentObject['onboarding-crohns-3']} setSliderValue={wellBeingResponse} minValue={0} maxValue={10} />
        <CustomSliderLabels labels={['Great', 'Very Poor', 'Terrible']}/>
      </Box>
    </VStack>
  )
}

export default CrohnsViewOne;