import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi, useAuth } from '../../../hooks';
import { User } from '../../../models/user';

export const useUpdateUser = (userId: string) => {
  const queryClient = useQueryClient();
  const { api } = useApi();
  const { userToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async (payload: Partial<User>) => {
      const route = `/private/coach/users/${userId}`;

      return await api({
        route,
        token: userToken,
        method: 'PATCH',
        payload,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Coach-Override': userId,
        },
      });
    },

    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['hc-recommended-foods'] }),
  });

  return { mutation };
};
