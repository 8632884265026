// WHOLE FILE COPIED STRAIGHT FROM mobile app - /common/Content/utils
// We parse these from text strings and pass back an object the appropriate prop into a <Text /> component

// Each key/value pair represents a prop/tag of a style.
const tags = ['bold', 'italic'] as const
type Tag = typeof tags[number]

const tagRegex = new RegExp(`(</?(?:${tags.join('|')})>)`, 'gm');

const buildTag = (tagKey: string, isOpenTag = true) => {
  return isOpenTag ? `<${tagKey}>` : `</${tagKey}>`;
};

const getPropTag = (tag: string) => {
  const propKey = tags.find(
    (tagKey) => buildTag(tagKey) === tag || buildTag(tagKey, false) === tag
  );

  if (propKey) {
    return {
      open: buildTag(propKey),
      close: buildTag(propKey, false),
      prop: propKey,
    };
  }
};

const removeTextItemProp = (props, propToRemove = '') => {
  const nextProps = Object.keys(props)
    .filter((prop) => prop !== propToRemove)
    .reduce((newProps, prop) => {
      return {
        ...newProps,
        [prop]: true,
      };
    }, {});

  return nextProps;
};

type ParsedTextItem = {
  props?: {
    [t in Tag]?: true
  }
  value?: string
} | null


/**
 * Parses a string into an array of text that is content or a tag that matches the tagRegex.
 * Returns an array of objects, each that has a value and props (optional) that get passed
 * into the <Text /> component that renders the value. props should be only bold or italic
 */
export const parseStyleTags = (text: string) => {
  const textList = text.split(tagRegex);

  // list of objects representing text to be rendered. Each object has value and nested props obj (optional) to determine bold, italic styling
  let textItems: ParsedTextItem[] = [];
  let nextItem: ParsedTextItem = null;
  textList.forEach((currentItem, i) => {
    const propTag = getPropTag(currentItem);

    // if currentItem is an open tag
    if (propTag?.open === currentItem) {
      if (nextItem) {
        // if nextItem has value and currentItem is an open tag, it implies nested tags
        if (nextItem.value) {
          // add nextItem to textItems
          textItems.push(nextItem);
        }

        // reset nextItem but preserve tags (removes any value)
        nextItem = { props: { ...nextItem.props } };

        if (nextItem.props) {
          // if nextItem has props and currentItem is an open tag, add prop determined by currentItem tag to nextItem props
          nextItem = {
            props: { ...nextItem.props, [propTag.prop]: true },
          };
        }
      } else {
        nextItem = { props: { [propTag.prop]: true } };
      }

      // if currentItem is a close tag, implies the end of some styling
    } else if (propTag?.close === currentItem) {
      if (nextItem?.value) {
        // if nextItem has a value, add nextItem to textItems
        textItems.push(nextItem);
      }

      // if nextItem has props, filter out prop determined by currentItem tag
      if (nextItem?.props) {
        nextItem = {
          props: { ...removeTextItemProp(nextItem.props, propTag.prop) },
        };
      } else {
        // clear nextItem
        if (nextItem) nextItem = null;
      }
    } else {
      // if currentItem is not an open or close tag
      if (nextItem) {
        // if there is a nextItem, preserve props but update value
        nextItem = {
          ...nextItem,
          value: nextItem.value ? nextItem.value + currentItem : currentItem,
        };
      } else {
        // if no nextItem, set new one with value of currentItem
        nextItem = { value: currentItem };
      }
    }

    // if currentItem is last item and nextItem exists, add to list
    if (i === textList.length - 1 && nextItem) {
      textItems.push(nextItem);
    }
  });

  return textItems;
};
