export enum AffiliateType {
  employer = 'Employer',
  healthSystem = 'Health System',
  healthPlan = 'Health Plan',
}

export type Affiliate = {
  affiliate_type: AffiliateType;
  banner_text: string;
  body_text?: string;
  candid_eligibility?: boolean;
  covers_ayble?: boolean;
  discount?: number;
  discount_code?: string;
  faq_link?: string;
  group_id_required?: boolean;
  header: string;
  logo: string;
  name: string;
  required_referrer: any;
  subheader: string;
  utm_source: string;
};

export type AffiliateLookup = {
  name: string;
  utm_source: string;
};
