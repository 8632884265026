import React from 'react';
import { useTheme } from 'native-base';
import { G, Line } from 'react-native-svg';

export const Grid = ({ y, ticks }) => {
  const {
    colors: { secondary },
  } = useTheme();

  return (
    <G>
      {
        // Horizontal grid
        ticks.map((tick) => (
          <Line
            key={tick}
            x1="0%"
            x2="100%"
            strokeDasharray="2,10"
            y1={y(tick)}
            y2={y(tick)}
            stroke={secondary['900']}
          />
        ))
      }
    </G>
  );
};
