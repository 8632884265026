import { useApi, useAuth } from '../../hooks';
import { useQuery } from '@tanstack/react-query';

export const useUserDiagnosisAndSymptom = (userId: string) => {
  const { api } = useApi();
  const { isAuthenticated, loading, userToken } = useAuth();

  const fetchUserDiagnosis = async () => {
    const requestObj = {
      route: `/private/user-diagnosis/?user_id=${userId}`,
      token: userToken,
    };

    return await api(requestObj);
  };

  const fetchUserSymptom = async () => {
    const requestObj = {
      route: `/private/user-symptom/?user_id=${userId}`,
      token: userToken,
    };

    return await api(requestObj);
  };

  const fetchUserDiagnosisAndSymptom = async () => {
    const diagnosis = await fetchUserDiagnosis();
    const symptom = await fetchUserSymptom();

    return { diagnosis, symptom };
  };

  const { data, isLoading, isError } = useQuery<any, Error>(
    ['hc-user-diagnosis-and-symptom', userToken, userId],
    () => fetchUserDiagnosisAndSymptom(),
    {
      enabled: isAuthenticated && !loading && !!userToken,
      refetchOnWindowFocus: false,
    }
  );

  return { data, isLoading, isError };
};
