import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Pressable, Text } from 'native-base';
import { Pill } from './Pill';
import { useDashboardPageState } from '../../hooks/useDashboardPageState';
import React from 'react';

export const DietDisplay = ({ readonly = false }) => {
  const {
    state: { user },
  } = useDashboardPageState();
  const navigate = useNavigate();

  if (!user) return null;

  const { allergens, diet_restrictions, selected_foods } = user;

  return (
    <Flex direction="column" px={{ base: 0, md: 2 }} alignItems="left">
      <Flex direction="row" alignItems={'center'} mb={6}>
        <Heading size={'md'} fontWeight={'normal'}>
          My Diet
        </Heading>
        {!readonly && (
          <Pressable onPress={() => navigate(`/dashboard/diet`)}>
            <Text bold color="secondary.200">
              EDIT
            </Text>
          </Pressable>
        )}
      </Flex>

      <Flex direction="column">
        <Text height={'auto'} fontWeight={'600'} mb={2}>
          Allergies
        </Text>
        <Flex flexDir={'row'} flexWrap={'wrap'}>
          {(allergens?.length &&
            allergens.map((allergy, i) => (
              <Pill key={i} label={allergy} />
            ))) || (
            <Text fontSize={'md'} italic>
              None
            </Text>
          )}
        </Flex>
      </Flex>

      <Flex direction="column">
        <Text fontWeight={'600'} mt={4} mb={2}>
          Diet
        </Text>
        <Flex flexDir={'row'} flexWrap={'wrap'}>
          {(diet_restrictions?.length &&
            diet_restrictions.map((allergy, i) => (
              <Pill key={i} label={allergy} />
            ))) || (
            <Text fontSize={'md'} italic>
              None
            </Text>
          )}
        </Flex>
      </Flex>

      <Flex direction="column">
        <Text fontWeight={'600'} mt={4} mb={2}>
          Avoided Foods
        </Text>
        <Flex flexDir={'row'} flexWrap={'wrap'}>
          {(selected_foods.length &&
            selected_foods.map((allergy, i) => (
              <Pill key={i} label={allergy} />
            ))) || (
            <Text fontSize={'md'} italic>
              None
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
