import { useState } from 'react';
import { Button, Heading, VStack, HStack, Link, Input } from 'native-base';
import {
  contentPreviewSections,
  Product,
  LearningHubSectionParams,
} from './constants';
import { useDebounce } from '../../hooks/useDebounce';

const PreviewSelector = ({
  activeSection,
  setActiveSection,
  activeProduct,
  setActiveProduct,
}) => {
  const [sectionSearch, setSectionSearch] = useState('');
  const debouncedSectionSearch = useDebounce(sectionSearch, 400);

  const getSectionTitle = (selectedSection: LearningHubSectionParams) => {
    if (activeProduct === Product.learningHub) {
      const { section, phase } = selectedSection;

      return `${section}${phase ? ` - ${phase}` : ''}`;
      // } else if (activeProduct === Product.mindGut) {
      // TODO: return mind-gut section link title
    }

    return '';
  };

  return (
    <VStack>
      <HStack mx={'auto'}>
        {Object.values(contentPreviewSections).map((product, i) => {
          return (
            <Button
              borderRadius={0}
              backgroundColor={
                activeProduct === product.label ? 'warmGray.300' : undefined
              }
              key={i}
              onPress={() => setActiveProduct(product.label)}
            >
              {product.title}
            </Button>
          );
        })}
      </HStack>
      <VStack
        w={310}
        px={4}
        pb={4}
        borderColor={'warmGray.300'}
        borderWidth={4}
        borderRadius={4}
      >
        <Heading size="md" my={4}>
          Select section to preview
        </Heading>
        <Input
          placeholder="Filter sections"
          onChangeText={(val) => setSectionSearch(val)}
        />
        {contentPreviewSections[activeProduct]?.sections?.map(
          (section: LearningHubSectionParams, idx: number) => {
            const sectionTitle = getSectionTitle(section);
            if (
              sectionTitle
                .toLocaleLowerCase()
                .includes(debouncedSectionSearch.toLocaleLowerCase())
            ) {
              return (
                <Link
                  my={1}
                  mr={'auto'}
                  key={idx}
                  p={1}
                  isUnderlined={false}
                  borderWidth={2}
                  borderColor={
                    activeSection === idx ? 'primary.500' : 'transparent'
                  }
                  borderRadius={8}
                  onPress={() => setActiveSection(idx)}
                >
                  {sectionTitle}
                </Link>
              );
            } else {
              return null;
            }
          }
        )}
      </VStack>
    </VStack>
  );
};

export default PreviewSelector;
