import { useState} from 'react';
import { Slider, Heading, Box, useTheme, View, Image} from "native-base";
import CustomSliderMarkers from "./CustomSliderMarkers.js";

function CustomSlider({ value, setSliderValue, minValue, maxValue }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [containerWidth, setContainerWidth] = useState(550);
  const windowWidth = window.innerWidth;
  const breakPoint = 412;
  const min = minValue ? minValue : 0;
  const max = maxValue ? maxValue : 10;
  const {
    colors: { secondary },
  } = useTheme();
  const beak = './../beak.png'
  const offsetMod = windowWidth > breakPoint ? 18:16;
  const offset = ((containerWidth / max) * value) - offsetMod;
  const styles = {
    tooltip: {
      width: 35,
      height: 35,
      backgroundColor: secondary['500'],
      position: 'absolute',
      top: -45,
      left: offset,
      borderRadius: 4,
      zIndex: 0,
      textAlign: 'center'
    },
    tooltipBeak: {
      position: 'absolute',
      bottom: -5,
      left: 0,
      height: 6,
      width: '100%'
    }
  }

  const Tooltip = () => {
    return (
      <Box style={styles.tooltip} display={showTooltip ? 'block' : 'none'}>
        <Heading fontFamily={'heading'} pt={'20px'} color={'white'} textAlign={'center'} fontSize={'1.8em'}>{value}</Heading>
        <Image
          style={styles.tooltipBeak}
          source={{
            uri: beak
          }}
          alt="pointer down" />
      </Box>
    )
  }

  return (
    <View mx={'auto'} w={'100%'} onLayout={(event) => { 
      const { width } = event.nativeEvent.layout
      setContainerWidth(width);
      }}>
      <View h={40} w={'100%'} left={0} right={0} position={'absolute'}>
        <Tooltip />
      </View>
      <Slider
        onChange={(e) => {
          setShowTooltip(true);
          setSliderValue(e);
        }}
        px={0}
        onChangeEnd={e => setShowTooltip(false)}
        value={value}
        minValue={min} maxValue={max}
        step={1} size={windowWidth > breakPoint ? "lg": "md"} accessibilityLabel="hello world">
        <Slider.Track bg={'secondary.50'}>
          <Slider.FilledTrack bg={'secondary.600' }/>
        </Slider.Track>
        <CustomSliderMarkers length={max} value={value} />
        <Slider.Thumb _hover={{ outlineColor: 'transparent' }} _focus={{ outlineColor: 'transparent' }} bg={'secondary.600'} w={4} h={8} ml={1} />
      </Slider>
    </View>
  )
}
export default CustomSlider;