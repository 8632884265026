import React from 'react';
import { HStack, Text } from 'native-base';
import { SeverityLevel } from './SeverityLevel';

type Props = {
  label: string;
  level: number;
};

export const SeverityDisplay = ({ label, level }: Props) => {
  return (
    <HStack alignItems={'center'} justifyContent={'space-between'} w={'100%'}>
      <Text
        color={'muted.900'}
        fontWeight={'semibold'}
        textTransform={'capitalize'}
      >
        {label}
      </Text>
      <SeverityLevel level={level} />
    </HStack>
  );
};
