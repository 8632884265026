import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi, useAuth } from '../../../hooks';

export const useUpdateCoachUserNotes = () => {
  const queryClient = useQueryClient();
  const { api } = useApi();
  const { userToken } = useAuth();

  const mutation = useMutation({
    mutationFn: async ({ userNotes, method }: any) => {
      const isPosting = method === 'POST';
      const route = `/private/coach-user-notes/${
        isPosting ? '' : `${userNotes.id}/`
      }`;

      const { sx_notes, dx_notes, general, user_id } = userNotes;

      const payload: any = {
        sx_notes,
        dx_notes,
        general,
        user_id: isPosting ? user_id : undefined,
      };

      return await api({
        route,
        token: userToken,
        method: method,
        payload,
      });
    },

    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['hc-user-notes'] }),
  });

  return { mutation };
};
