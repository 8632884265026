import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  Heading,
  Text,
  Box,
  HStack,
  Image,
  VStack,
  Progress,
  CheckIcon,
} from 'native-base';
import { Loader } from '../Loader';
import { useMindGutProgram } from '../../api/healthCoach/useMindGutProgram';

const mindGutLogo = '../../mind-gut-i.png';

const MindGutCard = ({ children }) => {
  return (
    <Card shadow={'none'} p={2}>
      <VStack>
        <HStack alignItems={'center'} justifyContent={'flex-start'}>
          <Image
            size={'md'}
            style={{
              resizeMode: 'contain',
              position: 'relative',
              width: '48px',
              height: '48px',
            }}
            source={{
              uri: mindGutLogo,
            }}
            alt="logo image image"
          />
          <Heading size={'md'} fontWeight={'normal'} textAlign="left">
            Mind-Gut Program
          </Heading>
        </HStack>
        {children}
      </VStack>
    </Card>
  );
};

const ProgramCompleted = () => (
  <MindGutCard>
    <VStack p={2} alignItems={'flex-start'} justifyContent={'center'}>
      <HStack alignItems={'flex-end'}>
        <Text fontSize="lg" fontWeight="bold" pt={1}>
          Program Completed
        </Text>
        <CheckIcon color={'secondary.300'} size={6} pl={1} />
      </HStack>{' '}
    </VStack>
  </MindGutCard>
);

export const HealthCoachMindGutCard = () => {
  const { patient = '' } = useParams();
  const { mindGutProgram, isLoading, error } = useMindGutProgram(patient);

  if (isLoading)
    return (
      <Box backgroundColor="muted.100" p={0}>
        <Loader loading={true} />
      </Box>
    );

  if (!mindGutProgram || error) return null;

  const { program_complete, weeks, program } = mindGutProgram;

  if (program_complete) return <ProgramCompleted />;

  const activeWeek = weeks.find((w) => !!w.active);
  if (!activeWeek) return null;

  const { activities, num_completed, title, week_num_display } = activeWeek;
  const activityProgress = (num_completed / activities?.length) * 100;
  const activityTitle = activities.find(
    ({ status }) => status === 'not-started' || status === 'upcoming'
  )?.title;

  return (
    <MindGutCard>
      <VStack p={2} alignItems={'flex-start'} justifyContent={'center'}>
        <Text fontSize="sm">{`${program} | ${week_num_display}`}</Text>

        <Text fontSize="lg" fontWeight="bold" pt={1}>
          {title}
        </Text>

        <Text fontSize="md" fontWeight="normal" fontStyle={'italic'}>
          {activityTitle}
        </Text>

        <Box pt={2} pb={1} px={'5px'} w={'100%'}>
          <Progress
            value={activityProgress}
            bg="secondary.50"
            _filledTrack={{
              bg: 'secondary.500',
            }}
          />
        </Box>

        <Text
          fontSize={'sm'}
        >{`${activeWeek?.num_completed} of ${activeWeek?.activities.length} Complete`}</Text>
      </VStack>
    </MindGutCard>
  );
};
